import { EditTechnicianFormValuesTypes } from '@ComponentsRoot/Technician/views/EditTechnicianView';
import moment from 'moment';

class modifyTechnicianInfo {
  schedule?: {
    mondayStartTime?: string;
    mondayEndTime?: string;
    mondayPresencial?: boolean;
    tuesdayStartTime?: string;
    tuesdayEndTime?: string;
    tuesdayPresencial?: boolean;
    wednesdayStartTime?: string;
    wednesdayEndTime?: string;
    wednesdayPresencial?: boolean;
    thursdayStartTime?: string;
    thursdayEndTime?: string;
    thursdayPresencial?: boolean;
    fridayStartTime?: string;
    fridayEndTime?: string;
    fridayPresencial?: boolean;
    saturdayStartTime?: string;
    saturdayEndTime?: string;
    saturdayPresencial?: boolean;
    sundayStartTime?: string;
    sundayEndTime?: string;
    sundayPresencial?: boolean;
  };
  skills?: {
    skillId: number;
  }[];
}

export default class TechniciansMapper {
  mapEditTechnician = (data: EditTechnicianFormValuesTypes, techSchedule: any) => {
    const newModifyTecnico = new modifyTechnicianInfo();

    if(techSchedule.length !== 0){
      techSchedule.forEach((scheduleItem: any) => {
        scheduleItem.days.forEach((day: string) => {
          if (!newModifyTecnico.schedule) {
            newModifyTecnico.schedule = {};
          }
  
          if (day === 'monday') {
            newModifyTecnico.schedule.mondayStartTime = this.setUpDate(scheduleItem.startTime)
            newModifyTecnico.schedule.mondayEndTime = this.setUpDate(scheduleItem.endTime);
            newModifyTecnico.schedule.mondayPresencial = scheduleItem.presencial;
            if (newModifyTecnico.schedule.mondayPresencial === undefined) {
              newModifyTecnico.schedule.mondayPresencial = false;
            }
          } else if (day === 'tuesday') {
            newModifyTecnico.schedule.tuesdayStartTime = this.setUpDate(scheduleItem.startTime);
            newModifyTecnico.schedule.tuesdayEndTime = this.setUpDate(scheduleItem.endTime);
            newModifyTecnico.schedule.tuesdayPresencial = scheduleItem.presencial;
            if (newModifyTecnico.schedule.tuesdayPresencial === undefined) {
              newModifyTecnico.schedule.tuesdayPresencial = false;
            }
          } else if (day === 'wednesday') {
            newModifyTecnico.schedule.wednesdayStartTime = this.setUpDate(scheduleItem.startTime);
            newModifyTecnico.schedule.wednesdayEndTime = this.setUpDate(scheduleItem.endTime);
            newModifyTecnico.schedule.wednesdayPresencial = scheduleItem.presencial;
            if (newModifyTecnico.schedule.wednesdayPresencial === undefined) {
              newModifyTecnico.schedule.wednesdayPresencial = false;
            }
          } else if (day === 'thursday') {
            newModifyTecnico.schedule.thursdayStartTime = this.setUpDate(scheduleItem.startTime);
            newModifyTecnico.schedule.thursdayEndTime = this.setUpDate(scheduleItem.endTime);
            newModifyTecnico.schedule.thursdayPresencial = scheduleItem.presencial;
            if (newModifyTecnico.schedule.thursdayPresencial === undefined) {
              newModifyTecnico.schedule.thursdayPresencial = false;
            }
          } else if (day === 'friday') {
            newModifyTecnico.schedule.fridayStartTime = this.setUpDate(scheduleItem.startTime);
            newModifyTecnico.schedule.fridayEndTime = this.setUpDate(scheduleItem.endTime);
            newModifyTecnico.schedule.fridayPresencial = scheduleItem.presencial;
            if (newModifyTecnico.schedule.fridayPresencial === undefined) {
              newModifyTecnico.schedule.fridayPresencial = false;
            }
          } else if (day === 'saturday') {
            newModifyTecnico.schedule.saturdayStartTime = this.setUpDate(scheduleItem.startTime);
            newModifyTecnico.schedule.saturdayEndTime = this.setUpDate(scheduleItem.endTime);
            newModifyTecnico.schedule.saturdayPresencial = scheduleItem.presencial;
            if (newModifyTecnico.schedule.saturdayPresencial === undefined) {
              newModifyTecnico.schedule.saturdayPresencial = false;
            }
          } else if (day === 'sunday') {
            newModifyTecnico.schedule.sundayStartTime = this.setUpDate(scheduleItem.startTime);
            newModifyTecnico.schedule.sundayEndTime = this.setUpDate(scheduleItem.endTime);
            newModifyTecnico.schedule.sundayPresencial = scheduleItem.presencial;
            if (newModifyTecnico.schedule.sundayPresencial === undefined) {
              newModifyTecnico.schedule.sundayPresencial = false;
            }
          }
        });
      });
    } else {
      newModifyTecnico.schedule = {}
    }

    newModifyTecnico.skills = [];
    const skillKeys = Object.keys(data).filter((key: string) => key.includes(`skill-`));
    skillKeys.forEach((skillKey: any) => {
      if (data[skillKey] && data[skillKey].length) {
        const value = data[skillKey].map((item: any) => {
          return { skillId: Number(item.value) };
        });
        newModifyTecnico.skills!.push(...value);
      }
    });

    return JSON.stringify(newModifyTecnico);
  };

  setUpDate = (data: any) => {
    const date = moment();
    const time = data.split(':');
    date.set('hour', Number(time[0]));
    date.set('minute', Number(time[1]));
    date.set('second', Number(time[2]));
    const formatDateTime = date.format();
    const dateTimeLocal = moment.parseZone(formatDateTime).local().format();

    return dateTimeLocal;
  };
}
