import { useParams } from 'react-router-dom';
import { InjectedPropsType } from '@ComponentsRoot/core/types/InjectedPropsType';
import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Button, Col, Input, Row, Label, FormGroup, Container, Alert, Spinner} from 'reactstrap';
import Accordion from 'react-bootstrap/Accordion';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import '@progress/kendo-theme-default/dist/all.css';
// import FormErrorView from '@ComponentsRoot/FormError/view/FormErrorView';
import '../js/EditTechnician.js';
import '../styles/EditTechnicianViewStyle.scss'
import '@progress/kendo-theme-default/dist/all.css';
import EditTechnicianController from '../controller/EditTechnicianController';
import { ErrorHandler } from '@ComponentsRoot/ErrorHandler/ErrorHandler';
import ModalAddSchedule from './ModalAddSchedule';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button as GridButton } from '@progress/kendo-react-buttons';
import moment from 'moment';

export type EditTechnicianFormValuesTypes = {
  tecnicoId: string;
  email: string;
  [key: `skill-${number}`]: any;
  sustituto: number;
};

//https://react-hook-form.com/api/usecontroller/controller (V7 IMPORTANT!)
//https://react-hook-form.com/api/useform

export const EditTechnicianView = (props: InjectedPropsType) => {
  const [tecnicoDetailData, setDetailData] = useState<any|null>([]);
  const [techSchedule, setTechSchedule] = useState<any|null>([]);
  const [techSkills, setTechSkills] = useState<Opcion[]>([]);
  const [allTecnicos, setAllTecnicos] = useState<any|null>(null);
  const [allSkills, setSkillsData] = useState<any|null>([]);
  const [allSubSkills, setSubSkillsData] = useState<any|null>(null);
  const [iconSuccess, setIconSuccess] = useState(null);
  const [messageSuccess, setMessageSuccess] = useState(null);
  const [isError, setIsError] = useState<any|null>(null);
  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const [showSpinner, setShowSpinner] = useState<any|null>('d-none');
  const [showSpinnerBtn, setShowSpinnerBtn] = useState<any|null>('d-none');
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onDismissSuccessAlertVisible = () => setSuccessAlertVisible(false);

  interface Opcion {
    skillId: any;
    name: string;
    categoryId: any;
  }

  const handleCrearNuevaHabilidad = async (inputValue: any, categoryId: string) => {
    try {
      setIsLoading(true);
      const res = await controller.addNewSkill(inputValue, categoryId, setSubSkillsData);
      const opcionesAPI = res.map((opcion: any) => ({
        value: opcion.value,
        label: opcion.label,
      }));
      const newSkill: Opcion = {
        skillId: opcionesAPI[opcionesAPI.length - 1].value,
        name: opcionesAPI[opcionesAPI.length - 1].label,
        categoryId: categoryId,
      };
      setSubSkillsData((prevSubSkills: any) => [...prevSubSkills, newSkill]); // Actualizar las opciones de allSubSkills
      setTechSkills((prevSkills: any) => [...prevSkills, newSkill]);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const { tecnicoId } = useParams();

  const controller = new EditTechnicianController(props, tecnicoId, setIsError);

  const renderErrorHandler = () => {
    if(isError){
      return <ErrorHandler isOpen toggle={controller.toggleError} message={isError.message} />
    }
    return <></>
  }

  const ColumnDays = (props: any) => {
    const { days } = props.dataItem;

    const dayTranslations: any = {
      monday: 'Lunes',
      tuesday: 'Martes',
      wednesday: 'Miércoles',
      thursday: 'Jueves',
      friday: 'Viernes',
      saturday: 'Sábado',
      sunday: 'Domingo',
    };

    const dayStyle = {
      backgroundColor: '#e6e6e6',
      borderRadius: '2px',
      padding: '5px',
      margin: '2px',
      color: '#333333',
      fontSize: '85%',
      paddingLeft: '8px',
    };

    return (
      <td className={props.className} {...props} style={Object.assign(props.style)}>
        {days.map((day: string) => (
          <span key={day} style={dayStyle}>
            {dayTranslations[day]}
          </span>
        ))}
      </td>
    );
  };

  const ColumnHour = (props: any) => {
    const { startTime, endTime } = props.dataItem;

    const formattedStartTime = moment(startTime, 'HH:mm:ss').format('HH:mm');
    const formattedEndTime = moment(endTime, 'HH:mm:ss').format('HH:mm');
  
    return (
      <td className={props.className} {...props} style={Object.assign(props.style)}>
        {formattedStartTime} - {formattedEndTime}
      </td>
    );
  };

  const ColumnOnSite = (props: any) => {
    const { presencial } = props.dataItem;
  
    return (
      <td className={props.className} {...props} style={Object.assign(props.style)}>
        {presencial ? 'Presencial' : 'No Presencial'}
      </td>
    );
  };

  const ColumnMenuAction = (props: any) => {
    const handleDelete = () => {
      setTechSchedule((prevSchedule: any[]) =>
        prevSchedule.filter((schedule: any) => {
          return (
            schedule.startTime !== props.dataItem.startTime ||
            schedule.endTime !== props.dataItem.endTime ||
            schedule.presencial !== props.dataItem.presencial ||
            schedule.days[0] !== props.dataItem.days[0]
          );
        })
      );
    };
    
    return (
      <td className={props.className} {...props} style={Object.assign(props.style)}>
        <GridButton
          icon='trash'
          fillMode='solid'
          className='btn-seidor bg-seidor-dark-grey color-seidor-white'
          onClick={handleDelete}
        />
      </td>
    );
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EditTechnicianFormValuesTypes>();

  const onSubmit: SubmitHandler<EditTechnicianFormValuesTypes> = async (data) => {
    await controller.saveTecnico(data, techSchedule, setSuccessAlertVisible, setIconSuccess, setMessageSuccess, props, setShowSpinnerBtn, setDisabledBtn);
  };

  useEffect(() => {
    (async () => {
      await controller.getInfoTecnico(tecnicoId as string, setDetailData, props, setShowSpinner);
      await controller.getTecnicos(setAllTecnicos, props);
      await controller.getAllSkills(setSkillsData);
      await controller.getAllSubSkills(setSubSkillsData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (tecnicoDetailData.length !== 0) {
        await controller.setSchedule(tecnicoDetailData?.technicianSchedule, setTechSchedule);
        await controller.setSkillsTech(tecnicoDetailData?.technicianSkills, setTechSkills);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tecnicoDetailData]);

  const renderMainForm = () => {
    return (
      <>
      <Container className='mb-4 border-container'>
        <Spinner color="info" className={`m-center mt-5 ${showSpinner}`}>
            Loading...
        </Spinner>
        <Row className='mt--40'>
          <Col xs='12'>
            <FormGroup>
              <h1><span className='bg-seidor-white'>Datos del técnico</span></h1>
              <h2>{`${tecnicoDetailData?.tecnico?.name}`} {`${tecnicoDetailData?.tecnico?.surname}`}</h2>
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Input
                    ref={ref}
                    id="tecnicoId"
                    type={'hidden'}
                    className={`${errors.tecnicoId ? 'error form-control' : 'form-control'}`}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={10}
                    value={value}
                  />
                )}
                name={'tecnicoId'}
                control={control}
                defaultValue={`${tecnicoDetailData?.tecnico?.id}`}
              />
            </FormGroup>
          </Col>
          <Col xs='12'>
            <FormGroup>
              <Label for="email">
                Email
              </Label>
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Input
                    ref={ref}
                    id="email"
                    type={'email'}
                    className={`${errors.email ? 'error form-control' : 'form-control'}`}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={100}
                    value={value}
                    disabled
                  />
                )}              
                name={'email'}              
                rules={{
                  minLength: 2,
                  required: true,               
                  maxLength: 100,
                }}
                control={control}
                defaultValue={`${tecnicoDetailData?.tecnico?.email}`}
              />
            </FormGroup>
          </Col>

          <Col xs='12'>
            <Accordion defaultActiveKey={['0','1']} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Horario</Accordion.Header>
                <Accordion.Body>
                  <Col xs='12'>
                    <Button className="btn-seidor bg-seidor-light-blue" onClick={() => {setModalIsOpen(true);}}>
                      Añadir <span className="k-icon k-i-clock"></span>
                    </Button>
                  </Col>
                  <Col xs='12' className='mt-2'>
                    <Grid data={techSchedule} className='turnos-grid'>
                      <Column title='Días' className='skill-column' cell={ColumnDays} />
                      <Column title='Horario' className='skill-column' cell={ColumnHour} />
                      <Column title='Presencial' className='skill-column' cell={ColumnOnSite} />
                      <Column title='Actions' cell={ColumnMenuAction} />
                    </Grid>
                  </Col>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Skills</Accordion.Header>
                <Accordion.Body>
                  {allSkills.map((skill: any, key: any) => {
                    const techSkillsOptions = techSkills
                    .filter((techSkill: any) => techSkill.categoryId === skill.id) // Filtrar por categoryId
                    .map((techSkill: any) => ({
                      value: techSkill.skillId,
                      label: techSkill.name,
                    }));

                    return <Col xs='12' className='mb-2' id={skill.id} key={key}>
                      <Label className='mr-3'>{skill.name}</Label>
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <CreatableSelect
                            isClearable
                            isMulti
                            isDisabled={isLoading}
                            isLoading={isLoading}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            name={`skill-${skill.id}`}
                            onChange={onChange}
                            options={allSubSkills
                              .filter((subSkill: any) => subSkill.categoryId === skill.id) // Filtrar por categoryId
                              .map((subSkill: any) => ({
                                value: subSkill.skillId,
                                label: subSkill.name,
                              }))
                            }
                            defaultValue={techSkillsOptions}
                            onCreateOption={(inputValue) => handleCrearNuevaHabilidad(inputValue, skill.id)}
                          />
                        )}
                        defaultValue={techSkillsOptions}
                        name={`skill-${skill.id}`}
                        control={control}
                      />
                    </Col>
                  })}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col lg='12'>
            <Button disabled={disabledBtn} onClick={() => handleSubmit(onSubmit)()} className="btn-seidor bg-seidor-medium-blue">
              Guardar
              <Spinner size="sm" className={`spinner-pos ${showSpinnerBtn}`}>
                Loading...
              </Spinner>
            </Button>
          </Col>
        </Row>
      </Container>

      <ModalAddSchedule
        data={tecnicoDetailData?.technicianSchedule}
        controller={controller}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        techSchedule={techSchedule}
        setTechSchedule={setTechSchedule}
      />

      <Alert isOpen={successAlertVisible} toggle={onDismissSuccessAlertVisible} className='alert-success'>
        <p className='m-0'><span className={`k-icon ${iconSuccess}`}></span> {messageSuccess}</p>
      </Alert>

      {renderErrorHandler()}
      </>
    );
  };

  return tecnicoDetailData === null || allTecnicos === null || allSubSkills === null ? <>{renderErrorHandler()}</> : renderMainForm();
};