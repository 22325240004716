import { useParams } from 'react-router-dom';
import { InjectedPropsType } from '@ComponentsRoot/core/types/InjectedPropsType';
import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Col, Input, Row, Label, Container, Alert, Spinner } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import EditSkillController from '../controller/EditSkillController';
import { ErrorHandler } from '@ComponentsRoot/ErrorHandler/ErrorHandler';

export type EditSkillsFormValuesTypes = {
  habilidadId: string;
  categoryName: string;
  skill: any;
};

//https://react-hook-form.com/api/usecontroller/controller (V7 IMPORTANT!)
//https://react-hook-form.com/api/useform

export const EditSkillView = (props: InjectedPropsType) => {
  const [skillDetailData, setDetailData] = useState<any|null>(null);
  const [subSkillDetailData, setSubDetailData] = useState<any|null>([]); 
  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const [iconSuccess, setIconSuccess] = useState(null);
  const [messageSuccess, setMessageSuccess] = useState(null);
  const [isError, setIsError] = useState<any|null>(null);
  const [showSpinner, setShowSpinner] = useState<any|null>('d-none');

  console.log(subSkillDetailData)

  const renderErrorHandler = () => {
    if(isError){
      return <ErrorHandler isOpen toggle={controller.toggleError} message={isError.message} />
    }
    return <></>
  }

  const { skillId } = useParams();

  const controller = new EditSkillController(props, skillId, setIsError);

  const onDismissSuccessAlertVisible = () => setSuccessAlertVisible(false);


  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Opcion[]>([]);

  interface Opcion {
    value: any;
    label: string;
  }

  const handleCreate = async (inputValue: string) => {
    try {
      setIsLoading(true);
      const res = await controller.addSkill(inputValue, setSuccessAlertVisible, setIconSuccess, setMessageSuccess, setOptions);
      const opcionesAPI = res.map((opcion: any) => ({
        value: opcion.value,
        label: opcion.label,
      }));
      const newSkill: Opcion = {
        value: opcionesAPI[opcionesAPI.length - 1].value,
        label: opcionesAPI[opcionesAPI.length - 1].label,
      };
      setOptions((prevSkills) => [...prevSkills, newSkill]);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error){
      console.log(error);
    }
  };

  const {
    control,
    formState: { errors },
  } = useForm<EditSkillsFormValuesTypes>();

  const handleEliminarOpcion = async (opcion: Opcion) => {
    setOptions((prevOpciones) => prevOpciones.filter((o) => o.value !== opcion.value));
    await controller.deleteSkill(opcion.value);
  };

  useEffect(() => {
    (async () => {
      await controller.getDetailSkill(skillId as string, setDetailData, setShowSpinner);
      await controller.getSubSkills(skillId as string, setSubDetailData, setOptions);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderMainForm = () => {

    const formatOptionLabel = (option: Opcion) => (
      <div>
        {option.label}
        <span
          className="delete-option"
          onClick={() => handleEliminarOpcion(option)}
          style={{
            marginLeft: '5px',
            color: '#525f7f',
            padding: '2px 4px',
            cursor: 'pointer',
            fontWeight: 'bold',
            fontSize: '11px',
          }}>
          X
        </span>
      </div>
    );

    return (
      <>
      <Container className='mb-4 border-container'>
        <Spinner color="info" className={`m-center mt-5 ${showSpinner}`}>
          Loading...
        </Spinner>
        <Row className='mt--40'>
          <Col xs='12'>
            <h1><span className='bg-seidor-white'>{skillDetailData?.name}</span></h1>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col xs='12'>
            <Label for="nombre">
              Skills
            </Label>

            <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Input
                    ref={ref}
                    id="habilidadId"
                    type={'hidden'}
                    className={`${errors.habilidadId ? 'error form-control' : 'form-control'}`}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={10}
                    value={`${skillDetailData?.id}`}
                  />
                )}
                name={'habilidadId'}
                control={control}
                defaultValue={`${skillDetailData?.id}`}
              />

            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                 <CreatableSelect
                  isClearable
                  isMulti
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  onCreateOption={handleCreate}
                  formatOptionLabel={formatOptionLabel}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={onChange}
                  name='skill'
                  value={options}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      '& [role="button"]': {
                        display: 'none',
                      },
                      '& .select__clear-indicator': { // Clase CSS específica de la "x" general
                        display: 'none',
                      },
                    }),
                  }}
                />
              )}
              name='skill'
              control={control}
            />
          </Col>
        </Row>
      </Container>

      <Alert isOpen={successAlertVisible} toggle={onDismissSuccessAlertVisible} className='alert-success'>
        <p className='m-0'><span className={`k-icon ${iconSuccess}`}></span> {messageSuccess}</p>
      </Alert>
      {renderErrorHandler()}
      </>
    );
  };

  //Solo printará el render si tenemos los datos de la API
  return skillDetailData === null ? <>{renderErrorHandler()}</> : renderMainForm();
};
