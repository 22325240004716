import { InjectedPropsType } from '@ComponentsRoot/core/types/InjectedPropsType';
import { OutletContextType } from '@ComponentsRoot/core/types/OutletContextType';
import { process } from '@progress/kendo-data-query';
import {
  Grid,
  GridColumn as Column,
  GridColumnMenuFilter,
  GridColumnMenuProps,
  GridDataStateChangeEvent,
} from '@progress/kendo-react-grid';
import { Button as GridButton } from "@progress/kendo-react-buttons";
import React from 'react';
import '@progress/kendo-theme-default/dist/all.css';
import SkillsController from '../controller/SkillsController';
import { Row, Col, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Spinner } from 'reactstrap';
import { ErrorHandler } from '@ComponentsRoot/ErrorHandler/ErrorHandler';
import { cleanGridColumnMenuIncomingPropsGridUtils } from '@MainUtils/GridUtils';
// import { string } from 'prop-types';
// import { any } from 'prop-types';


class ColumnMenu extends React.Component<GridColumnMenuProps> {
  render() {
    return (
      <div>
        <GridColumnMenuFilter {...this.props} expanded={true} />
      </div>
    );
  }
}

export default class SkillsGridView extends React.Component<
  InjectedPropsType,
  { take: number; skip: number; servicesGridData: undefined | any[]; skillsGridData: undefined | any[]; skillDelete: undefined | any[]; modal: boolean, categoria: string; isError: any; showSpinner: any; showSpinnerBtn: any; showAlert: any; messageAlert: undefined; disabledBtn: any; disabledDelBtn: any}
> {
  controller: SkillsController;

  constructor(props: any) {
    super(props);
    this.controller = new SkillsController(this, this.props as { outletContext: OutletContextType });

    this.state = {
      take: 20,
      skip: 0,
      servicesGridData: undefined,
      skillsGridData: undefined,
      skillDelete: undefined,
      modal: false,
      categoria: '',
      isError: undefined,
      showSpinner: undefined,
      showSpinnerBtn: 'd-none',
      showAlert: false,
      messageAlert: undefined,
      disabledBtn: false,
      disabledDelBtn: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleInput = (event: { target: { value: any; }; }) => {
    this.setState({ categoria: event.target.value });
  };

  renderErrorHandler = () => {
    if(this.state.isError){
      return <ErrorHandler isOpen toggle={this.controller.toggleError} message={this.state.isError.message} />
    }
    return <></>
  }

  componentDidMount = async() => {
    if (this.state.skillsGridData === undefined){
      await this.controller.getAllSkills();
    }
  }

  onDismiss = () => this.setState({showAlert: false})

  dataStateChange = (event: GridDataStateChangeEvent) => {
    this.setState({ ...(event.dataState as any) });
  };

  ColumnMenuAction = (props: any) => {
    const cleanProps = cleanGridColumnMenuIncomingPropsGridUtils(props);

    return( 
      <td className={props.className} {...cleanProps} style={Object.assign(props.style)}>
          <a href={`/skill/${props.dataItem.id}`}>
            <GridButton icon="pencil" fillMode="solid" className='mr-2 btn-seidor bg-seidor-light-blue color-seidor-white' />
          </a>
          <GridButton icon="trash" fillMode="solid" className='btn-seidor bg-seidor-dark-grey color-seidor-white' disabled={this.state.disabledDelBtn} onClick={(e:any) => this.controller.deleteSkill(props.dataItem.id)} />
      </td>
    )
  };


  render() {
    return (
      <>
      <Row>
        <Col lg='12'>
          <h1>Listado de Skills</h1>
          <Button className='mb-4 btn-seidor bg-seidor-medium-blue' onClick={this.toggle}>Añadir +</Button>
          <Spinner color="info" className={`m-center mt-5 ${this.state.showSpinner}`}>
            Loading...
          </Spinner>
          {this.state.skillsGridData && <Grid
            {...this.state}
            data={process(this.state.skillsGridData === undefined ? [] : this.state.skillsGridData, this.state)}        
            onDataStateChange={this.dataStateChange}
            sortable={{
              mode: 'multiple',
            }}
            pageable={true}
            pageSize={20}
          >
            <Column field='name' title='Nombre' columnMenu={ColumnMenu} />
            <Column title='Actions' cell={this.ColumnMenuAction} />
          </Grid>}
        </Col>
      </Row>

      <Modal isOpen={this.state.modal} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>Crear nueva skill</ModalHeader>
        <ModalBody>
          <Input type='text' id='categoria' name='categoria' onChange={this.handleInput} placeholder='Introduce la nueva skill' />
        </ModalBody>
        <ModalFooter>
          <Button className='btn-seidor bg-seidor-medium-blue' disabled={this.state.disabledBtn} onClick={(e:any) => this.controller.addSkill(this.state.categoria)}>
            Crear
            <Spinner size="sm" className={`spinner-pos ${this.state.showSpinnerBtn}`}>
              Loading...
            </Spinner>
          </Button>{' '}
          <Button className='btn-seidor bg-seidor-dark-grey' onClick={this.toggle}>Cerrar</Button>
        </ModalFooter>
      </Modal>

      <Alert isOpen={this.state.showAlert} toggle={this.onDismiss} className='alert-success'>
        <p className='m-0'><span className={'k-icon k-i-check-circle'}></span> {this.state.messageAlert}</p>
      </Alert>

      {this.renderErrorHandler()}
      </>
    );
  }
}