import { Controller, SubmitHandler, useForm } from 'react-hook-form';
// import Select from "react-select/dist/declarations/src/Select";
import { Modal, ModalHeader, ModalBody, Col, Input, Label, FormGroup, Button, ModalFooter, Spinner } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import { formatOptionLabelResources } from './EditServiceView';
import { useEffect, useState } from 'react';

export type NewTurnFormValuesTypes = {
  servicioIdNew: string;
  nameTurnoNew: string;
  mondayNew: boolean;
  thuesdayNew: boolean;
  wednesdayNew: boolean;
  thursdayNew: boolean;
  fridayNew: boolean;
  saturdayNew: boolean;
  sundayNew: boolean;
  startTimeNew: string;
  endTimeNew: string;
  onsiteNew: boolean;
  skills: any;
  [key: `skill-${number}-new`]: any;
};

export default function ModalAddturno(props: {
  disabledButton: boolean;
  addMoreButtonClickFn: any;
  allSkills: any;
  allSubSkills: any;
  controller: any;
  isOpen: boolean;
  onRequestClose: any;
  onSubmit: any;
  serviceDetailDataId: any;
  showSpinnerBtnTurn: any;
  
}) {
  const {
    handleSubmit: handleSubmitNew,
    control: controlNew,
    formState: { errors },
    reset,
  } = useForm<NewTurnFormValuesTypes>();

  const onSubmitNew: SubmitHandler<NewTurnFormValuesTypes> = async (data) => {
    const newData = {
      ...data,
      skills: skillSelectedOptions,
    };
    await props.onSubmit(newData);
    setSkillSelectedOptions([]);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [modalResources, setModalResources] = useState(false);
  const [modalSkillResources, setModalSkillResources] = useState(false);
  const [newSkill, setNewSkill] = useState<any | null>();
  const [resourcesSkill, setResourcesSkill] = useState<any | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<{ label: string; value: any } | null>(null);
  const [skillSelectedOptions, setSkillSelectedOptions] = useState<any | null>([]);
  const [skillsOptionsValues, setSkillsOptionsValues] = useState<any | null>([]);
  const [getCategoryId, setCategoryId] = useState(false);
  const toggleModalResources = () => setModalResources(!modalResources);
  const toggleModalSkillResources = () => setModalSkillResources(!modalSkillResources);
  
  const handleCreate = async (inputValue: string, categoryId: any) => {
    setNewSkill(inputValue);
    setCategoryId(categoryId);
    toggleModalResources();
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handleSelectChange = (selected: any, categoryId: any, actionMeta: any) => {
    const { action } = actionMeta;
    if (action === 'remove-value') {
      const removedOption = actionMeta.removedValue;
      // Realiza las operaciones necesarias con la opción eliminada
      const updatedOptions = skillSelectedOptions.filter((option: { value: any; }) => option.value !== removedOption.value);
      setSkillSelectedOptions(updatedOptions);
      console.log('Opción eliminada:', removedOption);
    }else{
      const selectedOption = selected ? selected[selected.length - 1] : null;
      setCategoryId(categoryId);
      setSelectedOptions(selectedOption);
      if (selectedOption !== undefined) {
        toggleModalSkillResources();
      }
    }
  };

  useEffect(() => {
    setSkillSelectedOptions(skillsOptionsValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillsOptionsValues]);

  useEffect(() => {
    if (props.isOpen) {
      setSkillSelectedOptions([]);
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onRequestClose} size={'lg'}>
      <ModalHeader>Añadir un nuevo turno</ModalHeader>
      <ModalBody>
        <Col xs='12' className='mb-3'>
          <Controller
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <Input
                ref={ref}
                id='servicioIdNew'
                type={'hidden'}
                className={`${errors.servicioIdNew ? 'error form-control' : 'form-control'}`}
                onChange={onChange}
                onBlur={onBlur}
                maxLength={10}
                value={value}
              />
            )}
            name={'servicioIdNew'}
            control={controlNew}
            defaultValue={`${props.serviceDetailDataId}`}
          />
          <Label>Nombre del turno</Label>
          <Controller
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <Input
                type='text'
                id='nameTurnoNew'
                name='nameTurnoNew'
                className='form-control'
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
            name={'nameTurnoNew'}
            control={controlNew}
          />
        </Col>

        <Col xs='12' className='mb-3'>
          <FormGroup className='mb-0'>
            <Label>Días de la semana</Label>
          </FormGroup>
          <FormGroup check inline>
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => <Input type='checkbox' id='mondayNew' name='mondayCheck' onChange={onChange} onBlur={onBlur} />}
              name={'mondayNew'}
              control={controlNew}
            />
            <Label check>Lunes</Label>
          </FormGroup>
          <FormGroup check inline>
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => <Input type='checkbox' id='thuesdayNew' name='thuesdayNew' onChange={onChange} onBlur={onBlur} />}
              name={'thuesdayNew'}
              control={controlNew}
            />
            <Label check>Martes</Label>
          </FormGroup>
          <FormGroup check inline>
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => <Input type='checkbox' id='wednesdayNew' name='wednesdayNew' onChange={onChange} onBlur={onBlur} />}
              name={'wednesdayNew'}
              control={controlNew}
            />
            <Label check>Miércoles</Label>
          </FormGroup>
          <FormGroup check inline>
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => <Input type='checkbox' id='thursdayNew' name='thursdayNew' onChange={onChange} onBlur={onBlur} />}
              name={'thursdayNew'}
              control={controlNew}
            />
            <Label check>Jueves</Label>
          </FormGroup>
          <FormGroup check inline>
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => <Input type='checkbox' id='fridayNew' name='fridayNew' onChange={onChange} onBlur={onBlur} />}
              name={'fridayNew'}
              control={controlNew}
            />
            <Label check>Viernes</Label>
          </FormGroup>
          <FormGroup check inline>
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => <Input type='checkbox' id='saturdayNew' name='saturdayNew' onChange={onChange} onBlur={onBlur} />}
              name={'saturdayNew'}
              control={controlNew}
            />
            <Label check>Sábado</Label>
          </FormGroup>
          <FormGroup check inline>
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => <Input type='checkbox' id='sundayNew' name='sundayNew' onChange={onChange} onBlur={onBlur} />}
              name={'sundayNew'}
              control={controlNew}
            />
            <Label check>Domingo</Label>
          </FormGroup>
        </Col>

        <Col xs='12' className='mb-3'>
          <FormGroup className='mb-0'>
            <Label>Horario</Label>
          </FormGroup>
          <FormGroup check inline>
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Input
                  type={'time'}
                  id='startTimeNew'
                  name='startTimeNew'
                  className='form-control'
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
              name={'startTimeNew'}
              control={controlNew}
            />
          </FormGroup>

          <FormGroup check inline>
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Input
                  type={'time'}
                  id='endTimeNew'
                  name='endTimeNew'
                  className='form-control'
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
              name={'endTimeNew'}
              control={controlNew}
            />
          </FormGroup>
        </Col>
        <Col xs='12'>
          <Label>Skills</Label>
        </Col>
        {props.allSkills.map((skill: any, key: any) => {
          return (
            <>
              <Col xs='12' lg='12' className='mb-2' id={skill.id} key={key}>
                <Label className='mr-3'>
                  {skill.name}{' '}
                </Label>
                
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <CreatableSelect
                      isMulti
                      isClearable={false}
                      isDisabled={isLoading}
                      isLoading={isLoading}
                      className='basic-multi-select'
                      classNamePrefix='select'
                      onCreateOption={(inputValue) => handleCreate(inputValue, skill.id)}
                      name={`skill-${skill.id}-new`}
                      options={props.allSubSkills
                        .filter((subSkill: any) => subSkill.categoryId === skill.id) // Filtrar por categoryId
                        .map((subSkill: any) => ({
                          value: subSkill.id,
                          label: subSkill.name,
                        }))
                      }
                      value={skillSelectedOptions
                        .filter((skills: any) => skills.categoryId === skill.id) // Filtrar por categoryId
                        .map((skills: any) => ({
                          value: skills.value,
                          label: skills.label,
                          resources: skills.resources
                        }))
                      }
                      formatOptionLabel={(inputValue) => formatOptionLabelResources(inputValue, skillSelectedOptions)}
                      onChange={(selected, actionMeta) => handleSelectChange(selected, skill.id, actionMeta)}
                    />
                  )}
                  // defaultValue={skillSelectedOptions
                  //   .filter((skills: any) => skills.categoryId === skill.id) // Filtrar por categoryId
                  //   .map((skills: any) => ({
                  //     value: skills.value,
                  //     label: skills.label,
                  //     resources: skills.resources
                  //   }))
                  // }
                  name={`skill-${skill.id}-new`}
                  control={controlNew}
                />
              </Col>
            </>
          );
        })}

        <Col xs='12' className='mt-4'>
          <FormGroup check inline>
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => <Input type='checkbox' id='onsiteNew' name='onsiteNew' onChange={onChange} onBlur={onBlur} />}
              name={'onsiteNew'}
              control={controlNew}
            />
            <Label check>Servicio es OnSite.</Label>
          </FormGroup>
        </Col>

        <Modal isOpen={modalSkillResources} toggle={toggleModalSkillResources}>
          <ModalHeader>Añadir recursos para {selectedOptions?.label}</ModalHeader>
          <ModalBody>
            <Label className='mt-3'>Recursos mínimos</Label>
            <Input
              type='number'
              id='recurso'
              name='recurso'
              className='form-control'
              onChange={(event) => setResourcesSkill(event.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={async(e: any) => {
              await props.controller.setSelectedSkillOption(selectedOptions, getCategoryId, resourcesSkill, setSkillSelectedOptions, skillSelectedOptions);
              toggleModalSkillResources();
              }}>
              Añadir
            </Button>{' '}
            <Button className='btn-seidor bg-seidor-dark-grey' onClick={toggleModalSkillResources}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalResources} toggle={toggleModalResources}>
            <ModalHeader>Añadir recursos para {newSkill}</ModalHeader>
            <ModalBody>
              <Label className='mt-3'>Recursos mínimos</Label>
              <Input
                type='number'
                id='recurso'
                name='recurso'
                className='form-control'
                onChange={(event) => setResourcesSkill(event.target.value)}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={async(e: any) =>{
                const res = await props.controller.addNewSkill(newSkill, getCategoryId, resourcesSkill, setSkillSelectedOptions, skillSelectedOptions);
                setSkillsOptionsValues(res)
                toggleModalResources();
              }}>
                Añadir
              </Button>{' '}
              <Button className='btn-seidor bg-seidor-dark-grey' onClick={toggleModalResources}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
      </ModalBody>
      <ModalFooter>
        <Button
          className='btn-seidor bg-seidor-medium-blue'
          disabled={props.disabledButton}
          onClick={() => handleSubmitNew(onSubmitNew)()}
        >
          Añadir
          <Spinner size='sm' className={`spinner-pos ${props.showSpinnerBtnTurn}`}>
            Loading...
          </Spinner>
        </Button>{' '}
        <Button className='btn-seidor bg-seidor-dark-grey' onClick={props.onRequestClose}>
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
