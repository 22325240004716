import RepositoryManager from '@ComponentsRoot/core/RepositoryManager';
import NodeApiDS from '@DataSource/NodeApiDS';
import AxiosClient from '@Services/AxiosClient';

export default class MainLayoutController {
  nodeApiDS: NodeApiDS;
  repositoryManager: RepositoryManager;
  viewCtx: any;
  constructor(viewCtx: any) {
    this.nodeApiDS = new NodeApiDS(new AxiosClient(viewCtx.props.msalContext.instance));
    this.repositoryManager = new RepositoryManager(this.nodeApiDS);
    this.viewCtx = viewCtx;
  }

  getImgUser = async (token: any): Promise<any> => {
    try {
      const res = await this.repositoryManager.getTechnicianRepository().getImgUser(token);
      this.viewCtx.setState({ imgUser: res });
    } catch (error) {
      console.log(error);
    }
  };

  getBEVersion = async () => {
    try {
      const res = await this.repositoryManager.getTurnsRepository().getBEVersion();
      if (res) {
        this.viewCtx.setState({ beVersion: res.version });
      }
    } catch (error) {
      console.log(error);
    }
  };

  getUncoverTurnsAlertIcon = async () => {
    try {
      const res = await this.repositoryManager.getTurnsRepository().getUncoverTurnsAlertIcon();
      if (res.status === true) {
        this.viewCtx.setState({ iconAlert: true });
      } else {
        this.viewCtx.setState({ iconAlert: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  getDataFromBackend = async () => {
    try {
    } catch (error) {}
  };

  getRefreshAccesToken = async (msalInstance: any): Promise<any> => {
    const token = await this.nodeApiDS.getAxiosClient().refreshAccessToken(msalInstance);
    this.nodeApiDS.getAxiosClient().setupHeaders({ Authorization: 'Bearer ' + token.id_token });
    this.nodeApiDS.getAxiosClient().setupHeaders({ MSGraphAccessToken: token.access_token });
    this.viewCtx.setState({ accessToken: token.access_token });
    await this.getImgUser(token.access_token);
  };
}
