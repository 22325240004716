import RepositoryManager from '@ComponentsRoot/core/RepositoryManager';
import { OutletContextType } from '@ComponentsRoot/core/types/OutletContextType';

export default class BackupsController {
  viewCtx;
  repositoryManager: RepositoryManager;
  constructor(viewCtx: any, viewProps: { outletContext: OutletContextType }) {
    this.viewCtx = viewCtx;
    this.repositoryManager = viewProps.outletContext.repositoryManager;
  }

  setError = (error: any) => {
    this.viewCtx.setState({ isError: error });
  };

  toggleError = () => {
    this.viewCtx.setState({ isError: null });
  };

  getAllBackups = async () => {
    try {
      this.viewCtx.setState({ showSpinner: 'd-block' });
      const res = await this.repositoryManager.getBackupsRepository().getAllBackups();
      if (res) {
        this.viewCtx.setState({ showSpinner: 'd-none' });
        const dataToSend = this.mapAllBackupsName(res);
        this.viewCtx.setState({ backupsGridData: dataToSend });
      }
    } catch (error) {
      this.viewCtx.setState({ showSpinner: 'd-none' });
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  mapAllBackupsName = (data: any) => {
    const backupsArr: any[] = [];
    data.forEach((item: any) => {
      backupsArr.push({ name: item });
    });
    return backupsArr;
  };

  createBackup = async () => {
    try {
      const date = new Date();
      const timestamp = date.getTime();
      this.viewCtx.setState({ showSpinnerBtn: 'd-block', disabledBtn: true });
      const res = await this.repositoryManager.getBackupsRepository().createBackup(timestamp);
      if (res) {
        const resGetAllBackups = await this.repositoryManager.getBackupsRepository().getAllBackups();
        if (resGetAllBackups) {
          const dataToSend = this.mapAllBackupsName(resGetAllBackups);
          this.viewCtx.setState({
            backupsGridData: dataToSend,
            alertVisible: true,
            message: 'Backup creada correctamente',
            showSpinnerBtn: 'd-none',
            disabledBtn: false,
          });
          setTimeout(() => {
            this.viewCtx.setState({ alertVisible: null });
          }, 5000);
        }
      }
    } catch (error) {
      this.viewCtx.setState({ showSpinnerBtn: 'd-none', disabledBtn: false });
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  restoreBackup = async (idBackup: any) => {
    try {
      this.viewCtx.setState({ showSpinnerRestore: 'd-block', disabledBtn: true });
      const res = await this.repositoryManager.getBackupsRepository().restoreBackup(idBackup);
      if (res) {
        this.viewCtx.setState({
          alertVisible: true,
          message: 'Backup restablecida correctamente',
          showSpinnerRestore: 'd-none',
          backupName: undefined,
          modal: false,
          disabledBtn: false,
        });
        setTimeout(() => {
          this.viewCtx.setState({ alertVisible: null });
        }, 5000);
      }
    } catch (error) {
      this.viewCtx.setState({ showSpinnerRestore: 'd-none', disabledBtn: false });
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  deleteBackup = async (backupName: string) => {
    try {
      this.viewCtx.setState({ disabledTrashBtn: true });
      const res = await this.repositoryManager.getBackupsRepository().deleteBackup(backupName)
      if (res) {
        this.viewCtx.setState({
          alertVisible: true,
          message: 'Backup eliminada correctamente',
          backupName: undefined,
          modal: false,
          disabledTrashBtn: false,
        });
        this.getAllBackups()
        setTimeout(() => {
          this.viewCtx.setState({ alertVisible: null });
        }, 5000);
      }
    } catch (error) {
      this.viewCtx.setState({ disabledTrashBtn: false });
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  }
}
