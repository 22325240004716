import RepositoryManager from '@ComponentsRoot/core/RepositoryManager';
import { OutletContextType } from '@ComponentsRoot/core/types/OutletContextType';

export default class SkillsController {
  viewCtx;
  repositoryManager: RepositoryManager;
  constructor(viewCtx: any, viewProps: { outletContext: OutletContextType }) {
    this.viewCtx = viewCtx;
    this.repositoryManager = viewProps.outletContext.repositoryManager;
  }

  setError = (error: any) => {
    this.viewCtx.setState({ isError: error });
  };

  toggleError = () => {
    this.viewCtx.setState({ isError: null });
  };

  getAllSkills = async () => {
    try {
      this.viewCtx.setState({ showSpinner: 'd-block' });
      const res = await this.repositoryManager.getSkillsRepository().getAllSkills();
      if (res) {
        this.viewCtx.setState({ showSpinner: 'd-none', skillsGridData: res });
      }
    } catch (error) {
      this.viewCtx.setState({ showSpinner: 'd-none' });
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  addSkill = async (data: string) => {
    try {
      const dataToSent = {
        name: data,
      };
      this.viewCtx.setState({ showSpinnerBtn: 'd-block', disabledBtn: true });
      const res = await this.repositoryManager.getSkillsRepository().addSkills(dataToSent);
      if (res) {
        const resGetAllSkills = await this.repositoryManager.getSkillsRepository().getAllSkills();
        if (resGetAllSkills) {
          this.viewCtx.setState({
            showSpinnerBtn: 'd-none',
            messageAlert: 'Skill creada correctamente',
            showAlert: true,
            disabledBtn: false,
            skillsGridData: resGetAllSkills,
            modal: false,
          });
          setTimeout(() => {
            this.viewCtx.setState({ showAlert: false });
          }, 5000);
        }
      }
    } catch (error) {
      this.viewCtx.setState({ showSpinnerBtn: 'd-none', disabledBtn: false });
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  deleteSkill = async (skillId: string) => {
    try {
      this.viewCtx.setState({ disabledDelBtn: true });
      const resGetSubSkills = await this.repositoryManager.getSkillsRepository().getSubSkills(skillId);
      console.log(resGetSubSkills.length);
      resGetSubSkills.forEach(async (element: any) => {
        // Eliminar tecnicos subhabilidades
        const resDeleteSubSkills = await this.repositoryManager.getSkillsRepository().deleteSubSkill(element.id);
        console.log(resDeleteSubSkills);
      });
      const res = await this.repositoryManager.getSkillsRepository().deleteSkill(skillId);
      if (res) {
        const resGetAllSkills = await this.repositoryManager.getSkillsRepository().getAllSkills();
        if (resGetAllSkills) {
          this.viewCtx.setState({
            messageAlert: 'Skill eliminada correctamente',
            showAlert: true,
            disabledDelBtn: false,
            skillsGridData: resGetAllSkills,
            skillDelete: res,
          });
          setTimeout(() => {
            this.viewCtx.setState({ showAlert: false });
          }, 5000);
        }
      }
    } catch (error) {
      this.viewCtx.setState({ disabledDelBtn: false });
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };
}
