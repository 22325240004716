import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import ConfigManager from '@ComponentsRoot/core/ConfigManager';
import { loginRequest } from '@ComponentsRoot/MsalAuth/authConfig';
import axios from 'axios';

export default class AxiosClient {
  private headers: object;
  private cfgManager: ConfigManager = new ConfigManager();
  private msalInstance: PublicClientApplication;
  constructor(msalInstance: PublicClientApplication) {
    this.headers = {};
    this.msalInstance = msalInstance;
    this.setupAxiosInterceptors();
  }

  setupAxiosInterceptors = () => {
    axios.interceptors.response.use(null as any, (error) => {
      if (error.config && error.response && error.response.status === 401) {
        // UNAUTHORIZED RESPONSE, TIME TO REFRESH TOKEN AND RETRY LAST CALL
        return this.refreshAccessToken(this.msalInstance).then((token) => {
          console.info('Unauthorized! Token Refreshed, time to retry last call...');

          const newHeaders = { Authorization: 'Bearer ' + token.id_token, MSGraphAccessToken: token.access_token };

          // REsetup local class variable headers
          this.setupHeaders(newHeaders);

          // Set new headers too on the last call before we retry it
          error.config.headers = { ...error.config.headers, ...newHeaders };

          // return last call to retry it
          return axios.request(error.config);
        });
      }

      return Promise.reject(error);
    });
  };

  setupHeaders = (headers: any) => {
    this.headers = { ...this.headers, ...headers };
  };

  private readonly urlBase: string = this.cfgManager.getApiEndpoint();

  // MAIN CALLS REGION --------------------------------------------------------------------------

  /**
   * @param {string}  url url a la cual consultar
   * esta funcion detecta si es una nueva url base (comienza con http:// o https://).
   * en caso de ser asi, retorna la url. en caso contrario, se asume que es un fragmento
   * de path por lo que se concatena con la constante urlBase
   **/
  readUrl = (url = '') => (url.startsWith('http://') || url.startsWith('https://') ? url : `${this.urlBase}/${url}`);

  get = async (url = '', headers = this.headers) =>
    await axios.get(this.readUrl(url), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        ...headers,
      },
    });

  post = async (url = '', body = {}, headers = this.headers) =>
    await axios.post(this.readUrl(url), body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        ...headers,
      },
    });

  put = async (url = '', body = {}, headers = this.headers) =>
    await axios.put(this.readUrl(url), body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
    });

  del = async (url = '', headers = this.headers) =>
    await axios.delete(this.readUrl(url), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
    });

  // -- END MAIN REGION CALLS

  // Returns refreshed accessToken and id_token
  refreshAccessToken = async (msalInstance: PublicClientApplication): Promise<any> => {
    try {
      const account = msalInstance.getActiveAccount();
      if (!account) {
        throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
      }
      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });

      return { access_token: response.accessToken, id_token: response.idToken };
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        return msalInstance
          .acquireTokenPopup(loginRequest)
          .then((resp: any) => {
            return { access_token: resp.accessToken };
          })
          .catch((err: any) => {
            console.log(err);
            msalInstance.loginRedirect();
          });
      } else {
        msalInstance.loginRedirect();

        console.log(error);
      }
    }
  };
}
