import RepositoryManager from '@ComponentsRoot/core/RepositoryManager';
import { InjectedPropsType } from '@ComponentsRoot/core/types/InjectedPropsType';
import moment from 'moment';

export default class DashboardController {
  repositoryManager: RepositoryManager;
  setIsError: any;
  constructor(viewProps: InjectedPropsType, setIsError: any) {
    this.repositoryManager = viewProps.outletContext!.repositoryManager;
    this.setIsError = setIsError;
  }

  setError = (error: any) => {
    this.setIsError(error);
  };

  toggleError = () => {
    this.setIsError(null);
  };

  getUncoverdTurns = async (
    props: InjectedPropsType,
    setUncoverTurns: any,
    setResolveTurns: any,
    setShowSpinner: any,
    setDataResults: any
  ) => {
    try {
      setShowSpinner('d-block');
      const res = await this.repositoryManager.getTurnsRepository().getUncoverdTurns(props.outletContext?.accessToken);
      if (res) {
        setShowSpinner('d-none');
        setUncoverTurns(res);
        setDataResults(res);
        setResolveTurns(null);
      }
    } catch (error) {
      setShowSpinner('d-none');
      this.setError(error);
    }
  };

  getResolveTurns = async (
    props: InjectedPropsType,
    setResolveTurns: any,
    setUncoverTurns: any,
    setShowSpinnerBtn: any,
    setDisabledBtn: any,
    setDataResults: any
  ) => {
    try {
      setShowSpinnerBtn('d-block');
      setDisabledBtn(true);
      const res = await this.repositoryManager.getTurnsRepository().getResolveTurns(props.outletContext?.accessToken);
      res.forEach((itm: any) => {
        if (itm.affectedTurns?.length) {
          itm.expanded = false;
        } else {
          itm.expanded = null;
        }
      });
      if (res) {
        setShowSpinnerBtn('d-none');
        setDisabledBtn(false);
        setUncoverTurns(null);
        setResolveTurns(res);
        setDataResults(res);
      }
    } catch (error) {
      setShowSpinnerBtn('d-none');
      setDisabledBtn(false);
      this.setError(error);
    }
  };

  uncoverTurnsAlert = async (setSuccessAlertVisible: any) => {
    try {
      const res = await this.repositoryManager.getTurnsRepository().uncoverTurnsAlert();
      if (res.status === true) {
        setSuccessAlertVisible(true);
        setTimeout(() => {
          setSuccessAlertVisible(false);
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  resolveTurns = async (data: any, setDisabledBtn: any, setSuccessResolveTurn: any) => {
    try {
      setDisabledBtn(true);
      const res = await this.repositoryManager.getTurnsRepository().resolveTurnsUncovereds(data);
      if (res) {
        setDisabledBtn(false);
        setSuccessResolveTurn(true);
        setTimeout(() => {
          setSuccessResolveTurn(false);
        }, 5000);
      }
    } catch (error) {
      setDisabledBtn(false);
      this.setError(error);
    }
  };

  setUpDate = (fechaIni: any, fechaEnd: any) => {
    const convertFechaIni = moment(fechaIni);
    const dateFormatIniMoment = moment.parseZone(convertFechaIni).utc().format();
    const formatDateIni = moment(dateFormatIniMoment).format('DD/MM/YYYY, HH:mm');

    const convertFechaFin = moment(fechaEnd);
    const dateFormatFinMoment = moment.parseZone(convertFechaFin).utc().format();
    const formatDateFin = moment(dateFormatFinMoment).format('DD/MM/YYYY, HH:mm');

    const dateStart = formatDateIni.split(',')[0];
    const timeStart = formatDateIni.split(',')[1];
    const timeEnd = formatDateFin.split(',')[1];

    return `${dateStart} ${timeStart.trim()} - ${timeEnd.trim()}`;
  };

  createBackup = async () => {
    try {
      const date = new Date();
      const timestamp = date.getTime();
      const res = await this.repositoryManager.getBackupsRepository().createBackup(timestamp);
      if (res) return true
    } catch (error){
      this.setError(error);
    }
  }

  createTurns = async (coverTurns: any) => {
    try {
      const res = await this.repositoryManager.getTurnsRepository().resolveTurnsUncovereds(coverTurns);
      if (res) return true
    } catch (error){
      this.setError(error);
    }
  }
}
