import { EditTurnFormValuesTypes } from '@ComponentsRoot/Services/view/EditServiceView';
import { EditServiceFormValuesTypes } from '@ComponentsRoot/Services/view/EditServiceView';
import { NewTurnFormValuesTypes } from '@ComponentsRoot/Services/view/ModalAddTurno';
import moment from 'moment';

class modifyTurnoInfo {
  serviceId?: string;
  name?: string;
  monday?: boolean;
  thuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
  startTime?: string;
  endTime?: string;
  inPerson?: boolean;
  skills?: {
    skillId: number;
    numbertechnicians: number;
  }[];
}

class modifyServiceSkills {
  skills?: {
    skillId?: number;
    numday?: number;
    numbertechnicians?: number;
  }[];
}

class createNewTurn {
  serviceId?: string;
  name?: string;
  monday?: boolean;
  thuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
  startTime?: string;
  endTime?: string;
  inPerson?: boolean;
  skills?: {
    skillId: number;
    numbertechnicians: number;
  }[];
}

class modifyService {
  linkedservices?: {
    serviceIdLink?: string;
  }[];
}

export default class ServicesMapper {
  mapEditService = (data: EditServiceFormValuesTypes) => {
    const editService = new modifyService();

    editService.linkedservices = [];
    data.linkedServices.forEach((linkedService: any) => {
      editService.linkedservices!.push({
        serviceIdLink: linkedService.value,
      });
    });

    return editService;
  };

  mapCreateNewTurn = (data: NewTurnFormValuesTypes) => {
    const newServiceTurn = new createNewTurn();

    newServiceTurn.serviceId = data.servicioIdNew;
    newServiceTurn.name = data.nameTurnoNew;
    data.mondayNew === undefined ? (newServiceTurn.monday = false) : (newServiceTurn.monday = data.mondayNew);

    data.thuesdayNew === undefined ? (newServiceTurn.thuesday = false) : (newServiceTurn.thuesday = data.thuesdayNew);

    data.wednesdayNew === undefined ? (newServiceTurn.wednesday = false) : (newServiceTurn.wednesday = data.wednesdayNew);

    data.thursdayNew === undefined ? (newServiceTurn.thursday = false) : (newServiceTurn.thursday = data.thursdayNew);

    data.fridayNew === undefined ? (newServiceTurn.friday = false) : (newServiceTurn.friday = data.fridayNew);

    data.saturdayNew === undefined ? (newServiceTurn.saturday = false) : (newServiceTurn.saturday = data.saturdayNew);

    data.sundayNew === undefined ? (newServiceTurn.sunday = false) : (newServiceTurn.sunday = data.sundayNew);

    const startTime = this.setUpDate(data.startTimeNew);
    const endTime = this.setUpDate(data.endTimeNew);

    newServiceTurn.startTime = startTime;
    newServiceTurn.endTime = endTime;

    data.onsiteNew === undefined ? (newServiceTurn.inPerson = false) : (newServiceTurn.inPerson = data.onsiteNew);

    newServiceTurn.skills = [];

    data.skills.forEach((skill: any) => {
      newServiceTurn.skills!.push({
        skillId: skill.value,
        numbertechnicians: skill.resources,
      });
    });

    return JSON.stringify(newServiceTurn);
  };

  mapEditSkillsService = (data: any) => {
    const newModifySkillsService = new modifyServiceSkills();

    newModifySkillsService.skills = [];

    data.forEach((skills: any) => {
      newModifySkillsService.skills!.push({
        skillId: skills.skillid,
        numday: skills.numday,
        numbertechnicians: skills.numbertechnicians,
      });
    });

    return newModifySkillsService;
  };

  mapSaveTurn = (data: EditTurnFormValuesTypes) => {
    const newModifyTurn = new modifyTurnoInfo();

    newModifyTurn.serviceId = data.servicioId;
    newModifyTurn.name = data.name;
    data.monday === undefined ? (newModifyTurn.monday = false) : (newModifyTurn.monday = data.monday);

    data.thuesday === undefined ? (newModifyTurn.thuesday = false) : (newModifyTurn.thuesday = data.thuesday);

    data.wednesday === undefined ? (newModifyTurn.wednesday = false) : (newModifyTurn.wednesday = data.wednesday);

    data.thursday === undefined ? (newModifyTurn.thursday = false) : (newModifyTurn.thursday = data.thursday);

    data.friday === undefined ? (newModifyTurn.friday = false) : (newModifyTurn.friday = data.friday);

    data.saturday === undefined ? (newModifyTurn.saturday = false) : (newModifyTurn.saturday = data.saturday);

    data.sunday === undefined ? (newModifyTurn.sunday = false) : (newModifyTurn.sunday = data.sunday);

    const startTime = this.setUpDate(data.startTime);
    const endTime = this.setUpDate(data.endTime);

    newModifyTurn.startTime = startTime;
    newModifyTurn.endTime = endTime;

    data.onsite === undefined ? (newModifyTurn.inPerson = false) : (newModifyTurn.inPerson = data.onsite);

    newModifyTurn.skills = [];

    data.skills.forEach((skill: any) => {
      newModifyTurn.skills!.push({
        skillId: skill.value,
        numbertechnicians: skill.resources,
      });
    });

    return newModifyTurn;
  };

  setUpDate = (data: any) => {
    const date = moment();
    const time = data.split(':');
    date.set('hour', Number(time[0]));
    date.set('minute', Number(time[1]));
    date.set('second', 0o0);
    const formatDateTime = date.format();
    const dateTimeLocal = moment.parseZone(formatDateTime).local(true).format();

    return dateTimeLocal;
  };
}
