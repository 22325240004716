import '@Assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@Assets/scss/argon-dashboard-react.scss';

import React, { PureComponent } from 'react';
// import { Route, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";

// core components
// import FixedPlugin from "@ComponentsRoot/FixedPlugin/FixedPlugin.js";
// import Footer from "@ComponentsRoot/Footer/Footer.js";
import AdminNavbar from '@ComponentsRoot/Navbars/AdminNavbar';
import Sidebar from '@ComponentsRoot/Sidebar/Sidebar.js';

// import routes from "routes.js";

// import logo from "@Assets/img/react-logo";
// import logo from '@Assets/img/react-logo.png';
import ConfigManager from '@ComponentsRoot/core/ConfigManager';
import routes from '@RootPath/routes/routes';
import { Outlet } from 'react-router-dom';
import MainLayoutController from '../controller/MainLayoutController';
import { withMsal } from '@azure/msal-react';
import '../styles/MainLayoutStyle.scss'; 
// import { BackgroundColorContext } from "contexts/BackgroundColorContext";

// var ps: any;

class MainLayoutViewWithTheme extends PureComponent<any, any> {
  // const location = useLocation();
  mainPanelRef = React.createRef<any>();

  controller: MainLayoutController;

  constructor(props: any) {
    super(props);
    this.state = { sidebarOpened: document.documentElement.className.indexOf('nav-open') !== -1, accessToken: null };
    this.controller = new MainLayoutController(this);
  }

  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };

  componentDidMount = async() => {
    console.log(this.props.msalContext);
    await this.controller.getRefreshAccesToken(this.props.msalContext.instance);
    await this.controller.getUncoverTurnsAlertIcon();
    await this.controller.getBEVersion();
  }

  render() {
    if (this.state.accessToken !== null){
      return (
        <>
          <div className='wrapper'>
            <Sidebar
              {...this.props}
              routes={routes}
              logo={{
                innerLink: '/admin/index',
                imgSrc: require('@Assets/img/brand/sap_logo.png'),
                imgAlt: '...',
                beVersion: this.state.beVersion
              }}
            />
            <div className='main-content' ref={this.mainPanelRef}>
              <AdminNavbar imgUser={this.state.imgUser} iconAlert={this.state.iconAlert} />
              <div className='mainContainer pt-5 pt-md-8'>
                <div className='container-fluid'>
                  <Outlet
                    context={{
                      configManager: new ConfigManager(),
                      repositoryManager: this.controller.repositoryManager,
                      nodeApi: this.controller.nodeApiDS,
                      accessToken: this.state.accessToken,
                      imgUser: this.state.imgUser,
                      iconAlert: this.state.iconAlert
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }else{
      return (<></>);
    }
  }
}

export default withMsal(MainLayoutViewWithTheme);