// import '../styles/FormError.scss';

const FormErrorView = (props: { error: any }) => {
  const getTextBasedOnCondition = () => {
    if (props.error.type === 'required') {
      return (
        <p className='fadeIn badFormat'>
          campo obligatorio
          {/* <FormattedMessage id="formErrors.mandatoryFields" defaultMessage="Campo Obligatorio" /> */}
        </p>
      );
    } else if (props.error.type === 'pattern') {
      if (props.error.ref.name === 'password') {
        return (
          <p className='fadeIn badFormat'>
            password incorrecto
            {/* <FormattedMessage id="formErrors.badPassword" defaultMessage="Password incorreto" /> */}
          </p>
        );
      } else {
        return (
          <p className='fadeIn badFormat'>
            formato incorrecto
            {/* <FormattedMessage id="formErrors.badFormat" defaultMessage="Formato incorreto" /> */}
          </p>
        );
      }
    } else {
      if (props.error.type === 'minLength') {
        return (
          <p className='fadeIn badFormat'>
            Min length incorrecto
            {/* <FormattedMessage id="formErrors.badFormat" defaultMessage="Formato incorreto" /> */}
          </p>
        );
      }
      return (
        <p className='fadeIn badFormat'>
          formato incorrecto
          {/* <FormattedMessage id="formErrors.badFormat" defaultMessage="Formato incorreto" /> */}
        </p>
      );
    }
  };

  return getTextBasedOnCondition();
};

export default FormErrorView;
