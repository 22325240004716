import { EditTurnFormValuesTypes } from '@ComponentsRoot/Services/view/EditServiceView';
import { EditServiceFormValuesTypes } from '@ComponentsRoot/Services/view/EditServiceView';
import { NewTurnFormValuesTypes } from '@ComponentsRoot/Services/view/ModalAddTurno';
import ServicesMapper from '@DataMappers/ServicesMapper';
import NodeApiDS from '@DataSource/NodeApiDS';

export default class ServicesRepository {
  nodeApiDS: NodeApiDS;
  servicesMapper: ServicesMapper;
  constructor(nodeApiDS: NodeApiDS) {
    this.nodeApiDS = nodeApiDS;
    this.servicesMapper = new ServicesMapper();
  }

  getAllServices = async () => {
    try {
      const res = await this.nodeApiDS.callApi('service', 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  syncServices = async (tokenAccess: any) => {
    try {
      const token = {
        token: tokenAccess,
      };
      const res = await this.nodeApiDS.callApi('service/sync', 'post', token);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  getInfoServicio = async (serviceId: string) => {
    try {
      const res = await this.nodeApiDS.callApi(`service/${serviceId}/detail`, 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  getTurnosServicio = async (serviceId: string) => {
    try {
      const res = await this.nodeApiDS.callApi(`service/${serviceId}/servicesturn`, 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  getSkillsById = async (skill: any) => {
    try {
      const res = await this.nodeApiDS.callApi(`category/${skill}/skills`, 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  getServiceTurnById = async (turnId: number) => {
    try {
      const res = await this.nodeApiDS.callApi(`servicesturn/${turnId}`, 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  saveSkillsService = async (data: any, serviceId: any) => {
    try {
      const dataToSend = this.servicesMapper.mapEditSkillsService(data);
      const res = await this.nodeApiDS.callApi(`service/${serviceId}`, 'post', dataToSend);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  deleteServiceTurn = async (turnId: number) => {
    try {
      const res = await this.nodeApiDS.callApi(`servicesturn/${turnId}`, 'del');
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  saveTurno = async (data: EditTurnFormValuesTypes) => {
    try {
      const dataToSend = this.servicesMapper.mapSaveTurn(data);
      const res = await this.nodeApiDS.callApi(`servicesturn/${data.servicesTurnId}`, 'put', dataToSend);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  createNewTurn = async (data: NewTurnFormValuesTypes) => {
    try {
      const dataToSend = this.servicesMapper.mapCreateNewTurn(data);
      const res = await this.nodeApiDS.callApi(`servicesturn`, 'post', dataToSend);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  editService = async (data: EditServiceFormValuesTypes, serviceId: any) => {
    try {
      const dataToSend = this.servicesMapper.mapEditService(data);
      const res = await this.nodeApiDS.callApi(`service/${serviceId}`, 'put', dataToSend);
      return res.data;
    } catch (error) {
      throw error;
    }
  };
}
