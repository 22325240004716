export default class ConfigManager {
  getApiEndpoint = () => {
    // const currentEnv = process.env.REACT_APP_HOST_ENV as string;
    // console.log(process.env.REACT_APP_HOST_ENV);
    // return process.env[`REACT_APP_PROCESS_API_ENDPOINT_${currentEnv.toUpperCase().trim()}`] as string;
    return process.env.REACT_APP_API_ENDPOINT?.trim() as string;
  };

  getReactOauthRedirect = () => {
    // const currentEnv = process.env.REACT_APP_HOST_ENV as string;
    // console.log(process.env.REACT_APP_HOST_ENV);
    // return process.env[`REACT_APP_OAUTH_REDIRECT_${currentEnv.toUpperCase().trim()}`] as string;
    return process.env.REACT_APP_REDIRECT_URL as string;
  };
}
