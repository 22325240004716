import RepositoryManager from '@ComponentsRoot/core/RepositoryManager';
import { InjectedPropsType } from '@ComponentsRoot/core/types/InjectedPropsType';

export default class AddSkillController {
  repositoryManager: RepositoryManager;
  skillId: string;
  setIsError: any;
  constructor(viewProps: InjectedPropsType, skillId: any, setIsError: any) {
    this.repositoryManager = viewProps.outletContext!.repositoryManager;
    this.skillId = skillId;
    this.setIsError = setIsError;
  }

  setError = (error: any) => {
    this.setIsError(error);
  };

  toggleError = () => {
    this.setIsError(null);
  };

  getDetailSkill = async (skillId: string, setDetailDataFn: any, setShowSpinner: any) => {
    try {
      setShowSpinner('d-block');
      const res = await this.repositoryManager.getSkillsRepository().getDetailSkill(skillId);
      if (res) {
        setShowSpinner('d-none');
        setDetailDataFn(res);
      }
    } catch (error) {
      setShowSpinner('d-none');
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  getSubSkills = async (skillId: string, setSubDetailDataFn: any, setOptions: any) => {
    try {
      const res = await this.repositoryManager.getSkillsRepository().getSubSkills(skillId);
      if (res) {
        setSubDetailDataFn(res);
        const opcionesAPI = res.map((opcion: any) => ({
          value: opcion.id,
          label: opcion.name,
        }));
        setOptions(opcionesAPI);
      }
    } catch (error) {
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  addSkill = async (skill: any, setSuccessAlertVisible: any, setIconSuccess: any, setMessageSuccess: any, setOptions: any) => {
    try {
      const dataToSend = {
        name: skill,
        categoryId: Number(this.skillId),
      };
      const res = await this.repositoryManager.getSkillsRepository().addSubSkills(dataToSend);
      if (res) {
        const resGetSkills = await this.repositoryManager.getSkillsRepository().getSubSkills(this.skillId);
        if (resGetSkills) {
          const opcionesAPI = resGetSkills.map((opcion: any) => ({
            value: opcion.id,
            label: opcion.name,
          }));
          setSuccessAlertVisible(true);
          setIconSuccess('k-i-check-circle');
          setMessageSuccess('Skill creada correctamente');
          setTimeout(() => {
            setSuccessAlertVisible(null);
          }, 5000);
          return opcionesAPI;
        }
      }
    } catch (error) {
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  deleteSkill = async (subSkillId: any) => {
    try {
      const res = await this.repositoryManager.getSkillsRepository().deleteSubSkill(subSkillId);
      if (res) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  setUpOptionDropdownSkills = (skillData: any) => {
    return skillData.map((item: any) => {
      return { value: item.id, label: item.name };
    });
  };

  setUpDefaultOptionDropdownSkillsServices = (skillObj: any) => {
    return skillObj.map((item: any) => {
      return { value: item.id, label: item.name };
    });
  };
}
