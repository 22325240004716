import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { OutletContextType } from './types/OutletContextType';

export const MainWrapperWithUseOutletContext = (props: any) => {
  const outletContext: OutletContextType = useOutletContext<OutletContextType>();

  return (
    <>
      {React.Children.map(props.children, (child) => {
        return React.cloneElement(child, { outletContext: outletContext }, null);
      })}
    </>
  );
};
