import RepositoryManager from '@ComponentsRoot/core/RepositoryManager';
import { OutletContextType } from '@ComponentsRoot/core/types/OutletContextType';

export default class TechnicianController {
  viewCtx;
  repositoryManager: RepositoryManager;
  constructor(viewCtx: any, viewProps: { outletContext: OutletContextType }) {
    this.viewCtx = viewCtx;
    this.repositoryManager = viewProps.outletContext.repositoryManager;
  }

  setError = (error: any) => {
    this.viewCtx.setState({ isError: error });
  };

  toggleError = () => {
    this.viewCtx.setState({ isError: null });
  };

  getAllTechnician = async () => {
    try {
      this.viewCtx.setState({ showSpinner: 'd-block' });
      const res = await this.repositoryManager
        .getTechnicianRepository()
        .getAllTechnician(this.viewCtx.props.outletContext.accessToken);
      if (res) {
        this.viewCtx.setState({ showSpinner: 'd-none', techGridData: res });
      }
    } catch (error) {
      this.viewCtx.setState({ showSpinner: 'd-none' });
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  syncTechnician = async () => {
    try {
      this.viewCtx.setState({ showSpinnerBtn: 'd-block', disabledBtn: true });
      const res = await this.repositoryManager
        .getTechnicianRepository()
        .syncTechnician(this.viewCtx.props.outletContext.accessToken);
      if (res) {
        const res = await this.repositoryManager
          .getTechnicianRepository()
          .getAllTechnician(this.viewCtx.props.outletContext.accessToken);
        if (res) {
          this.viewCtx.setState({ showAlert: true, disabledBtn: false });
          setTimeout(() => {
            this.viewCtx.setState({ showAlert: false });
          }, 5000);
          this.viewCtx.setState({ showSpinnerBtn: 'd-none', techGridData: res });
        }
      }
    } catch (error) {
      this.viewCtx.setState({ showSpinnerBtn: 'd-none', disabledBtn: false });
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };
}
