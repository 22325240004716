import RepositoryManager from '@ComponentsRoot/core/RepositoryManager';
import { InjectedPropsType } from '@ComponentsRoot/core/types/InjectedPropsType';
// import { any } from 'prop-types';
import { EditTurnFormValuesTypes } from '../view/EditServiceView';
import { EditServiceFormValuesTypes } from '../view/EditServiceView';
import { NewTurnFormValuesTypes } from '../view/ModalAddTurno';
import moment from 'moment';

export default class EditServiceController {
  repositoryManager: RepositoryManager;
  serviceId: string;
  setIsError: any;
  constructor(viewProps: InjectedPropsType, serviceId: any, setIsError: any) {
    this.repositoryManager = viewProps.outletContext!.repositoryManager;
    this.serviceId = serviceId;
    this.setIsError = setIsError;
  }

  setError = (error: any) => {
    this.setIsError(error);
  };

  toggleError = () => {
    this.setIsError(null);
  };

  getAllServices = async (setAllServices: any) => {
    try {
      const res = await this.repositoryManager.getServicesRepository().getAllServices();
      setAllServices(res);
    } catch (error) {
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  getInfoServicio = async (serviceId: string, setDetailDataFn: any, setShowSpinner: any) => {
    try {
      setShowSpinner('d-block');
      const res = await this.repositoryManager.getServicesRepository().getInfoServicio(serviceId);
      if (res) {
        setShowSpinner('d-none');
        setDetailDataFn(res);
      }
    } catch (error) {
      setShowSpinner('d-none');
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  getTurnosServicio = async (serviceId: string, setDetailTurno: any) => {
    try {
      const res = await this.repositoryManager.getServicesRepository().getTurnosServicio(serviceId);
      if (res) {
        setDetailTurno(res);
      }
    } catch (error) {
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  getServiceTurnById = async (turnId: number, setServiceTurnDetailById: any) => {
    try {
      const res = await this.repositoryManager.getServicesRepository().getServiceTurnById(turnId);
      if (res) {
        setServiceTurnDetailById(res);
      }
    } catch (error) {
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  getAllSkills = async (setSkillsData: any) => {
    try {
      const res = await this.repositoryManager.getTechnicianRepository().getAllSkills();
      if (res) {
        setSkillsData(res);
      }
    } catch (error) {
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  getAllSubSkills = async (setAllSubSkills: any) => {
    try {
      const res = await this.repositoryManager.getTechnicianRepository().getAllSubSkills();
      if (res) {
        setAllSubSkills(res);
      }
    } catch (error) {
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  getSkillsById = async (skills: any, setSkillsById: any, setCategoryId: any) => {
    try {
      const res = await this.repositoryManager.getServicesRepository().getSkillsById(skills.id);
      if (res) {
        setSkillsById(res);
        setCategoryId(skills.id);
      }
    } catch (error) {
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  getSubSkillsById = async (skills: any[], setSubSkillsDataById: any) => {
    try {
      const promises: Array<Promise<object | undefined>> = [];
      skills.forEach((skill) => {
        promises.push(this.repositoryManager.getTechnicianRepository().getSubSkillsById(skill.id));
      });

      const resultOfAll = await Promise.all(promises).catch((error) => {
        throw error;
      });

      if (resultOfAll) {
        setSubSkillsDataById(resultOfAll);
      }
    } catch (error) {
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  setUpOptionDropdownSkills = (skillObj: any, skillsByIdArr: any[]) => {
    const skills: any = [];
    skillsByIdArr.forEach((item) => {
      skills.push(...item);
    });
    const founded = skills.filter((skill: any) => skill.habilidadId === skillObj.id);
    if (founded) {
      return founded.map((FItem: any) => {
        return { value: FItem.id, label: FItem.nombre };
      });
    }
    return [];
  };

  setOptionsSkills = async (categoryId: any) => {
    const skillArr: any[] = [];
    const skillsByIdArr = await this.repositoryManager.getServicesRepository().getSkillsById(categoryId);
    skillsByIdArr.forEach((item: any) => {
      if (item.categoryId === categoryId) {
        skillArr.push({ label: item.name, value: item.id, resources: 0 });
      }
    });

    return skillArr;

    // const founded = skillsByIdArr.filter((skill: any) => skill.categoryId === categoryId);
    // if (founded) {
    //   return founded.map((FItem: any) => {
    //     return { value: FItem.id, label: FItem.name, resources: 1 };
    //   });
    // }
    // return [];
  };

  // setUpDefaultOptionDropdownSkillsServices = (skillObj: any, categoryId: any) => {
  //   return skillObj.serviceSkills.map((item: any) => {
  //     if (item.skill.habilidadId === categoryId) {
  //       return { value: item.skillid, label: item.skill.nombre };
  //     }
  //   });
  // };

  setUpDefaultOptionDropdownNewSkillsServices = (newSkillsTurnDropdown: any, categoryId: any) => {
    const skillArr: any[] = [];
    newSkillsTurnDropdown.forEach((item: any) => {
      if (item.categoryId === categoryId) {
        skillArr.push({ value: item.value, label: item.label, resources: item.resources });
      }
    });
    return skillArr;
  };

  setUpDefaultOptionDropdownSkillsServicesByTurn = (skills: any, categoryId: any, editSkillsTurnDropdown: any) => {
    if (skills) {
      const skillArr: any[] = [];
      skills.forEach((item: any) => {
        if (item.skill.categoryId === categoryId) {
          skillArr.push({ value: item.skillId, label: item.skill.name, resources: item.numbertechnicians });
        }
      });

      if (editSkillsTurnDropdown.length !== 0) {
        editSkillsTurnDropdown.forEach((item: any) => {
          if (item.categoryId === categoryId) {
            skillArr.push({ value: item.value, label: item.label, resources: item.resources });
          }
        });
      }
      return skillArr;
    }
  };

  getLinkedServices = (serviceDetailData: any) => {
    const serviceArr: any[] = [];
    serviceDetailData.linkedServices.forEach((item: any) => {
      serviceArr.push({ value: item.service.id, label: item.service.name });
    });
    return serviceArr;
  };

  editNewSkillTurn = async (
    skillTurn: any,
    resourcesSkill: any,
    categoryId: any,
    skills: any,
    editSkillsTurnDropdown: any,
    setEditSkillsTurnDropdown: any,
    toggleEditSkills: any
  ) => {
    if (resourcesSkill < 1) {
      const error = {
        message: 'La cantidad mínima de recursos ha de ser mayor o igual a 1',
      };
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    } else {
      //Comprobamos si existe alguna habilidad nueva
      const resNewSkill = await this.foundSkills(skillTurn, categoryId);
      if (resNewSkill) {
        editSkillsTurnDropdown.push({
          value: skillTurn.value,
          label: skillTurn.label,
          resources: Number(resourcesSkill),
          categoryId: categoryId,
        });
        setEditSkillsTurnDropdown(editSkillsTurnDropdown);
        this.setUpDefaultOptionDropdownSkillsServicesByTurn(skills, categoryId, editSkillsTurnDropdown);
        toggleEditSkills(false);
      }
    }
  };

  setUpDate = (fecha: any) => {
    const dateFormat = new Date(fecha).toLocaleDateString('es-ES', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });

    return dateFormat;
  };

  parseZoneUTC = (data: any) => {
    const date = moment.utc();
    const time = data.split(':');
    date.set('hour', Number(time[0]));
    date.set('minute', Number(time[1]));
    date.set('second', 0);
    const formatDateTime = date.format();
    const dateTimeLocal = moment.parseZone(formatDateTime).local().format();
    const timeFormat = moment(dateTimeLocal).format('HH:mm');
    return timeFormat;
  };

  addSkill = async (serviceSkillsData: any, skillId: any, recursos: any, serviceId: any, numDay: any, setModalIsOpen: any) => {
    const serviceSkillsDataArr: any = [];
    serviceSkillsData.serviceSkills.forEach((item: any) => {
      const skill = {
        skillid: item.skillid,
        numday: item.numday,
        numbertechnicians: item.numbertechnicians,
      };
      serviceSkillsDataArr.push(skill);
    });

    const newSkill = {
      skillid: skillId.value,
      numday: numDay,
      numbertechnicians: Number(recursos),
    };
    serviceSkillsDataArr.push(newSkill);

    try {
      const res = await this.repositoryManager.getServicesRepository().saveSkillsService(serviceSkillsDataArr, serviceId);
      if (res) {
        setModalIsOpen(false);
      }
    } catch (error) {
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  deleteServiceTurn = async (
    turnId: number,
    serviceId: any,
    setDetailTurno: any,
    setSuccessAlertVisible: any,
    setIconSuccess: any,
    setMessageSuccess: any
  ) => {
    try {
      const res = await this.repositoryManager.getServicesRepository().deleteServiceTurn(turnId);
      if (res) {
        const resDataTurn = await this.repositoryManager.getServicesRepository().getTurnosServicio(serviceId);
        if (resDataTurn) {
          setDetailTurno(resDataTurn);
          setSuccessAlertVisible(true);
          setTimeout(() => {
            setSuccessAlertVisible(null);
          }, 5000);
          setIconSuccess('k-i-check-circle');
          setMessageSuccess('Turno eliminado correctamente');
        }
      }
    } catch (error) {
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  saveTurno = async (
    data: EditTurnFormValuesTypes,
    setDetailTurno: any,
    setModalEditIsOpen: any,
    setSuccessAlertVisible: any,
    setIconSuccess: any,
    setMessageSuccess: any,
    setServiceTurnDetailById: any,
    setShowSpinnerBtnTurn: any,
    setDisabledBtn: any
  ) => {
    try {
      setShowSpinnerBtnTurn('d-block');
      setDisabledBtn(true);
      const res = await this.repositoryManager.getServicesRepository().saveTurno(data);
      if (res) {
        await this.getTurnosServicio(this.serviceId, setDetailTurno);
        setShowSpinnerBtnTurn('d-none');
        setDisabledBtn(false);
        setModalEditIsOpen(false);
        setSuccessAlertVisible(true);
        setTimeout(() => {
          setSuccessAlertVisible(null);
        }, 5000);
        setIconSuccess('k-i-check-circle');
        setMessageSuccess('Turno editado correctamente');
        setServiceTurnDetailById(null);
      }
    } catch (error) {
      setShowSpinnerBtnTurn('d-none');
      setDisabledBtn(false);
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  createNewTurn = async (
    data: NewTurnFormValuesTypes,
    // skills: any,
    setDetailTurno: any,
    setModalIsOpen: any,
    setSuccessAlertVisible: any,
    setIconSuccess: any,
    setMessageSuccess: any,
    setServiceTurnDetailById: any,
    setShowSpinnerBtnTurn: any,
    setDisabledBtn: any
  ) => {
    try {
      setShowSpinnerBtnTurn('d-block');
      setDisabledBtn(true);
      const res = await this.repositoryManager.getServicesRepository().createNewTurn(data);
      if (res) {
        await this.getTurnosServicio(this.serviceId, setDetailTurno);
        setShowSpinnerBtnTurn('d-none');
        setDisabledBtn(false);
        setModalIsOpen(false);
        setSuccessAlertVisible(true);
        setTimeout(() => {
          setSuccessAlertVisible(null);
        }, 5000);
        setIconSuccess('k-i-check-circle');
        setMessageSuccess('Turno creado correctamente');
        setServiceTurnDetailById(null);
      }
    } catch (error) {
      setShowSpinnerBtnTurn('d-none');
      setDisabledBtn(false);
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  editService = async (
    data: EditServiceFormValuesTypes,
    setSuccessAlertVisible: any,
    setIconSuccess: any,
    setMessageSuccess: any,
    setShowSpinnerBtn: any,
    setDisabledBtn: any
  ) => {
    try {
      setShowSpinnerBtn('d-block');
      setDisabledBtn(true);
      const res = await this.repositoryManager.getServicesRepository().editService(data, this.serviceId);
      if (res) {
        setShowSpinnerBtn('d-none');
        setDisabledBtn(false);
        setSuccessAlertVisible(true);
        setTimeout(() => {
          setSuccessAlertVisible(null);
        }, 5000);
        setIconSuccess('k-i-check-circle');
        setMessageSuccess('Servicio vinculado correctamente');
      }
    } catch (error) {
      setShowSpinnerBtn('d-none');
      setDisabledBtn(false);
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  foundSkills = async (data: any, categoryId: any) => {
    const allSubSkills = await this.repositoryManager.getTechnicianRepository().getAllSubSkills();
    const newSkills: any = [];

    const coincidencia = Object.values(allSubSkills).map((valorObjeto: any) => {
      if (data.label === valorObjeto.name) {
        // No se hace nada si hay coincidencia
        return true;
      }
      return false;
    });

    if (!coincidencia.includes(true)) {
      // Guardar el valor no coincidente en el array
      newSkills.push(data);
    }

    const dataToSend = {
      name: data.label,
      categoryId: categoryId,
    };

    const res = await this.repositoryManager.getSkillsRepository().addSubSkills(dataToSend);
    if (res) {
      return true;
    }
  };

  addNewSkill = async (
    newSkill: any,
    categoryId: any,
    resources: any,
    setSkillSelectedOptions: any,
    skillSelectedOptions: any
  ) => {
    const dataToSend = {
      name: newSkill,
      categoryId: categoryId,
    };

    const res = await this.repositoryManager.getSkillsRepository().addSubSkills(dataToSend);
    if (res) {
      const allSubSkills = await this.repositoryManager.getTechnicianRepository().getAllSubSkills();
      if (allSubSkills.length) {
        const lastSkill = allSubSkills.filter((objeto: { name: any }) => objeto.name === newSkill);
        const selectedOption = {
          value: lastSkill[0].id,
          label: lastSkill[0].name,
          resources: Number(resources) ?? 1,
          categoryId: lastSkill[0].categoryId,
        };
        const res = this.setSelectedSkillOption(selectedOption, categoryId, resources, setSkillSelectedOptions, skillSelectedOptions);
        return res
      }
    }
  };

  setSelectedSkillOption = (
    selectedOption: any,
    categoryId: any,
    resources: any,
    setSkillSelectedOptions: any,
    skillSelectedOptions: any
  ) => {
    const skillArr: any = [];
    skillSelectedOptions.forEach((item: any) => {
      const skill = {
        value: item.value,
        label: item.label,
        resources: item.resources,
        categoryId: item.categoryId,
      };
      skillArr.push(skill);
    });
    const newSkill = {
      value: selectedOption.value,
      label: selectedOption.label,
      resources: Number(resources) ?? 1,
      categoryId: categoryId,
    };
    skillArr.push(newSkill);
    setSkillSelectedOptions(skillArr);
    return skillArr
  };

  setUpSkillSelectedOptions = async (turnData: any, idTurno: any, setSkillSelectedOptions: any) => {
    const skillArr: any = [];
    const turnArr = turnData
      .filter((turn: any) => turn.id === idTurno) // Filtrar por categoryId
      .map((turn: any) => ({
        skills: turn.skills,
      }));
    turnArr[0].skills.forEach((item: any) => {
      const skills = {
        value: item.skill.id,
        label: item.skill.name,
        resources: item.numbertechnicians,
        categoryId: item.skill.categoryId,
      };
      skillArr.push(skills);
      setSkillSelectedOptions(skillArr);
    });
  };
}
