import { InjectedPropsType } from '@ComponentsRoot/core/types/InjectedPropsType';
import { OutletContextType } from '@ComponentsRoot/core/types/OutletContextType';
import { process } from '@progress/kendo-data-query';
import {
  Grid,
  GridColumn as Column,
  // GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
  GridColumnMenuProps,
  GridDataStateChangeEvent,
} from '@progress/kendo-react-grid';
import { Button as GridButton } from '@progress/kendo-react-buttons';
import React from 'react';
import '@progress/kendo-theme-default/dist/all.css';
import ServicesController from '../controller/ServicesController';
import products from '../data/turnos.json';
import { Row, Col, Button, Spinner, Alert } from 'reactstrap';
import { ErrorHandler } from '@ComponentsRoot/ErrorHandler/ErrorHandler';
import { cleanGridColumnMenuIncomingPropsGridUtils } from '@MainUtils/GridUtils';

class ColumnMenu extends React.Component<GridColumnMenuProps> {
  render() {
    return (
      <div>
        <GridColumnMenuFilter {...this.props} expanded={true} />
      </div>
    );
  }
}

export default class ServicesGridView extends React.Component<
  InjectedPropsType,
  {
    take: number;
    skip: number;
    servicesGridData: undefined | any[];
    gridData: any;
    isError: any;
    showSpinner: any;
    showSpinnerBtn: any;
    showAlert: any;
    disabledBtn: any;
  }
> {
  controller: ServicesController;

  constructor(props: any) {
    super(props);
    this.controller = new ServicesController(this, this.props as { outletContext: OutletContextType });

    this.state = {
      take: 20,
      skip: 0,
      servicesGridData: undefined,
      gridData: products,
      isError: undefined,
      showSpinner: undefined,
      showSpinnerBtn: 'd-none',
      showAlert: false,
      disabledBtn: false,
    };
  }

  renderErrorHandler = () => {
    if (this.state.isError) {
      return <ErrorHandler isOpen toggle={this.controller.toggleError} message={this.state.isError.message} />;
    }
    return <></>;
  };

  componentDidMount = async () => {
    if (this.state.servicesGridData === undefined) {
      await this.controller.getAllServices();
    }
  };

  onDismiss = () => this.setState({ showAlert: false });

  dataStateChange = (event: GridDataStateChangeEvent) => {
    this.setState({ ...(event.dataState as any) });
  };

  render() {
    return (
      <>
        <Row>
          <Col lg='12'>
            <h1>Listado de servicios</h1>
            <Button
              className='mb-4 btn-seidor bg-seidor-medium-blue'
              disabled={this.state.disabledBtn}
              onClick={(e: any) => this.controller.syncServices()}
            >
              Sincronizar servicios
              <Spinner size='sm' className={`spinner-pos ${this.state.showSpinnerBtn}`}>
                Loading...
              </Spinner>
            </Button>
            <Spinner color='info' className={`m-center mt-5 ${this.state.showSpinner}`}>
              Loading...
            </Spinner>
            {this.state.servicesGridData && (
              <Grid
                {...this.state}
                data={process(this.state.servicesGridData === undefined ? [] : this.state.servicesGridData, this.state)}
                onDataStateChange={this.dataStateChange}
                sortable={{
                  mode: 'multiple',
                }}
                pageable={true}
                pageSize={20}
              >
                <Column field='name' title='Servicio' columnMenu={ColumnMenu} />
                <Column title='Actions' cell={ColumnMenuAction} />
              </Grid>
            )}
          </Col>
        </Row>

        <Alert isOpen={this.state.showAlert} toggle={this.onDismiss} className='alert-success'>
          <p className='m-0'>
            <span className={'k-icon k-i-check-circle'}></span> Servicios sincronizados
          </p>
        </Alert>

        {this.renderErrorHandler()}
      </>
    );
  }
}

const ColumnMenuAction = (props: any) => {
  const cleanProps = cleanGridColumnMenuIncomingPropsGridUtils(props);
  return (
    <td className={props.className} {...cleanProps} style={Object.assign(props.style)}>
      <a href={`/service/${props.dataItem.id}`}>
        <GridButton icon='pencil' fillMode='solid' className='mr-2 btn-seidor bg-seidor-light-blue color-seidor-white' />
      </a>
    </td>
  );
};
