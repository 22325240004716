import moment from "moment";

export default class TurnsMapper {
  setUpDate = (fechaIni: any, fechaEnd: any) => {
    const convertFechaIni = moment(fechaIni);
    const dateFormatIniMoment = moment.parseZone(convertFechaIni).utc().format();
    const formatDateIni = moment(dateFormatIniMoment).format('DD/MM/YYYY, HH:mm');

    const convertFechaFin = moment(fechaEnd);
    const dateFormatFinMoment = moment.parseZone(convertFechaFin).utc().format();
    const formatDateFin = moment(dateFormatFinMoment).format('DD/MM/YYYY, HH:mm');

    const timeStart = formatDateIni.split(',')[1];
    const timeEnd = formatDateFin.split(',')[1];

    return `${timeStart.trim()} - ${timeEnd.trim()}`;
  };

  mappingTurns = (data: any) => {
    // const createTurn = new createTurns();
    // const createTurn: any[] = [];

    // TODO HERE HABLAR CON ELOY PARA SABER QUE CAMPOS MAPEAR i si es servicesTurn en vez de "affectedTurns"
    /**
     * name: servicesTurn.name (ROOT)
     * technicianId: turn.technician.id,
     * startDateTime: turn.startDateTime de cada uno en affected Turns
     * endDateTime: turn.endDateTime de cada uno en affected Turns
     * serviceId: affectedTurn.serviceId, por cada uno
     */

    const arrCreateTurn: any[] = [];
    const arrUpdateTurn: any[] = [];
    // const rootObjData = data;
    // data.affectedTurns.forEach((turn: any) => {
    //   arrToSendToBack.push({
    //     name: turn.servicesTurn ? turn.servicesTurn.name : '',
    //     technicianId: turn.technician.id,
    //     startDateTime: Date.parse(turn.startDateTime) / 1000,
    //     endDateTime: Date.parse(turn.endDateTime) / 1000,
    //     serviceId: turn.serviceId,
    //     theme: '',
    //     notes: '',
    //   });
    // });

    // data.forEach((turn: any) => {
    //   arrToSendToBack!.push({
    //     name: turn.servicesTurn ? turn.servicesTurn.name : '',
    //     technicianId: turn.technician.id,
    //     startDateTime: Date.parse(turn.startDateTime) / 1000,
    //     endDateTime: Date.parse(turn.endDateTime) / 1000,
    //     serviceId: turn.serviceId,
    //     theme: '',
    //     notes: '',
    //   })
    // })

    // return arrToSendToBack;

    data.forEach((turn: any) => {
      if(!turn.affectedTurns || turn.affectedTurns.technicianId === null){
        arrCreateTurn!.push({
          "name": this.setUpDate(turn.startDateTime, turn.endDateTime),
          "technicianId": turn.technicianId,
          "startDateTime": moment.parseZone(turn.startDateTime).valueOf(),
          "endDateTime": moment.parseZone(turn.endDateTime).valueOf(),
          "serviceId": turn.serviceId,
        })
      } else {
        arrCreateTurn!.push({
          "name": this.setUpDate(turn.startDateTime, turn.endDateTime),
          "technicianId": turn.technicianId,
          "startDateTime": moment.parseZone(turn.startDateTime).valueOf(),
          "endDateTime": moment.parseZone(turn.endDateTime).valueOf(),
          "serviceId": turn.serviceId,
        })

        turn.affectedTurns.forEach((affectedTurn: any) => {
          arrUpdateTurn!.push({
            "name": this.setUpDate(affectedTurn.startDateTime, affectedTurn.endDateTime),
            "technicianId": affectedTurn.technicianId,
            "startDateTime": moment.parseZone(affectedTurn.startDateTime).valueOf(),
            "endDateTime": moment.parseZone(affectedTurn.endDateTime).valueOf(),
            "serviceId": affectedTurn.serviceId,
            "turnId": affectedTurn.id
          })
        })
      }
    })

    return {arrCreateTurn, arrUpdateTurn};

    // if (!data.affectedTurns) {
    //   data.forEach((turn: any) => {
    //     arrToSendToBack!.push({
    //       "name": turn.servicesTurn.name,
    //       "technicianId": turn.technicianId,
    //       "startDateTime": moment(data.startDateTime).valueOf(),
    //       "endDateTime": moment(data.endDateTime).valueOf(),
    //       "theme": "pink",
    //       "notes": "note of turn",
    //       "serviceId": turn.serviceId,
    //     });
    //   });
    //   return arrToSendToBack;
    // } else {
    //   data.forEach((turn: any) => {
    //     arrToSendToBack!.push({
    //       name: turn.servicesTurn.name,
    //       technicianId: turn.technicianId,
    //       startDateTime: Date.parse(data.startDateTime) / 1000,
    //       endDateTime: Date.parse(data.endDateTime) / 1000,
    //       serviceId: turn.serviceId,
    //     });
    //   });

    //   if (data.affectedTurns.technicianId !== null){
    //     data.affectedTurns.forEach((turn: any) => {
    //       arrToSendToBack!.push({
    //         name: turn.servicesTurn.name,
    //         technicianId: turn.technicianId,
    //         startDateTime: Date.parse(data.startDateTime) / 1000,
    //         endDateTime: Date.parse(data.endDateTime) / 1000,
    //         serviceId: turn.serviceId,
    //       });
    //     });
    //   }
      
    //   return arrToSendToBack;
    // }
  }
}
