import { MainWrapperWithUseOutletContext } from '@ComponentsRoot/core/TurnosPropsInjector';
import { DashboardView } from '@ComponentsRoot/Dashboard/view/DashboardView';
// import MainLayoutView from '@ComponentsRoot/MainLayout/view/MainLayoutView';
import MainLayoutViewWithTheme from '@ComponentsRoot/MainLayout/view/MainLayoutViewWithTheme';
import { AddServiceView } from '@ComponentsRoot/Services/view/AddServiceView';
import { EditServiceView } from '@ComponentsRoot/Services/view/EditServiceView';
import ServicesGridView from '@ComponentsRoot/Services/view/ServicesGridView';
import TechnicianGridView from '@ComponentsRoot/Technician/views/TechnicianGridView';
import { AddTechnicianView } from '@ComponentsRoot/Technician/views/AddTechnicianView';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import SkillsGridView from '@ComponentsRoot/Skills/views/SkillsGridView';
import { EditSkillView } from '@ComponentsRoot/Skills/views/EditSkillView';
import { EditTechnicianView } from '@ComponentsRoot/Technician/views/EditTechnicianView';
import BackupsGridView from '@ComponentsRoot/Backups/views/BackupsGridView';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { MsalAuthInjector } from '@ComponentsRoot/MsalAuth/MsalAuthInjector';
// import EditTurnView from '@ComponentsRoot/Turns/views/EditTurnView';
// import MainLayoutView from '@ComponentsRoot/MainLayout/view/MainLayoutView';

function App(props:{pca: PublicClientApplication, fromLogin: boolean}) {
  return (
    <MsalProvider instance={props.pca}>
      <MsalAuthInjector>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<MainLayoutViewWithTheme />}>
              {/* <Route index element={<Home />} /> */}

              <Route
                index={true as any}
                path='dashboard'
                element={
                  <MainWrapperWithUseOutletContext>
                    <DashboardView />
                  </MainWrapperWithUseOutletContext>
                }
              />

              <Route
                path='technician'
                element={
                  <MainWrapperWithUseOutletContext>
                    <TechnicianGridView />
                  </MainWrapperWithUseOutletContext>
                }
              />
              <Route
                path='addTechnician'
                element={
                  <MainWrapperWithUseOutletContext>
                    <AddTechnicianView />
                  </MainWrapperWithUseOutletContext>
                }
              />
              <Route
                path='technician/:tecnicoId'
                element={
                  <MainWrapperWithUseOutletContext>
                    <EditTechnicianView />
                  </MainWrapperWithUseOutletContext>
                }
              />

              <Route
                path='skills'
                element={
                  <MainWrapperWithUseOutletContext>
                    <SkillsGridView />
                  </MainWrapperWithUseOutletContext>
                }
              />
              <Route
                path='skill/:skillId'
                element={
                  <MainWrapperWithUseOutletContext>
                    <EditSkillView />
                  </MainWrapperWithUseOutletContext>
                }
              />

              <Route
                path='services'
                element={
                  <MainWrapperWithUseOutletContext>
                    <ServicesGridView />
                  </MainWrapperWithUseOutletContext>
                }
              />
              <Route
                path='addService'
                element={
                  <MainWrapperWithUseOutletContext>
                    <AddServiceView />
                  </MainWrapperWithUseOutletContext>
                }
              />
              <Route
                path='service/:serviceId'
                element={
                  <MainWrapperWithUseOutletContext>
                    <EditServiceView />
                  </MainWrapperWithUseOutletContext>
                }
              />
              <Route
                path='backups'
                element={
                  <MainWrapperWithUseOutletContext>
                    <BackupsGridView />
                  </MainWrapperWithUseOutletContext>
                }
              />
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
            </Route>
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Routes>
        </BrowserRouter>
      </MsalAuthInjector>
    </MsalProvider>
  );
}

export default App;
