import NodeApiDS from '@DataSource/NodeApiDS';

export default class SkillsRepository {
  nodeApiDS: NodeApiDS;
  constructor(nodeApiDS: NodeApiDS) {
    this.nodeApiDS = nodeApiDS;
  }

  getAllSkills = async () => {
    try {
      const res = await this.nodeApiDS.callApi('category', 'get');
      console.log(res.data);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  addSkills = async (data: any) => {
    try {
      const res = await this.nodeApiDS.callApi('category', 'post', data);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  getDetailSkill = async (skillId: string) => {
    try {
      const res = await this.nodeApiDS.callApi(`category/${skillId}`, 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  getSubSkills = async (skillId: string) => {
    try {
      const res = await this.nodeApiDS.callApi(`category/${skillId}/skills`, 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  deleteSkill = async (skillId: string) => {
    try {
      const res = await this.nodeApiDS.callApi(`category/${skillId}`, 'del');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  addSubSkills = async (data: any) => {
    try {
      const res = await this.nodeApiDS.callApi('skill', 'post', data);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  deleteSubSkill = async (skillId: string) => {
    try {
      const res = await this.nodeApiDS.callApi(`skill/${skillId}`, 'del');
      console.log(res.data);
      return res.data;
    } catch (error) {
      throw error;
    }
  };
}
