import React from "react";
import { MsalAuthenticationResult } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { processAzureMsalError } from "./authConfig";

export const ErrorComponent: React.FC<MsalAuthenticationResult & { instance: IPublicClientApplication }> = ({
  error,
  instance,
}) => {
  processAzureMsalError(instance, error);
  return <></>;
};
