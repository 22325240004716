import { InjectedPropsType } from '@ComponentsRoot/core/types/InjectedPropsType';
import { useEffect } from 'react';
import { 
  Button,
  Col,
  Input,
  Row,
  FormGroup,
  Label
 } from 'reactstrap';
import Accordion from 'react-bootstrap/Accordion';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import FormErrorView from '@ComponentsRoot/FormError/view/FormErrorView';
import '../styles/ServicesGridViewStyle.scss';
// import { Controller, OnSubmit, SubmitHandler, useForm } from 'react-hook-form';
// import AddServiceController from "../controller/AddServiceController";

type AddServiceFormValuesTypes = {
  servicio: string;
  ciudad: string;
  recursos: number;
};

//https://react-hook-form.com/api/usecontroller/controller (V7 IMPORTANT!)
//https://react-hook-form.com/api/useform

export const AddServiceView = (props: InjectedPropsType) => {
  // const controller = new AddServiceController(props);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AddServiceFormValuesTypes>();

  const onSubmit: SubmitHandler<AddServiceFormValuesTypes> = (data) => {
    console.log(data);
  };

  useEffect(() => {
    (async () => {
      // await props.bloc.getInventory();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderMainForm = () => {
    return (
      <>
        <Row>
          <Col lg='12'>
            <FormGroup>
                <Label for="servicio">
                  Servicio
                </Label>
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Input
                    ref={ref}
                    type={'text'}
                    className={`${errors.servicio ? 'error form-control' : 'form-control'}`}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={10}
                  />
                )}
                name={'servicio'}
                rules={{
                  minLength: 2,
                  required: true,               
                  maxLength: 10,
                }}
                control={control}
              />

              {errors.servicio && <FormErrorView error={errors.servicio} />}
            </FormGroup>
          </Col>
          <Col xs='12' md='6'>
            <FormGroup>
              <Label for="exampleDate">
                Fecha de inicio
              </Label>
              <Input
                id="exampleDate"
                name="date"
                placeholder="date placeholder"
                type="date"
              />
            </FormGroup>
          </Col>
          <Col xs='12' md='6'>
            <FormGroup>
              <Label for="exampleDate">
                Fecha de finalización
              </Label>
              <Input
                id="exampleDate"
                name="date"
                placeholder="date placeholder"
                type="date"
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Lunes</Accordion.Header>
                <Accordion.Body>
                  <Col xs='12'>
                    <FormGroup check inline>
                      <Input type="time" name='timeIniLunes' value={''} className='form-check-input'  /> <Input type="time" name='timeFinLunes' value={''} className='form-check-input'  />
                    </FormGroup>

                    <Label className='mr-3'>Presencial:</Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' value={'si'} />
                    </FormGroup>
                    
                    <p className='mt-3'>Habilidades</p>
                    <Label className='mr-3'>
                      Técnicas:
                    </Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="PHP" value={'PHP'} />
                      <Label check>
                        PHP
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="Java" value={'Java'} />
                      <Label check>
                        Java
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="React" value={'React'} />
                      <Label check>
                        React
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col xs='12' className='mb-3'>
                    <Label className='mr-3'>
                      Idiomas:
                    </Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="PHP" value={'Inglés'} />
                      <Label check>
                        Inglés
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="Java" value={'Catalan'} />
                      <Label check>
                        Catalan
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="React" value={'Castellano'} />
                      <Label check>
                        Castellano
                      </Label>
                    </FormGroup>
                  </Col>

                  <Col xs='12' md='4'>
                    <FormGroup>
                        <Label for="servicio">
                          Ciudad
                        </Label>
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Input
                            ref={ref}
                            type={'text'}
                            className={`${errors.ciudad ? 'error form-control' : 'form-control'}`}
                            onChange={onChange}
                            onBlur={onBlur}
                            maxLength={10}
                          />
                        )}
                        name={'ciudad'}
                        rules={{
                          minLength: 2,
                          required: true,               
                          maxLength: 10,
                        }}
                        control={control}
                      />

                      {errors.ciudad && <FormErrorView error={errors.ciudad} />}
                    </FormGroup>
                  </Col>
                  <Col xs='12' md='4'>
                    <FormGroup>
                      <Label for="phone">
                        Cantidad de recursos
                      </Label>
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Input
                            ref={ref}
                            id="recursos"
                            type={'number'}
                            className={`${errors.recursos ? 'error form-control' : 'form-control'}`}
                            onChange={onChange}
                            onBlur={onBlur}
                            maxLength={9}
                            // placeholder={'Enter age'}
                          />
                        )}              
                        name={'recursos'}              
                        rules={{
                          minLength: 2,
                          required: true,               
                          maxLength: 9,
                        }}
                        control={control}
                      />
                    </FormGroup>
                  </Col>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Martes</Accordion.Header>
                <Accordion.Body>
                  <Col xs='12'>
                    <FormGroup check inline>
                      <Input type="time" name='timeIniLunes' value={''} className='form-check-input'  /> <Input type="time" name='timeFinLunes' value={''} className='form-check-input'  />
                    </FormGroup>

                    <Label className='mr-3'>Presencial:</Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' value={'si'} />
                    </FormGroup>
                    
                    <p className='mt-3'>Habilidades</p>
                    <Label className='mr-3'>
                      Técnicas:
                    </Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="PHP" value={'PHP'} />
                      <Label check>
                        PHP
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="Java" value={'Java'} />
                      <Label check>
                        Java
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="React" value={'React'} />
                      <Label check>
                        React
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col xs='12' className='mb-3'>
                    <Label className='mr-3'>
                      Idiomas:
                    </Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="PHP" value={'Inglés'} />
                      <Label check>
                        Inglés
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="Java" value={'Catalan'} />
                      <Label check>
                        Catalan
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="React" value={'Castellano'} />
                      <Label check>
                        Castellano
                      </Label>
                    </FormGroup>
                  </Col>

                  <Col xs='12' md='4'>
                    <FormGroup>
                        <Label for="servicio">
                          Ciudad
                        </Label>
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Input
                            ref={ref}
                            type={'text'}
                            className={`${errors.ciudad ? 'error form-control' : 'form-control'}`}
                            onChange={onChange}
                            onBlur={onBlur}
                            maxLength={10}
                          />
                        )}
                        name={'ciudad'}
                        rules={{
                          minLength: 2,
                          required: true,               
                          maxLength: 10,
                        }}
                        control={control}
                      />

                      {errors.ciudad && <FormErrorView error={errors.ciudad} />}
                    </FormGroup>
                  </Col>
                  <Col xs='12' md='4'>
                    <FormGroup>
                      <Label for="phone">
                        Cantidad de recursos
                      </Label>
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Input
                            ref={ref}
                            id="recursos"
                            type={'number'}
                            className={`${errors.recursos ? 'error form-control' : 'form-control'}`}
                            onChange={onChange}
                            onBlur={onBlur}
                            maxLength={9}
                            // placeholder={'Enter age'}
                          />
                        )}              
                        name={'recursos'}              
                        rules={{
                          minLength: 2,
                          required: true,               
                          maxLength: 9,
                        }}
                        control={control}
                      />
                    </FormGroup>
                  </Col>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Miercoles</Accordion.Header>
                <Accordion.Body>
                  <Col xs='12'>
                    <FormGroup check inline>
                      <Input type="time" name='timeIniLunes' value={''} className='form-check-input'  /> <Input type="time" name='timeFinLunes' value={''} className='form-check-input'  />
                    </FormGroup>

                    <Label className='mr-3'>Presencial:</Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' value={'si'} />
                    </FormGroup>
                    
                    <p className='mt-3'>Habilidades</p>
                    <Label className='mr-3'>
                      Técnicas:
                    </Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="PHP" value={'PHP'} />
                      <Label check>
                        PHP
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="Java" value={'Java'} />
                      <Label check>
                        Java
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="React" value={'React'} />
                      <Label check>
                        React
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col xs='12' className='mb-3'>
                    <Label className='mr-3'>
                      Idiomas:
                    </Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="PHP" value={'Inglés'} />
                      <Label check>
                        Inglés
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="Java" value={'Catalan'} />
                      <Label check>
                        Catalan
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="React" value={'Castellano'} />
                      <Label check>
                        Castellano
                      </Label>
                    </FormGroup>
                  </Col>

                  <Col xs='12' md='4'>
                    <FormGroup>
                        <Label for="servicio">
                          Ciudad
                        </Label>
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Input
                            ref={ref}
                            type={'text'}
                            className={`${errors.ciudad ? 'error form-control' : 'form-control'}`}
                            onChange={onChange}
                            onBlur={onBlur}
                            maxLength={10}
                          />
                        )}
                        name={'ciudad'}
                        rules={{
                          minLength: 2,
                          required: true,               
                          maxLength: 10,
                        }}
                        control={control}
                      />

                      {errors.ciudad && <FormErrorView error={errors.ciudad} />}
                    </FormGroup>
                  </Col>
                  <Col xs='12' md='4'>
                    <FormGroup>
                      <Label for="phone">
                        Cantidad de recursos
                      </Label>
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Input
                            ref={ref}
                            id="recursos"
                            type={'number'}
                            className={`${errors.recursos ? 'error form-control' : 'form-control'}`}
                            onChange={onChange}
                            onBlur={onBlur}
                            maxLength={9}
                            // placeholder={'Enter age'}
                          />
                        )}              
                        name={'recursos'}              
                        rules={{
                          minLength: 2,
                          required: true,               
                          maxLength: 9,
                        }}
                        control={control}
                      />
                    </FormGroup>
                  </Col>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Jueves</Accordion.Header>
                <Accordion.Body>
                  <Col xs='12'>
                    <FormGroup check inline>
                      <Input type="time" name='timeIniLunes' value={''} className='form-check-input'  /> <Input type="time" name='timeFinLunes' value={''} className='form-check-input'  />
                    </FormGroup>

                    <Label className='mr-3'>Presencial:</Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' value={'si'} />
                    </FormGroup>
                    
                    <p className='mt-3'>Habilidades</p>
                    <Label className='mr-3'>
                      Técnicas:
                    </Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="PHP" value={'PHP'} />
                      <Label check>
                        PHP
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="Java" value={'Java'} />
                      <Label check>
                        Java
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="React" value={'React'} />
                      <Label check>
                        React
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col xs='12' className='mb-3'>
                    <Label className='mr-3'>
                      Idiomas:
                    </Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="PHP" value={'Inglés'} />
                      <Label check>
                        Inglés
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="Java" value={'Catalan'} />
                      <Label check>
                        Catalan
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="React" value={'Castellano'} />
                      <Label check>
                        Castellano
                      </Label>
                    </FormGroup>
                  </Col>

                  <Col xs='12' md='4'>
                    <FormGroup>
                        <Label for="servicio">
                          Ciudad
                        </Label>
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Input
                            ref={ref}
                            type={'text'}
                            className={`${errors.ciudad ? 'error form-control' : 'form-control'}`}
                            onChange={onChange}
                            onBlur={onBlur}
                            maxLength={10}
                          />
                        )}
                        name={'ciudad'}
                        rules={{
                          minLength: 2,
                          required: true,               
                          maxLength: 10,
                        }}
                        control={control}
                      />

                      {errors.ciudad && <FormErrorView error={errors.ciudad} />}
                    </FormGroup>
                  </Col>
                  <Col xs='12' md='4'>
                    <FormGroup>
                      <Label for="phone">
                        Cantidad de recursos
                      </Label>
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Input
                            ref={ref}
                            id="recursos"
                            type={'number'}
                            className={`${errors.recursos ? 'error form-control' : 'form-control'}`}
                            onChange={onChange}
                            onBlur={onBlur}
                            maxLength={9}
                            // placeholder={'Enter age'}
                          />
                        )}              
                        name={'recursos'}              
                        rules={{
                          minLength: 2,
                          required: true,               
                          maxLength: 9,
                        }}
                        control={control}
                      />
                    </FormGroup>
                  </Col>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Viernes</Accordion.Header>
                <Accordion.Body>
                  <Col xs='12'>
                    <FormGroup check inline>
                      <Input type="time" name='timeIniLunes' value={''} className='form-check-input'  /> <Input type="time" name='timeFinLunes' value={''} className='form-check-input'  />
                    </FormGroup>

                    <Label className='mr-3'>Presencial:</Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' value={'si'} />
                    </FormGroup>
                    
                    <p className='mt-3'>Habilidades</p>
                    <Label className='mr-3'>
                      Técnicas:
                    </Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="PHP" value={'PHP'} />
                      <Label check>
                        PHP
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="Java" value={'Java'} />
                      <Label check>
                        Java
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="React" value={'React'} />
                      <Label check>
                        React
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col xs='12' className='mb-3'>
                    <Label className='mr-3'>
                      Idiomas:
                    </Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="PHP" value={'Inglés'} />
                      <Label check>
                        Inglés
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="Java" value={'Catalan'} />
                      <Label check>
                        Catalan
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="React" value={'Castellano'} />
                      <Label check>
                        Castellano
                      </Label>
                    </FormGroup>
                  </Col>

                  <Col xs='12' md='4'>
                    <FormGroup>
                        <Label for="servicio">
                          Ciudad
                        </Label>
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Input
                            ref={ref}
                            type={'text'}
                            className={`${errors.ciudad ? 'error form-control' : 'form-control'}`}
                            onChange={onChange}
                            onBlur={onBlur}
                            maxLength={10}
                          />
                        )}
                        name={'ciudad'}
                        rules={{
                          minLength: 2,
                          required: true,               
                          maxLength: 10,
                        }}
                        control={control}
                      />

                      {errors.ciudad && <FormErrorView error={errors.ciudad} />}
                    </FormGroup>
                  </Col>
                  <Col xs='12' md='4'>
                    <FormGroup>
                      <Label for="phone">
                        Cantidad de recursos
                      </Label>
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Input
                            ref={ref}
                            id="recursos"
                            type={'number'}
                            className={`${errors.recursos ? 'error form-control' : 'form-control'}`}
                            onChange={onChange}
                            onBlur={onBlur}
                            maxLength={9}
                            // placeholder={'Enter age'}
                          />
                        )}              
                        name={'recursos'}              
                        rules={{
                          minLength: 2,
                          required: true,               
                          maxLength: 9,
                        }}
                        control={control}
                      />
                    </FormGroup>
                  </Col>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>Sábado</Accordion.Header>
                <Accordion.Body>
                  <Col xs='12'>
                    <FormGroup check inline>
                      <Input type="time" name='timeIniLunes' value={''} className='form-check-input'  /> <Input type="time" name='timeFinLunes' value={''} className='form-check-input'  />
                    </FormGroup>

                    <Label className='mr-3'>Presencial:</Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' value={'si'} />
                    </FormGroup>
                    
                    <p className='mt-3'>Habilidades</p>
                    <Label className='mr-3'>
                      Técnicas:
                    </Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="PHP" value={'PHP'} />
                      <Label check>
                        PHP
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="Java" value={'Java'} />
                      <Label check>
                        Java
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="React" value={'React'} />
                      <Label check>
                        React
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col xs='12' className='mb-3'>
                    <Label className='mr-3'>
                      Idiomas:
                    </Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="PHP" value={'Inglés'} />
                      <Label check>
                        Inglés
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="Java" value={'Catalan'} />
                      <Label check>
                        Catalan
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="React" value={'Castellano'} />
                      <Label check>
                        Castellano
                      </Label>
                    </FormGroup>
                  </Col>

                  <Col xs='12' md='4'>
                    <FormGroup>
                        <Label for="servicio">
                          Ciudad
                        </Label>
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Input
                            ref={ref}
                            type={'text'}
                            className={`${errors.ciudad ? 'error form-control' : 'form-control'}`}
                            onChange={onChange}
                            onBlur={onBlur}
                            maxLength={10}
                          />
                        )}
                        name={'ciudad'}
                        rules={{
                          minLength: 2,
                          required: true,               
                          maxLength: 10,
                        }}
                        control={control}
                      />

                      {errors.ciudad && <FormErrorView error={errors.ciudad} />}
                    </FormGroup>
                  </Col>
                  <Col xs='12' md='4'>
                    <FormGroup>
                      <Label for="phone">
                        Cantidad de recursos
                      </Label>
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Input
                            ref={ref}
                            id="recursos"
                            type={'number'}
                            className={`${errors.recursos ? 'error form-control' : 'form-control'}`}
                            onChange={onChange}
                            onBlur={onBlur}
                            maxLength={9}
                            // placeholder={'Enter age'}
                          />
                        )}              
                        name={'recursos'}              
                        rules={{
                          minLength: 2,
                          required: true,               
                          maxLength: 9,
                        }}
                        control={control}
                      />
                    </FormGroup>
                  </Col>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>Domingo</Accordion.Header>
                <Accordion.Body>
                  <Col xs='12'>
                    <FormGroup check inline>
                      <Input type="time" name='timeIniLunes' value={''} className='form-check-input'  /> <Input type="time" name='timeFinLunes' value={''} className='form-check-input'  />
                    </FormGroup>

                    <Label className='mr-3'>Presencial:</Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' value={'si'} />
                    </FormGroup>
                    
                    <p className='mt-3'>Habilidades</p>
                    <Label className='mr-3'>
                      Técnicas:
                    </Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="PHP" value={'PHP'} />
                      <Label check>
                        PHP
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="Java" value={'Java'} />
                      <Label check>
                        Java
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="React" value={'React'} />
                      <Label check>
                        React
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col xs='12' className='mb-3'>
                    <Label className='mr-3'>
                      Idiomas:
                    </Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="PHP" value={'Inglés'} />
                      <Label check>
                        Inglés
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="Java" value={'Catalan'} />
                      <Label check>
                        Catalan
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="React" value={'Castellano'} />
                      <Label check>
                        Castellano
                      </Label>
                    </FormGroup>
                  </Col>

                  <Col xs='12' md='4'>
                    <FormGroup>
                        <Label for="servicio">
                          Ciudad
                        </Label>
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Input
                            ref={ref}
                            type={'text'}
                            className={`${errors.ciudad ? 'error form-control' : 'form-control'}`}
                            onChange={onChange}
                            onBlur={onBlur}
                            maxLength={10}
                          />
                        )}
                        name={'ciudad'}
                        rules={{
                          minLength: 2,
                          required: true,               
                          maxLength: 10,
                        }}
                        control={control}
                      />

                      {errors.ciudad && <FormErrorView error={errors.ciudad} />}
                    </FormGroup>
                  </Col>
                  <Col xs='12' md='4'>
                    <FormGroup>
                      <Label for="phone">
                        Cantidad de recursos
                      </Label>
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Input
                            ref={ref}
                            id="recursos"
                            type={'number'}
                            className={`${errors.recursos ? 'error form-control' : 'form-control'}`}
                            onChange={onChange}
                            onBlur={onBlur}
                            maxLength={9}
                            // placeholder={'Enter age'}
                          />
                        )}              
                        name={'recursos'}              
                        rules={{
                          minLength: 2,
                          required: true,               
                          maxLength: 9,
                        }}
                        control={control}
                      />
                    </FormGroup>
                  </Col>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>

        {/* <Row>
          <Col xs='12'>
            <Card>
              <CardHeader>
                <Button color="link" onClick={toggle} >
                  Lunes
                </Button>
              </CardHeader>
            </Card>
            <Collapse isOpen={isOpen}>
              <Card>
                <CardBody>
                  <Col xs='12'>
                    <FormGroup check inline>
                      <Input type="time" name='timeIniLunes' value={''} className='form-check-input'  /> <Input type="time" name='timeFinLunes' value={''} className='form-check-input'  />
                    </FormGroup>

                    <Label className='mr-3'>Presencial:</Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' value={'si'} />
                    </FormGroup>
                    
                    <p className='mt-3'>Habilidades</p>
                    <Label className='mr-3'>
                      Técnicas:
                    </Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="PHP" value={'PHP'} />
                      <Label check>
                        PHP
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="Java" value={'Java'} />
                      <Label check>
                        Java
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="React" value={'React'} />
                      <Label check>
                        React
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col xs='12' className='mb-3'>
                    <Label className='mr-3'>
                      Idiomas:
                    </Label>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="PHP" value={'Inglés'} />
                      <Label check>
                        Inglés
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="Java" value={'Catalan'} />
                      <Label check>
                        Catalan
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Input type="checkbox" className='form-check-input' id="React" value={'Castellano'} />
                      <Label check>
                        Castellano
                      </Label>
                    </FormGroup>
                  </Col>

                  <Col xs='12' md='4'>
                    <FormGroup>
                        <Label for="servicio">
                          Ciudad
                        </Label>
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Input
                            ref={ref}
                            type={'text'}
                            className={`${errors.ciudad ? 'error form-control' : 'form-control'}`}
                            onChange={onChange}
                            onBlur={onBlur}
                            maxLength={10}
                          />
                        )}
                        name={'ciudad'}
                        rules={{
                          minLength: 2,
                          required: true,               
                          maxLength: 10,
                        }}
                        control={control}
                      />

                      {errors.ciudad && <FormErrorView error={errors.ciudad} />}
                    </FormGroup>
                  </Col>
                  <Col xs='12' md='4'>
                    <FormGroup>
                      <Label for="phone">
                        Cantidad de recursos
                      </Label>
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Input
                            ref={ref}
                            id="recursos"
                            type={'number'}
                            className={`${errors.recursos ? 'error form-control' : 'form-control'}`}
                            onChange={onChange}
                            onBlur={onBlur}
                            maxLength={9}
                            // placeholder={'Enter age'}
                          />
                        )}              
                        name={'recursos'}              
                        rules={{
                          minLength: 2,
                          required: true,               
                          maxLength: 9,
                        }}
                        control={control}
                      />
                    </FormGroup>
                  </Col>
                </CardBody>
              </Card>
            </Collapse>
          </Col>

        </Row> */}
        {/* <hr></hr>
        <Row>
          <Col xs='12'>
            <p>Martes</p>
            <FormGroup check inline>
              <Input type="time" name='timeIniLunes' value={''} className='form-check-input'  /> <Input type="time" name='timeFinLunes' value={''} className='form-check-input'  />
            </FormGroup>

            <Label className='mr-3'>Presencial:</Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' value={'si'} />
            </FormGroup>
            
            <p className='mt-3'>Habilidades</p>
            <Label className='mr-3'>
              Técnicas:
            </Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="PHP" value={'PHP'} />
              <Label check>
                PHP
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="Java" value={'Java'} />
              <Label check>
                Java
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="React" value={'React'} />
              <Label check>
                React
              </Label>
            </FormGroup>
          </Col>
          <Col xs='12' className='mb-3'>
            <Label className='mr-3'>
              Idiomas:
            </Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="PHP" value={'Inglés'} />
              <Label check>
                Inglés
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="Java" value={'Catalan'} />
              <Label check>
                Catalan
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="React" value={'Castellano'} />
              <Label check>
                Castellano
              </Label>
            </FormGroup>
          </Col>

          <Col xs='12' md='6'>
            <FormGroup>
                <Label for="servicio">
                  Ciudad
                </Label>
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Input
                    ref={ref}
                    type={'text'}
                    className={`${errors.ciudad ? 'error form-control' : 'form-control'}`}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={10}
                  />
                )}
                name={'ciudad'}
                rules={{
                  minLength: 2,
                  required: true,               
                  maxLength: 10,
                }}
                control={control}
              />

              {errors.ciudad && <FormErrorView error={errors.ciudad} />}
            </FormGroup>
          </Col>
          <Col xs='12' md='6'>
            <FormGroup>
              <Label for="phone">
                Cantidad de recursos
              </Label>
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Input
                    ref={ref}
                    id="recursos"
                    type={'number'}
                    className={`${errors.recursos ? 'error form-control' : 'form-control'}`}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={9}
                    // placeholder={'Enter age'}
                  />
                )}              
                name={'recursos'}              
                rules={{
                  minLength: 2,
                  required: true,               
                  maxLength: 9,
                }}
                control={control}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col xs='12'>
            <p>Miércoles</p>
            <FormGroup check inline>
              <Input type="time" name='timeIniLunes' value={''} className='form-check-input'  /> <Input type="time" name='timeFinLunes' value={''} className='form-check-input'  />
            </FormGroup>

            <Label className='mr-3'>Presencial:</Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' value={'si'} />
            </FormGroup>
            
            <p className='mt-3'>Habilidades</p>
            <Label className='mr-3'>
              Técnicas:
            </Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="PHP" value={'PHP'} />
              <Label check>
                PHP
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="Java" value={'Java'} />
              <Label check>
                Java
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="React" value={'React'} />
              <Label check>
                React
              </Label>
            </FormGroup>
          </Col>
          <Col xs='12' className='mb-3'>
            <Label className='mr-3'>
              Idiomas:
            </Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="PHP" value={'Inglés'} />
              <Label check>
                Inglés
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="Java" value={'Catalan'} />
              <Label check>
                Catalan
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="React" value={'Castellano'} />
              <Label check>
                Castellano
              </Label>
            </FormGroup>
          </Col>

          <Col xs='12' md='6'>
            <FormGroup>
                <Label for="servicio">
                  Ciudad
                </Label>
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Input
                    ref={ref}
                    type={'text'}
                    className={`${errors.ciudad ? 'error form-control' : 'form-control'}`}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={10}
                  />
                )}
                name={'ciudad'}
                rules={{
                  minLength: 2,
                  required: true,               
                  maxLength: 10,
                }}
                control={control}
              />

              {errors.ciudad && <FormErrorView error={errors.ciudad} />}
            </FormGroup>
          </Col>
          <Col xs='12' md='6'>
            <FormGroup>
              <Label for="phone">
                Cantidad de recursos
              </Label>
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Input
                    ref={ref}
                    id="recursos"
                    type={'number'}
                    className={`${errors.recursos ? 'error form-control' : 'form-control'}`}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={9}
                    // placeholder={'Enter age'}
                  />
                )}              
                name={'recursos'}              
                rules={{
                  minLength: 2,
                  required: true,               
                  maxLength: 9,
                }}
                control={control}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col xs='12'>
            <p>Jueves</p>
            <FormGroup check inline>
              <Input type="time" name='timeIniLunes' value={''} className='form-check-input'  /> <Input type="time" name='timeFinLunes' value={''} className='form-check-input'  />
            </FormGroup>

            <Label className='mr-3'>Presencial:</Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' value={'si'} />
            </FormGroup>
            
            <p className='mt-3'>Habilidades</p>
            <Label className='mr-3'>
              Técnicas:
            </Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="PHP" value={'PHP'} />
              <Label check>
                PHP
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="Java" value={'Java'} />
              <Label check>
                Java
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="React" value={'React'} />
              <Label check>
                React
              </Label>
            </FormGroup>
          </Col>
          <Col xs='12' className='mb-3'>
            <Label className='mr-3'>
              Idiomas:
            </Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="PHP" value={'Inglés'} />
              <Label check>
                Inglés
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="Java" value={'Catalan'} />
              <Label check>
                Catalan
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="React" value={'Castellano'} />
              <Label check>
                Castellano
              </Label>
            </FormGroup>
          </Col>

          <Col xs='12' md='6'>
            <FormGroup>
                <Label for="servicio">
                  Ciudad
                </Label>
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Input
                    ref={ref}
                    type={'text'}
                    className={`${errors.ciudad ? 'error form-control' : 'form-control'}`}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={10}
                  />
                )}
                name={'ciudad'}
                rules={{
                  minLength: 2,
                  required: true,               
                  maxLength: 10,
                }}
                control={control}
              />

              {errors.ciudad && <FormErrorView error={errors.ciudad} />}
            </FormGroup>
          </Col>
          <Col xs='12' md='6'>
            <FormGroup>
              <Label for="phone">
                Cantidad de recursos
              </Label>
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Input
                    ref={ref}
                    id="recursos"
                    type={'number'}
                    className={`${errors.recursos ? 'error form-control' : 'form-control'}`}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={9}
                    // placeholder={'Enter age'}
                  />
                )}              
                name={'recursos'}              
                rules={{
                  minLength: 2,
                  required: true,               
                  maxLength: 9,
                }}
                control={control}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col xs='12'>
            <p>Viernes</p>
            <FormGroup check inline>
              <Input type="time" name='timeIniLunes' value={''} className='form-check-input'  /> <Input type="time" name='timeFinLunes' value={''} className='form-check-input'  />
            </FormGroup>

            <Label className='mr-3'>Presencial:</Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' value={'si'} />
            </FormGroup>
            
            <p className='mt-3'>Habilidades</p>
            <Label className='mr-3'>
              Técnicas:
            </Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="PHP" value={'PHP'} />
              <Label check>
                PHP
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="Java" value={'Java'} />
              <Label check>
                Java
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="React" value={'React'} />
              <Label check>
                React
              </Label>
            </FormGroup>
          </Col>
          <Col xs='12' className='mb-3'>
            <Label className='mr-3'>
              Idiomas:
            </Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="PHP" value={'Inglés'} />
              <Label check>
                Inglés
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="Java" value={'Catalan'} />
              <Label check>
                Catalan
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="React" value={'Castellano'} />
              <Label check>
                Castellano
              </Label>
            </FormGroup>
          </Col>

          <Col xs='12' md='6'>
            <FormGroup>
                <Label for="servicio">
                  Ciudad
                </Label>
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Input
                    ref={ref}
                    type={'text'}
                    className={`${errors.ciudad ? 'error form-control' : 'form-control'}`}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={10}
                  />
                )}
                name={'ciudad'}
                rules={{
                  minLength: 2,
                  required: true,               
                  maxLength: 10,
                }}
                control={control}
              />

              {errors.ciudad && <FormErrorView error={errors.ciudad} />}
            </FormGroup>
          </Col>
          <Col xs='12' md='6'>
            <FormGroup>
              <Label for="phone">
                Cantidad de recursos
              </Label>
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Input
                    ref={ref}
                    id="recursos"
                    type={'number'}
                    className={`${errors.recursos ? 'error form-control' : 'form-control'}`}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={9}
                    // placeholder={'Enter age'}
                  />
                )}              
                name={'recursos'}              
                rules={{
                  minLength: 2,
                  required: true,               
                  maxLength: 9,
                }}
                control={control}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col xs='12'>
            <p>Sábado</p>
            <FormGroup check inline>
              <Input type="time" name='timeIniLunes' value={''} className='form-check-input'  /> <Input type="time" name='timeFinLunes' value={''} className='form-check-input'  />
            </FormGroup>

            <Label className='mr-3'>Presencial:</Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' value={'si'} />
            </FormGroup>
            
            <p className='mt-3'>Habilidades</p>
            <Label className='mr-3'>
              Técnicas:
            </Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="PHP" value={'PHP'} />
              <Label check>
                PHP
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="Java" value={'Java'} />
              <Label check>
                Java
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="React" value={'React'} />
              <Label check>
                React
              </Label>
            </FormGroup>
          </Col>
          <Col xs='12' className='mb-3'>
            <Label className='mr-3'>
              Idiomas:
            </Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="PHP" value={'Inglés'} />
              <Label check>
                Inglés
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="Java" value={'Catalan'} />
              <Label check>
                Catalan
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="React" value={'Castellano'} />
              <Label check>
                Castellano
              </Label>
            </FormGroup>
          </Col>

          <Col xs='12' md='6'>
            <FormGroup>
                <Label for="servicio">
                  Ciudad
                </Label>
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Input
                    ref={ref}
                    type={'text'}
                    className={`${errors.ciudad ? 'error form-control' : 'form-control'}`}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={10}
                  />
                )}
                name={'ciudad'}
                rules={{
                  minLength: 2,
                  required: true,               
                  maxLength: 10,
                }}
                control={control}
              />

              {errors.ciudad && <FormErrorView error={errors.ciudad} />}
            </FormGroup>
          </Col>
          <Col xs='12' md='6'>
            <FormGroup>
              <Label for="phone">
                Cantidad de recursos
              </Label>
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Input
                    ref={ref}
                    id="recursos"
                    type={'number'}
                    className={`${errors.recursos ? 'error form-control' : 'form-control'}`}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={9}
                    // placeholder={'Enter age'}
                  />
                )}              
                name={'recursos'}              
                rules={{
                  minLength: 2,
                  required: true,               
                  maxLength: 9,
                }}
                control={control}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col xs='12'>
            <p>Domingo</p>
            <FormGroup check inline>
              <Input type="time" name='timeIniLunes' value={''} className='form-check-input'  /> <Input type="time" name='timeFinLunes' value={''} className='form-check-input'  />
            </FormGroup>

            <Label className='mr-3'>Presencial:</Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' value={'si'} />
            </FormGroup>
            
            <p className='mt-3'>Habilidades</p>
            <Label className='mr-3'>
              Técnicas:
            </Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="PHP" value={'PHP'} />
              <Label check>
                PHP
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="Java" value={'Java'} />
              <Label check>
                Java
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="React" value={'React'} />
              <Label check>
                React
              </Label>
            </FormGroup>
          </Col>
          <Col xs='12' className='mb-3'>
            <Label className='mr-3'>
              Idiomas:
            </Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="PHP" value={'Inglés'} />
              <Label check>
                Inglés
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="Java" value={'Catalan'} />
              <Label check>
                Catalan
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="React" value={'Castellano'} />
              <Label check>
                Castellano
              </Label>
            </FormGroup>
          </Col>

          <Col xs='12' md='6'>
            <FormGroup>
                <Label for="servicio">
                  Ciudad
                </Label>
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Input
                    ref={ref}
                    type={'text'}
                    className={`${errors.ciudad ? 'error form-control' : 'form-control'}`}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={10}
                  />
                )}
                name={'ciudad'}
                rules={{
                  minLength: 2,
                  required: true,               
                  maxLength: 10,
                }}
                control={control}
              />

              {errors.ciudad && <FormErrorView error={errors.ciudad} />}
            </FormGroup>
          </Col>
          <Col xs='12' md='6'>
            <FormGroup>
              <Label for="phone">
                Cantidad de recursos
              </Label>
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Input
                    ref={ref}
                    id="recursos"
                    type={'number'}
                    className={`${errors.recursos ? 'error form-control' : 'form-control'}`}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={9}
                    // placeholder={'Enter age'}
                  />
                )}              
                name={'recursos'}              
                rules={{
                  minLength: 2,
                  required: true,               
                  maxLength: 9,
                }}
                control={control}
              />
            </FormGroup>
          </Col>
        </Row> */}
        <Row>
          <Col lg='12'>
            <Button className='btn-seidor bg-seidor-medium-blue color-seidor-white' onClick={() => handleSubmit(onSubmit)()}>Crear</Button>
          </Col>
        </Row>
      </>
    );
  };

  return renderMainForm();
};
