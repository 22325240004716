// import { AccessTokenCache } from '@azure/msal-common';
import RepositoryManager from '@ComponentsRoot/core/RepositoryManager';
import { InjectedPropsType } from '@ComponentsRoot/core/types/InjectedPropsType';
import { EditTechnicianFormValuesTypes } from '../views/EditTechnicianView';
import moment from 'moment';
// import { OutletContextType } from '@ComponentsRoot/core/types/OutletContextType';

export default class EditTechnicianController {
  repositoryManager: RepositoryManager;
  techId: string;
  setIsError: any;
  constructor(viewProps: InjectedPropsType, techId: any, setIsError: any) {
    this.repositoryManager = viewProps.outletContext!.repositoryManager;
    this.techId = techId;
    this.setIsError = setIsError;
  }

  setError = (error: any) => {
    this.setIsError(error);
  };

  toggleError = () => {
    this.setIsError(null);
  };

  getTecnicos = async (setAllTecnicos: any, props: any) => {
    try {
      const token = props.outletContext?.accessToken;
      const res = await this.repositoryManager.getTechnicianRepository().getAllTechnician(token);
      if (res) {
        setAllTecnicos(res);
      }
    } catch (error) {
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  getDetailTecnico = async (techId: string, setDetailDataFn: any, token: any) => {
    try {
      const res = await this.repositoryManager.getTechnicianRepository().getDetailTecnico(techId, token);
      if (res) {
        setDetailDataFn(res);
      }
    } catch (error) {
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  getInfoTecnico = async (techId: string, setDetailDataFn: any, props: InjectedPropsType, setShowSpinner: any) => {
    try {
      setShowSpinner('d-block');
      const token = props.outletContext?.accessToken;
      const res = await this.repositoryManager.getTechnicianRepository().getInfoTecnico(techId, token);
      if (res) {
        setShowSpinner('d-none');
        setDetailDataFn(res);
      }
    } catch (error) {
      setShowSpinner('d-none');
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  saveTecnico = async (
    data: EditTechnicianFormValuesTypes,
    techSchedule: any,
    setModalSuccess: any,
    setIconSuccess: any,
    setMessageSuccess: any,
    props: InjectedPropsType,
    setShowSpinnerBtn: any,
    setDisabledBtn: any
  ) => {
    try {
      setShowSpinnerBtn('d-block');
      setDisabledBtn(true);
      // Guardamos la info del técnico
      const res = await this.repositoryManager.getTechnicianRepository().saveTecnico(data, techSchedule);
      if (res) {
        // await this.getDetailTecnico(this.techId, setDetailData, token);
        setShowSpinnerBtn('d-none');
        setDisabledBtn(false);
        setModalSuccess(true);
        setTimeout(() => {
          setModalSuccess(null);
        }, 5000);
        setIconSuccess('k-i-check-circle');
        setMessageSuccess('Técnico actualizado correctamente');
      }
    } catch (error) {
      setShowSpinnerBtn('d-none');
      setDisabledBtn(false);
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  getAllSkills = async (setSkillsData: any) => {
    try {
      const res = await this.repositoryManager.getTechnicianRepository().getAllSkills();
      if (res) {
        setSkillsData(res);
      }
    } catch (error) {
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  getSubSkillsById = async (skills: any[], setSubSkillsDataById: any) => {
    try {
      const promises: Array<Promise<object | undefined>> = [];
      skills.forEach((skill) => {
        promises.push(this.repositoryManager.getTechnicianRepository().getSubSkillsById(skill.id));
      });

      const resultOfAll = await Promise.all(promises).catch((error) => {
        throw error;
      });

      if (resultOfAll) {
        setSubSkillsDataById(resultOfAll);
      }
    } catch (error) {
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  setUpOptionDropdownSkills = (skillObj: any, skillsByIdArr: any[]) => {
    if (skillsByIdArr.length !== 0) {
      const skills: any = [];
      skillsByIdArr.forEach((item) => {
        skills.push(...item);
      });
      const founded = skills.filter((skill: any) => skill.categoryId === skillObj.id);
      if (founded) {
        return founded.map((FItem: any) => {
          return { value: FItem.id, label: FItem.name };
        });
      }
      return [];
    }
  };

  setSkillsTechnician = (techDetailSkills: any[], skillObj: any) => {
    const founded = techDetailSkills.filter((skill: any) => skill.category.categoryId === skillObj.id);
    if (founded) {
      return founded.map((FItem: any) => {
        return { value: FItem.skillId, label: FItem.category.name };
      });
    }
    return [];
  };

  setSchedule = async (tecnicoDetailData: any, setTechSchedule: any) => {
    const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    const groupedDays: any = [];

    daysOfWeek.forEach((day) => {
      const startTime = tecnicoDetailData[`${day}StartTime`];
      const endTime = tecnicoDetailData[`${day}EndTime`];
      const presencial = tecnicoDetailData[`${day}Presencial`];

      if (startTime && endTime) {
        const matchedGroup = groupedDays.find(
          (group: { startTime: any; endTime: any; presencial: any }) =>
            group.startTime === startTime && group.endTime === startTime && group.presencial === presencial
        );

        if (matchedGroup) {
          matchedGroup.days.push(day);
        } else {
          groupedDays.push({
            startTime,
            endTime,
            presencial,
            days: [day],
          });
        }
      }
    });

    const techShedule = groupedDays.map((shedule: any) => ({
      startTime: this.ParseZoneUTC(shedule.startTime),
      endTime: this.ParseZoneUTC(shedule.endTime),
      presencial: shedule.presencial,
      days: shedule.days,
    }));

    setTechSchedule(techShedule);
  };

  ParseZoneUTC = (data: any) => {
    const date = moment.utc();
    const time = data.split(':');
    date.set('hour', Number(time[0]));
    date.set('minute', Number(time[1]));
    date.set('second', Number(time[2]));
    const formatDateTime = date.format();
    const dateTimeLocal = moment.parseZone(formatDateTime).local().format();
    const timeFormat = moment(dateTimeLocal).format('HH:mm:ss');
    return timeFormat;
  };

  setSkillsTech = async (tecnicoDetailData: any, setTechSkills: any) => {
    try {
      const techSkills = tecnicoDetailData.map((skill: any) => ({
        skillId: skill.skillId,
        name: skill.category.name,
        categoryId: skill.category.categoryId,
      }));
      setTechSkills(techSkills);
    } catch (error) {
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  getAllSubSkills = async (setSubSkillsData: any) => {
    try {
      const res = await this.repositoryManager.getTechnicianRepository().getAllSubSkills();
      if (res) {
        const allSubSkills = res.map((skill: any) => ({
          skillId: skill.id,
          name: skill.name,
          categoryId: skill.categoryId,
        }));
        setSubSkillsData(allSubSkills);
      }
    } catch (error) {
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };

  addNewSkill = async (skill: any, categoryId: any, setSubSkillsData: any) => {
    try {
      const dataToSend = {
        name: skill,
        categoryId: Number(categoryId),
      };
      const res = await this.repositoryManager.getSkillsRepository().addSubSkills(dataToSend);
      if (res) {
        const resGetSkills = await this.repositoryManager.getSkillsRepository().getSubSkills(categoryId);
        if (resGetSkills) {
          const opcionesAPI = resGetSkills.map((opcion: any) => ({
            value: opcion.id,
            label: opcion.name,
          }));
          return opcionesAPI;
        }
      }
    } catch (error) {
      this.setError(error);
      setTimeout(() => {
        this.setError(null);
      }, 5000);
    }
  };
}
