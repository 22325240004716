import _ from 'lodash';

export const cleanGridColumnMenuIncomingPropsGridUtils = (props: any) => {
  const clonedProps = _.cloneDeep(props);
  delete clonedProps['isSelected'];
  delete clonedProps['dataIndex'];
  // delete clonedProps['expanded'];
  delete clonedProps['rowType'];
  delete clonedProps['columnsCount'];
  delete clonedProps['columnIndex'];
  delete clonedProps['selectionChange'];
  delete clonedProps['ariaColumnIndex'];
  delete clonedProps['dataItem'];

  return clonedProps;
};
