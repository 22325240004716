import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';

import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '@ComponentsRoot/MsalAuth/authConfig';
// import ConfigManager from "@MainUtils/ConfigManager";
import MyApp from './App';

export const msalInstance = new PublicClientApplication(msalConfig);
// const cfgManager = new ConfigManager();

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

const MainRenderApp = () => {
  const [fromLogin, setFromLogin] = useState(false);

  //Handler to set the active account retrieved from the payload (from login or after acquiring token)
  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);

      setFromLogin(true);
    }
  });

  return <MyApp pca={msalInstance} fromLogin={fromLogin} />;
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<MainRenderApp />);
