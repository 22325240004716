import { InjectedPropsType } from '@ComponentsRoot/core/types/InjectedPropsType';
import { OutletContextType } from '@ComponentsRoot/core/types/OutletContextType';
import { process } from '@progress/kendo-data-query';
import {
  Grid,
  GridColumn as Column,
  GridColumnMenuFilter,
  GridColumnMenuProps,
  GridDataStateChangeEvent,
} from '@progress/kendo-react-grid';
import { Button as GridButton } from "@progress/kendo-react-buttons";
import React from 'react';
import '@progress/kendo-theme-default/dist/all.css';
import BackupsController from '../controller/BackupsController';
import { Row, Col, Button, Alert, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ErrorHandler } from '@ComponentsRoot/ErrorHandler/ErrorHandler';
// import { string } from 'prop-types';
// import { any } from 'prop-types';


class ColumnMenu extends React.Component<GridColumnMenuProps> {
  render() {
    return (
      <div>
        <GridColumnMenuFilter {...this.props} expanded={true} />
      </div>
    );
  }
}

export default class BackupsGridView extends React.Component<
  InjectedPropsType,
  { take: number; skip: number; backupsGridData: undefined | any[]; skillDelete: undefined | any[]; alertVisible: any; message: any; isError: any; showSpinner: any; showSpinnerBtn: any; showSpinnerRestore: any; modal: any; backupName: any; disabledBtn: any; disabledTrashBtn: boolean}
> {
  controller: BackupsController;

  constructor(props: any) {
    super(props);
    this.controller = new BackupsController(this, this.props as { outletContext: OutletContextType });

    this.state = {
      take: 20,
      skip: 0,
      backupsGridData: undefined,
      skillDelete: undefined,
      alertVisible: false,
      message: undefined,
      isError: undefined,
      showSpinner: 'd-none',
      showSpinnerBtn: 'd-none',
      showSpinnerRestore: 'd-none',
      modal: false,
      backupName: undefined,
      disabledBtn: false,
      disabledTrashBtn: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  onDismiss() {
    this.setState({
      alertVisible: !this.state.alertVisible
    });
  }

  renderErrorHandler = () => {
    if(this.state.isError){
      return <ErrorHandler isOpen toggle={this.controller.toggleError} message={this.state.isError.message} />
    }
    return <></>
  }

  componentDidMount = async() => {
    if (this.state.backupsGridData === undefined){
      await this.controller.getAllBackups();
    }
  }

  dataStateChange = (event: GridDataStateChangeEvent) => {
    this.setState({ ...(event.dataState as any) });
  };

  ColumnMenuAction = (props: any) => {
    return( 
      <td className={props.className} {...props} style={Object.assign(props.style)}>
          <GridButton icon="refresh" fillMode="solid" className='btn-seidor bg-seidor-light-blue color-seidor-white' onClick={() => {this.toggle();this.setState({backupName: props.dataItem.name})}} />
          <GridButton icon="trash" fillMode="solid" className='ml-2 btn-seidor bg-seidor-dark-grey color-seidor-white' disabled={this.state.disabledTrashBtn} onClick={(e:any) => this.controller.deleteBackup(props.dataItem.name)} />
      </td>
    )
  };

  render() {
    return (
      <>
      <Row>
        <Col lg='12'>
          <h1>Backups</h1>
          <Button className='mb-4 btn-seidor bg-seidor-medium-blue' disabled={this.state.disabledBtn} onClick={(e:any) => this.controller.createBackup()}>
            Nuevo backup
            <Spinner size="sm" className={`spinner-pos ${this.state.showSpinnerBtn}`}>
              Loading...
            </Spinner>
          </Button>
          <Spinner color="info" className={`m-center mt-5 ${this.state.showSpinner}`}>
            Loading...
          </Spinner>
          {this.state.backupsGridData && <Grid
            {...this.state}
            data={process(this.state.backupsGridData === undefined ? [] : this.state.backupsGridData, this.state)}        
            onDataStateChange={this.dataStateChange}
            sortable={{
              mode: 'multiple',
            }}
            pageable={true}
            pageSize={20}
          >
            <Column field='name' title='Nombre' columnMenu={ColumnMenu} />
            <Column title='Actions' cell={this.ColumnMenuAction} />
          </Grid>}
        </Col>
      </Row>

      <Modal isOpen={this.state.modal} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>¡Aviso Importante!</ModalHeader>
        <ModalBody>
          ¿Estas seguro de querer restaurar el backup?
        </ModalBody>
        <ModalFooter>
          <Button className='btn-seidor bg-seidor-medium-blue' disabled={this.state.disabledBtn} onClick={async() => this.controller.restoreBackup(this.state.backupName)}>
            Sí
            <Spinner size="sm" className={`spinner-pos ${this.state.showSpinnerRestore}`}>
              Loading...
            </Spinner>
          </Button>{' '}
          <Button color='danger' onClick={() => {this.toggle(); this.setState({backupName: undefined})}}>No</Button>
        </ModalFooter>
      </Modal>

      <Alert isOpen={this.state.alertVisible} toggle={this.onDismiss} className='alert-success'>
        <p className='m-0'><span className={'k-icon k-i-check-circle'}></span> {this.state.message}</p>
      </Alert>

      {this.renderErrorHandler()}
      </>
    );
  }
}