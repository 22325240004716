import { EditTechnicianFormValuesTypes } from '@ComponentsRoot/Technician/views/EditTechnicianView';
import TechniciansMapper from '@DataMappers/TechniciansMapper';
import NodeApiDS from '@DataSource/NodeApiDS';

export default class TechnicianRepository {
  nodeApiDS: NodeApiDS;
  technicianMapper: TechniciansMapper;
  constructor(nodeApiDS: NodeApiDS) {
    this.nodeApiDS = nodeApiDS;
    this.technicianMapper = new TechniciansMapper();
  }

  getImgUser = async (tokenAccess: any) => {
    try {
      const res = await this.nodeApiDS.callApi('user/photo', 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  getAllTechnician = async (tokenAccess: any) => {
    try {
      const res = await this.nodeApiDS.callApi('technician', 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  syncTechnician = async (tokenAccess: any) => {
    try {
      // const token = {
      //   token: tokenAccess,
      // };
      const res = await this.nodeApiDS.callApi('technician/sync', 'post');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  getDetailTecnico = async (techId: string, tokenAccess: any) => {
    try {
      const res = await this.nodeApiDS.callApi(`technician/${techId}`, 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  getInfoTecnico = async (techId: string, tokenAccess: any) => {
    try {
      const res = await this.nodeApiDS.callApi(`technician/${techId}/detail`, 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  saveTecnico = async (data: EditTechnicianFormValuesTypes, techSchedule: any) => {
    try {
      const dataToSend = this.technicianMapper.mapEditTechnician(data, techSchedule);
      const res = await this.nodeApiDS.callApi(`technician/${data.tecnicoId}/detail`, 'post', dataToSend);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  getAllSkills = async () => {
    try {
      const res = await this.nodeApiDS.callApi('category', 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  getAllSubSkills = async () => {
    try {
      const res = await this.nodeApiDS.callApi('skill', 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  getSubSkillsById = async (skillId: any) => {
    try {
      const res = await this.nodeApiDS.callApi(`category/${skillId}/skills`, 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };
}
