import NodeApiDS from '@DataSource/NodeApiDS';
import ServicesRepository from '@Repositories/ServicesRepository';
import TechnicianRepository from '@Repositories/TechnicianRepository';
import SkillsRepository from '@Repositories/SkillsRepository';
import TurnsRepository from '@Repositories/TurnsRepository';
import BackupsRepository from '@Repositories/BackupsRepository';

export default class RepositoryManager {
  servicesRepository: ServicesRepository;
  technicianRepository: TechnicianRepository;
  skillsRepository: SkillsRepository;
  turnsRepository: TurnsRepository;
  backupsRepository: BackupsRepository;

  constructor(nodeApiDS: NodeApiDS) {
    this.servicesRepository = new ServicesRepository(nodeApiDS);
    this.technicianRepository = new TechnicianRepository(nodeApiDS);
    this.skillsRepository = new SkillsRepository(nodeApiDS);
    this.turnsRepository = new TurnsRepository(nodeApiDS);
    this.backupsRepository = new BackupsRepository(nodeApiDS);
  }

  getServicesRepository = () => {
    return this.servicesRepository;
  };

  getTechnicianRepository = () => {
    return this.technicianRepository;
  };

  getSkillsRepository = () => {
    return this.skillsRepository;
  };

  getTurnsRepository = () => {
    return this.turnsRepository;
  };

  getBackupsRepository = () => {
    return this.backupsRepository;
  };
}
