import NodeApiDS from '@DataSource/NodeApiDS';

export default class BackupsRepository {
  nodeApiDS: NodeApiDS;
  constructor(nodeApiDS: NodeApiDS) {
    this.nodeApiDS = nodeApiDS;
  }

  getAllBackups = async () => {
    try {
      const res = await this.nodeApiDS.callApi('backup', 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  createBackup = async (timestamp: any) => {
    try {
      const dataToSend = {
        sinceTimestamp: timestamp,
      };
      const res = await this.nodeApiDS.callApi('backup', 'post', dataToSend);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  restoreBackup = async (idBackup: any) => {
    try {
      const dataToSend = {
        backup: idBackup,
      };
      const res = await this.nodeApiDS.callApi('backup/restore', 'post', dataToSend);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  deleteBackup = async (backupName: string) => {
    try {
      const res = await this.nodeApiDS.callApi(`backup/${backupName}`, 'del');
      return res.data;
    } catch (error) {
      throw error;
    }
  }
}
