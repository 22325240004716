import { Controller, useForm } from 'react-hook-form';
import { Modal, ModalHeader, ModalBody, Row, Col, Input, Label, FormGroup, Button, ModalFooter } from 'reactstrap';
import { useEffect } from 'react';
import moment from 'moment';

export type AddTechnicianScheduleFormValuesTypes = {
    tecnicoId: string;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
    startTime: string;
    endTime: string;
    onsite: boolean;
};

export default function ModalAddturno(props: {
    data: any
    controller: any
    isOpen: boolean
    onRequestClose: any
    techSchedule: any
    setTechSchedule: any
}){
    const {
        control,
        reset,
        handleSubmit 
    } = useForm<AddTechnicianScheduleFormValuesTypes>();
      
    useEffect(() => {
        if (props.isOpen) {
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen]);

    const handleAddSchedule = (data: AddTechnicianScheduleFormValuesTypes) => {
        const { monday, tuesday, wednesday, thursday, friday, saturday, sunday, startTime, endTime, onsite } = data;

        const newSchedule = {
            startTime: moment(startTime, 'HH:mm').format('HH:mm:ss'),
            endTime: moment(endTime, 'HH:mm').format('HH:mm:ss'),
            presencial: onsite ? true : false,
            days: [] as string[],
        };

        if (monday) {
            newSchedule.days.push('monday');
        }
        if (tuesday) {
            newSchedule.days.push('tuesday');
        }
        if (wednesday) {
            newSchedule.days.push('wednesday');
        }
        if (thursday) {
            newSchedule.days.push('thursday');
        }
        if (friday) {
            newSchedule.days.push('friday');
        }
        if (saturday) {
            newSchedule.days.push('saturday');
        }
        if (sunday) {
            newSchedule.days.push('sunday');
        }

        const updatedTechSchedule = [...props.techSchedule];

        // Buscar si ya existe un grupo de horarios con el mismo rango de tiempo
        const existingGroupIndex = updatedTechSchedule.findIndex((group) =>
            group.startTime === newSchedule.startTime && group.endTime === newSchedule.endTime && group.presencial === newSchedule.presencial
        );

        if (existingGroupIndex !== -1) {
            // Si existe, agregar los días al grupo existente
            updatedTechSchedule[existingGroupIndex].days.push(...newSchedule.days);
        } else {
        // Si no existe, crear un nuevo grupo de horarios
            updatedTechSchedule.push({
                startTime: newSchedule.startTime,
                endTime: newSchedule.endTime,
                presencial: newSchedule.presencial,
                days: newSchedule.days
            });
        }

        props.setTechSchedule(updatedTechSchedule); // Actualizar techSchedule en el componente padre

        props.onRequestClose();
    };

    useEffect(() => {
        console.log(props.techSchedule); // Mostrar techSchedule actualizado
    }, [props.techSchedule]);

    return (
        <Modal isOpen={props.isOpen} onRequestClose={props.onRequestClose} size={'lg'}>
            <ModalHeader>Añadir horario</ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs='12'>
                        <FormGroup className='mb-0'>
                            <Label className='font-weight-bold'>Días de la semana</Label>
                        </FormGroup>
                        <FormGroup check inline>
                            <Controller
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <Input
                                type='checkbox'
                                id='monday'
                                name='monday'
                                value={'true'}
                                onChange={onChange}
                                onBlur={onBlur}
                                disabled={props.techSchedule.some((schedule: { days: string | string[]; }) => schedule.days.includes('monday'))}
                                />
                            )}
                            name={'monday'}
                            control={control}
                            />
                            <Label check>Lunes</Label>
                        </FormGroup>

                        <FormGroup check inline>
                            <Controller
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <Input
                                type='checkbox'
                                id='tuesday'
                                name='tuesday'
                                value={'true'}
                                onChange={onChange}
                                onBlur={onBlur}
                                disabled={props.techSchedule.some((schedule: { days: string | string[]; }) => schedule.days.includes('tuesday'))}
                                />
                            )}
                            name={'tuesday'}
                            control={control}
                            />
                            <Label check>Martes</Label>
                        </FormGroup>

                        <FormGroup check inline>
                            <Controller
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <Input
                                type='checkbox'
                                id='wednesday'
                                name='wednesday'
                                value={'true'}
                                onChange={onChange}
                                onBlur={onBlur}
                                disabled={props.techSchedule.some((schedule: { days: string | string[]; }) => schedule.days.includes('wednesday'))}
                                />
                            )}
                            name={'wednesday'}
                            control={control}
                            />
                            <Label check>Miércoles</Label>
                        </FormGroup>

                        <FormGroup check inline>
                            <Controller
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <Input
                                type='checkbox'
                                id='thursday'
                                name='thursday'
                                value={'true'}
                                onChange={onChange}
                                onBlur={onBlur}
                                disabled={props.techSchedule.some((schedule: { days: string | string[]; }) => schedule.days.includes('thursday'))}
                                />
                            )}
                            name={'thursday'}
                            control={control}
                            />
                            <Label check>Jueves</Label>
                        </FormGroup>

                        <FormGroup check inline>
                            <Controller
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <Input
                                type='checkbox'
                                id='friday'
                                name='friday'
                                value={'true'}
                                onChange={onChange}
                                onBlur={onBlur}
                                disabled={props.techSchedule.some((schedule: { days: string | string[]; }) => schedule.days.includes('friday'))}
                                />
                            )}
                            name={'friday'}
                            control={control}
                            />
                            <Label check>Viernes</Label>
                        </FormGroup>

                        <FormGroup check inline>
                            <Controller
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <Input
                                type='checkbox'
                                id='saturday'
                                name='saturday'
                                value={'true'}
                                onChange={onChange}
                                onBlur={onBlur}
                                disabled={props.techSchedule.some((schedule: { days: string | string[]; }) => schedule.days.includes('saturday'))}
                                />
                            )}
                            name={'saturday'}
                            control={control}
                            />
                            <Label check>Sábado</Label>
                        </FormGroup>

                        <FormGroup check inline>
                            <Controller
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <Input
                                type='checkbox'
                                id='sunday'
                                name='sunday'
                                value={'true'}
                                onChange={onChange}
                                onBlur={onBlur}
                                disabled={props.techSchedule.some((schedule: { days: string | string[]; }) => schedule.days.includes('sunday'))}
                                />
                            )}
                            name={'sunday'}
                            control={control}
                            />
                            <Label check>Domingo</Label>
                        </FormGroup>
                    </Col>

                    <Col xs='12' className='mt-3'>
                        <FormGroup className='mb-0'>
                            <Label className='font-weight-bold'>Horario</Label>
                        </FormGroup>
                        <FormGroup check inline>
                            <Controller
                            render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                            }) => (
                                <Input
                                    type={'time'}
                                    id='startTime'
                                    name='startTime'
                                    className='form-control'
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                />
                            )}
                            name={'startTime'}
                            control={control}
                            />
                        </FormGroup>

                        <FormGroup check inline>
                            <Controller
                            render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                            }) => (
                                <Input
                                    type={'time'}
                                    id='endTime'
                                    name='endTime'
                                    className='form-control'
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                />
                            )}
                            name={'endTime'}
                            control={control}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs='12' className='mt-3'>
                        <FormGroup check inline>
                            <Controller
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <Input
                                type='checkbox'
                                id='onsite'
                                name='onsite'
                                value={'true'}
                                onChange={onChange}
                                onBlur={onBlur}
                                />
                            )}
                            name={'onsite'}
                            control={control}
                            />
                            <Label check>Presencial</Label>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button className='btn-seidor bg-seidor-medium-blue' onClick={handleSubmit(handleAddSchedule)}>Añadir</Button>{' '}
                <Button className='btn-seidor bg-seidor-dark-grey' onClick={props.onRequestClose}>
                Cerrar
                </Button>
            </ModalFooter>
        </Modal>
    );
}