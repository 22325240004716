import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Modal, ModalHeader, ModalBody, Col, Input, Label, FormGroup, Button, ModalFooter, Spinner } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import { EditTurnFormValuesTypes, formatOptionLabelResources } from './EditServiceView';
import { useEffect, useState } from 'react';

export default function ModalEditTurno(props: {
  disabledBtn: boolean;
  editSkillsTurnDropdown: any;
  addMoreButtonClickFn:any;
  skillsById: any;
  allSkills: any;
  allSubSkills: any;
  controller: any;
  modalEditIsOpen: boolean;
  onRequestClose: any;
  onSubmit: any;
  serviceTurnDetailById: any;
  showSpinnerBtnTurn: any;
  setModalEditSkills: any;
  setServiceTurnDetailById: any;
  setModalEditIsOpen: any;
  serviceDetailTurno: any;
  getIdTurn: any;
}) {
  const {
    handleSubmit,
    control,
    reset,
    // formState: { errors },
  } = useForm<EditTurnFormValuesTypes>();

  const onSubmit: SubmitHandler<EditTurnFormValuesTypes> = async (data) => {
    const newData = {
      ...data,
      skills: skillSelectedOptions,
    };
    await props.onSubmit(newData);
    setSkillSelectedOptions([]);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [modalResources, setModalResources] = useState(false);
  const [modalSkillResources, setModalSkillResources] = useState(false);
  const [newSkill, setNewSkill] = useState<any | null>();
  const [resourcesSkill, setResourcesSkill] = useState<any | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<{ label: string; value: any } | null>(null);
  const [skillSelectedOptions, setSkillSelectedOptions] = useState<any | null>([]);
  const [skillsOptionsValues, setSkillsOptionsValues] = useState<any | null>([]);
  const [getCategoryId, setCategoryId] = useState(false);
  const toggleModalResources = () => setModalResources(!modalResources);
  const toggleModalSkillResources = () => setModalSkillResources(!modalSkillResources);

  const handleCreate = async (inputValue: string, categoryId: any) => {
    console.log(props.serviceTurnDetailById.skills)
    setNewSkill(inputValue);
    setCategoryId(categoryId);
    toggleModalResources();
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    setSkillSelectedOptions(skillsOptionsValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillsOptionsValues]);

  useEffect(() => {
    if (props.modalEditIsOpen) {
      props.controller.setUpSkillSelectedOptions(props.serviceDetailTurno, props.getIdTurn, setSkillSelectedOptions);
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.modalEditIsOpen]);

  const handleRecursoChange = (event: any) => {
    setResourcesSkill(event.target.value);
    console.log(event.target.value)
  };

  
  const handleSelectChange = (selected: any, categoryId: any, actionMeta: any) => {
    const { action } = actionMeta;
    if (action === 'remove-value') {
      const removedOption = actionMeta.removedValue;
      // Realiza las operaciones necesarias con la opción eliminada
      const updatedOptions = skillSelectedOptions.filter((option: { value: any; }) => option.value !== removedOption.value);
      setSkillSelectedOptions(updatedOptions);
      console.log('Opción eliminada:', removedOption);
    }else{
      const selectedOption = selected ? selected[selected.length - 1] : null;
      setCategoryId(categoryId);
      setSelectedOptions(selectedOption);
      if (selectedOption !== undefined) {
        toggleModalSkillResources();
      }
    }
  };

  return (
    <>
    <Modal isOpen={props.modalEditIsOpen} onRequestClose={props.onRequestClose} size={'lg'}>
      <ModalHeader>Editar turno</ModalHeader>
      {props.serviceTurnDetailById && (
        <ModalBody>
          <Col xs='12' className='mb-3'>
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Input
                  type='hidden'
                  id='servicesTurnId'
                  name='servicesTurnId'
                  className='form-control'
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
              name={'servicesTurnId'}
              control={control}
              defaultValue={props.serviceTurnDetailById?.id}
            />

            <Label>Nombre del turno</Label>
            {props.serviceTurnDetailById && (
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Input
                    type='text'
                    id='name'
                    name='name'
                    className='form-control'
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
                name={'name'}
                control={control}
                defaultValue={props.serviceTurnDetailById?.name}
              />
            )}
          </Col>

          <Col xs='12' className='mb-3'>
            <FormGroup className='mb-0'>
              <Label>Días de la semana</Label>
            </FormGroup>
            <FormGroup check inline>
              {props.serviceTurnDetailById && (
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <Input
                      type='checkbox'
                      id='monday'
                      name='monday'
                      value={'true'}
                      defaultChecked={props.serviceTurnDetailById?.monday === true ? true : false}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                  name={'monday'}
                  control={control}
                  defaultValue={props.serviceTurnDetailById?.monday === true ? true : false}
                />
              )}
              <Label check>Lunes</Label>
            </FormGroup>
            <FormGroup check inline>
              {props.serviceTurnDetailById && (
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <Input
                      type='checkbox'
                      id='thuesday'
                      name='thuesday'
                      defaultChecked={props.serviceTurnDetailById?.thuesday === true ? true : false}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                  name={'thuesday'}
                  control={control}
                  defaultValue={props.serviceTurnDetailById?.thuesday === true ? true : false}
                />
              )}
              <Label check>Martes</Label>
            </FormGroup>
            <FormGroup check inline>
              {props.serviceTurnDetailById && (
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <Input
                      type='checkbox'
                      id='wednesday'
                      name='wednesday'
                      defaultChecked={props.serviceTurnDetailById?.wednesday === true ? true : false}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                  name={'wednesday'}
                  control={control}
                  defaultValue={props.serviceTurnDetailById?.wednesday === true ? true : false}
                />
              )}
              <Label check>Miércoles</Label>
            </FormGroup>
            <FormGroup check inline>
              {props.serviceTurnDetailById && (
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <Input
                      type='checkbox'
                      id='thursday'
                      name='thursday'
                      defaultChecked={props.serviceTurnDetailById?.thursday === true ? true : false}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                  name={'thursday'}
                  control={control}
                  defaultValue={props.serviceTurnDetailById?.thursday === true ? true : false}
                />
              )}
              <Label check>Jueves</Label>
            </FormGroup>
            <FormGroup check inline>
              {props.serviceTurnDetailById && (
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <Input
                      type='checkbox'
                      id='friday'
                      name='friday'
                      defaultChecked={props.serviceTurnDetailById?.friday === true ? true : false}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                  name={'friday'}
                  control={control}
                  defaultValue={props.serviceTurnDetailById?.friday === true ? true : false}
                />
              )}
              <Label check>Viernes</Label>
            </FormGroup>
            <FormGroup check inline>
              {props.serviceTurnDetailById && (
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <Input
                      type='checkbox'
                      id='saturday'
                      name='saturday'
                      defaultChecked={props.serviceTurnDetailById?.saturday === true ? true : false}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                  name={'saturday'}
                  control={control}
                  defaultValue={props.serviceTurnDetailById?.saturday === true ? true : false}
                />
              )}
              <Label check>Sábado</Label>
            </FormGroup>
            <FormGroup check inline>
              {props.serviceTurnDetailById && (
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <Input
                      type='checkbox'
                      id='sunday'
                      name='sunday'
                      defaultChecked={props.serviceTurnDetailById?.sunday === true ? true : false}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                  name={'sunday'}
                  control={control}
                  defaultValue={props.serviceTurnDetailById?.sunday === true ? true : false}
                />
              )}
              <Label check>Domingo</Label>
            </FormGroup>
          </Col>

          <Col xs='12' className='mb-3'>
            <FormGroup className='mb-0'>
              <Label>Horario</Label>
            </FormGroup>
            <FormGroup check inline>
              {props.serviceTurnDetailById && (
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <Input
                      type={'time'}
                      id='startTime'
                      name='startTime'
                      // step={'2'}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      className='form-control'
                    />
                  )}
                  name={'startTime'}
                  control={control}
                  defaultValue={props.controller.parseZoneUTC(props.serviceTurnDetailById?.startTime)}
                />
              )}
            </FormGroup>

            <FormGroup check inline>
              {props.serviceTurnDetailById && (
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <Input
                      type={'time'}
                      id='endTime'
                      name='endTime'
                      // step={'2'}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      className='form-control'
                    />
                  )}
                  name={'endTime'}
                  control={control}
                  defaultValue={props.controller.parseZoneUTC(props.serviceTurnDetailById?.endTime)}
                />
              )}
            </FormGroup>
          </Col>
          <Col xs='12'>
            <Label>Skills</Label>
          </Col>
          {props.allSkills.map((skill: any, key: any) => {
            return (
              <>
                <Col xs='12' lg='12' className='mb-2' id={skill.id} key={key}>
                <Label className='mr-3'>
                {skill.name}{' '}
                </Label>
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <CreatableSelect
                        isMulti
                        isClearable
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        onChange={(selected, actionMeta) => handleSelectChange(selected, skill.id, actionMeta)}
                        onCreateOption={(inputValue) => handleCreate(inputValue, skill.id)}
                        name={`skill-${skill.id}`}
                        formatOptionLabel={(inputValue) => formatOptionLabelResources(inputValue, skillSelectedOptions)}
                        options={props.allSubSkills
                          .filter((subSkill: any) => subSkill.categoryId === skill.id) // Filtrar por categoryId
                          .map((subSkill: any) => ({
                            value: subSkill.id,
                            label: subSkill.name,
                          }))
                        }
                        value={skillSelectedOptions
                          .filter((skills: any) => skills.categoryId === skill.id) // Filtrar por categoryId
                          .map((skills: any) => ({
                            value: skills.value,
                            label: skills.label,
                            resources: skills.resources
                          }))
                        }
                      />
                    )}
                    // defaultValue={skillSelectedOptions
                    //   .filter((skills: any) => skills.categoryId === skill.id) // Filtrar por categoryId
                    //   .map((skills: any) => ({
                    //     value: skills.value,
                    //     label: skills.label,
                    //     resources: skills.resources
                    //   }))
                    // }
                    name={`skill-${skill.id}`}
                    control={control}
                  />
                </Col>
              </>
            );
          })}
          <Col xs='12' className='mt-4'>
            <FormGroup check inline>
              {props.serviceTurnDetailById && (
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <Input
                      type='checkbox'
                      id='onsite'
                      name='onsite'
                      defaultChecked={props.serviceTurnDetailById?.inPerson === true ? true : false}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                  name={'onsite'}
                  control={control}
                  defaultValue={props.serviceTurnDetailById?.inPerson === true ? true : false}
                />
              )}
              <Label check>Servicio es OnSite.</Label>
            </FormGroup>
          </Col>

          <Modal isOpen={modalSkillResources} toggle={toggleModalSkillResources}>
          <ModalHeader>Añadir recursos para {selectedOptions?.label}</ModalHeader>
          <ModalBody>
            <Label className='mt-3'>Recursos mínimos</Label>
            <Input
              type='number'
              id='recursoEdit'
              name='recursoEdit'
              className='form-control'
              // onChange={(event) => setResourcesSkill(event.target.value)}
              onChange={handleRecursoChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={(e: any) => {
              const skillsResponse = props.controller.setSelectedSkillOption(selectedOptions, getCategoryId, resourcesSkill, setSkillSelectedOptions, skillSelectedOptions);
              setSkillSelectedOptions(skillsResponse)
              toggleModalSkillResources();
              }}>
              Añadir
            </Button>{' '}
            <Button className='btn-seidor bg-seidor-dark-grey' onClick={toggleModalSkillResources}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalResources} toggle={toggleModalResources}>
            <ModalHeader>Añadir recursos para {newSkill}</ModalHeader>
            <ModalBody>
              <Label className='mt-3'>Recursos mínimos</Label>
              <Input
                type='number'
                id='recurso'
                name='recurso'
                className='form-control'
                onChange={(event) => setResourcesSkill(event.target.value)}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={async(e: any) =>{
                const res = await props.controller.addNewSkill(newSkill, getCategoryId, resourcesSkill, setSkillSelectedOptions, skillSelectedOptions);
                setSkillsOptionsValues(res)
                toggleModalResources();
              }}>
                Añadir
              </Button>{' '}
              <Button className='btn-seidor bg-seidor-dark-grey' onClick={toggleModalResources}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </ModalBody>
      )}
      <ModalFooter>
        <Button
          className='btn-seidor bg-seidor-medium-blue'
          disabled={props.disabledBtn}
          onClick={() => handleSubmit(onSubmit)()}
        >
          Actualizar
          <Spinner size='sm' className={`spinner-pos ${props.showSpinnerBtnTurn}`}>
            Loading...
          </Spinner>
        </Button>{' '}
        <Button
          className='btn-seidor bg-seidor-dark-grey'
          onClick={() => {
            props.setModalEditIsOpen(false);
            props.setServiceTurnDetailById(null);
          }}
        >
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
    </>
  );
}
