import DashboardController from '../controller/DashboardController';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table, Spinner, Alert } from 'reactstrap';
import {
  Grid,
  GridColumn as Column,
  GridRowProps,
  GridDetailRowProps,
  GridExpandChangeEvent,
  GridDataStateChangeEvent,
} from '@progress/kendo-react-grid';
import { Button as GridButton } from '@progress/kendo-react-buttons';
import { Tooltip } from '@progress/kendo-react-tooltip';
import React, { useEffect, useState } from 'react';
import { InjectedPropsType } from '@ComponentsRoot/core/types/InjectedPropsType';
import { ErrorHandler } from '@ComponentsRoot/ErrorHandler/ErrorHandler';
import { DataResult, process, State } from '@progress/kendo-data-query';

export const DashboardView = (props: InjectedPropsType) => {
  const [isError, setIsError] = useState<any | null>(null);
  const [uncoverTurns, setUncoverTurns] = useState<any | null>(null);
  const [resolveTurns, setResolveTurns] = useState<any | null>(null);
  const [modal, setModal] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [showSpinner, setShowSpinner] = useState(null);
  const [showSpinnerBtn, setShowSpinnerBtn] = useState<any | null>('d-none');
  const [infoTechnician, setInfoTechnician] = useState<any | null>(null);
  const [infoTech, setInfoTech] = useState<any | null>(null);
  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const [successResolveTurn, setSuccessResolveTurn] = useState(false);
  const [coverTurns, setCoverTurns] = useState<any | null>([]);
  const [hiddenBtn, setHiddenBtn] = useState(false);
  const [showSpinnerTurns, setShowSpinnerTurns] = useState(true);

  const onDismissSuccessAlertVisible = () => setSuccessAlertVisible(false);
  const onDismissSuccessResolveTurn = () => setSuccessResolveTurn(false);

  const toggle = () => setModal(!modal);

  const tooltip = React.useRef<Tooltip>(null);

  const controller = new DashboardController(props, setIsError);

  const renderErrorHandler = () => {
    if (isError) {
      return <ErrorHandler isOpen toggle={controller.toggleError} message={isError.message} />;
    }
    return <></>;
  };

  const [dataState, setDataState] = React.useState<State>({
    skip: 0,
    take: 20,
    sort: [{ field: 'serviceName', dir: 'asc' }],
  });

  const [dataResult, setDataResult] = React.useState<DataResult>(process([], dataState));

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataResult(process((event as any).data, event.dataState));
    setDataState(event.dataState);
  };

  const DetailComponent = (props: GridDetailRowProps) => {
    const dataItem = props.dataItem;
    return (
      <>
        {dataItem.affectedTurns?.length && (
          <div>
            <p>Turnos afectados</p>
            <Grid data={dataItem.affectedTurns}>
              <Column title='Servicio' cell={ColumnName} />
              <Column title='Turno' cell={ColumnTurn} />
              <Column title='Fecha' cell={ColumnStartDate} />
              <Column title='Técnico Asignado' cell={columnAffectedTechnician} />
            </Grid>
          </div>
        )}
      </>
    );
  };

  const expandChange = (event: GridExpandChangeEvent) => {
    const isExpanded = event.dataItem.expanded === undefined ? false : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;

    // Search techicianId, serviceID, startDateTime to find it on array and change his expanded value
    const newDataResult = (dataResult as any).map((itm: any) => {
      if (
        event.dataItem.serviceId === itm.serviceId &&
        event.dataItem.startDateTime === itm.startDateTime &&
        event.dataItem.technicianId === itm.techicianId
      ) {
        itm.expanded = !isExpanded;
      }
      return itm;
    });
    setDataResult(newDataResult);
  };

  const cellRender = (td: any, props: any) => {
    if (props.field === 'expanded' && !props.dataItem.affectedTurns) {
      return <td></td>;
    }
    return <td {...td.props}></td>;
  };

  const ColumnMenuAction = (props: any) => {
    return (
      <>
        <td className={props.className} {...props} style={Object.assign(props.style)}>
        {props.dataItem.technician ? 
          <div id="ck-button">
            <label>
                <input
                  type="checkbox"
                  name="validate"
                  value={props.dataItem.id}
                  onChange={handleOnChange}
                  checked={coverTurns.some((turn: any) => turn.id === props.dataItem.id)}
                /><span><i className="fa-solid fa-check"></i></span>
            </label>
          </div>
        :
          ''
        }
        </td>
      </>
    );
  };

  const handleOnChange = (value: any) => {
    const id = value.target.value
    const isChecked = value.target.checked

    if (isChecked) {
      const getTurn = resolveTurns.filter((turn: any) => turn.id === value.target.value)
      const newCoverTurns = [...coverTurns, ...getTurn];
      setCoverTurns(newCoverTurns)
    } else {
      const newCoverTurns = coverTurns.filter((turn: any) => turn.id !== id);
      setCoverTurns(newCoverTurns);
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setHiddenBtn(true)
    setShowSpinnerTurns(false)
    const resBackup = await controller.createBackup()
    const resTurns = await controller.createTurns(coverTurns)
    if (resBackup && resTurns) {
      await controller.getResolveTurns(
        props,
        setResolveTurns,
        setUncoverTurns,
        setShowSpinnerBtn,
        setDisabledBtn,
        setDataResult
      )
      setCoverTurns([])
      setSuccessResolveTurn(true);
      setHiddenBtn(false)
      setShowSpinnerTurns(true)
      setTimeout(() => {
        setSuccessResolveTurn(false);
      }, 5000);
    }
  }

  const columnAffectedTechnician = (props: any) => {
    return (
      <>
        <td className={props.className} {...props} style={Object.assign(props.style)}>
          {props.dataItem.technician ? `${props.dataItem.technician.name} ${props.dataItem.technician.surname}` : 'Sin técnico'}
          {/* {props.dataItem.oldTechnician.name} {props.dataItem.oldTechnician.surname}{' '} */}
          <div
            className='d-inline-block'
            onMouseOver={(event) => tooltip.current && tooltip.current.handleMouseOver(event)}
            onMouseOut={(event) => tooltip.current && tooltip.current.handleMouseOut(event)}
          >
            <span title={`Técnico anterior: ${props.dataItem.oldTechnician?.name} ${props.dataItem.oldTechnician?.surname}`} className={'k-icon k-i-information'}></span>
            <Tooltip ref={tooltip} anchorElement='target' position='top' openDelay={300} />
          </div>
        </td>
      </>
    );
  };

  const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
    const rowClassName = props.dataItem.affectedTurns?.length ? '' : 'hideExpandBtn';

    const available = !props.dataItem.affectedTurns || !props.dataItem.technician;
    const light = { backgroundColor: 'rgb(254, 254, 254, 1)' };
    const danger = { backgroundColor: 'rgb(248, 215, 218, 1)' };
    const trProps: any = { className: rowClassName, style: available ? danger : light };
    return React.cloneElement(trElement, { ...trProps });
  };

  const ColumnName = (props: any) => {
    return (
      <td className={props.className} {...props} style={Object.assign(props.style)}>
        {props.dataItem.service?.name}
      </td>
    );
  };

  const ColumnTurn = (props: any) => {
    return (
      <td className={props.className} {...props} style={Object.assign(props.style)}>
        {props.dataItem.servicesTurn?.name}
      </td>
    );
  };

  const ColumnStartDate = (props: any) => {
    return (
      <td className={props.className} {...props} style={Object.assign(props.style)}>
        {controller.setUpDate(props.dataItem.startDateTime, props.dataItem.endDateTime)}
      </td>
    );
  };

  const ColumnMenuSkills = (props: any) => {
    return (
      <td className={props.className} {...props} style={Object.assign(props.style)}>
        {props.dataItem.skills.map((skill: any) => {
          return (
            <>
              <div>
                <div>
                  {skill.skill.name} <span>({skill.numbertechnicians})</span>
                </div>
              </div>
            </>
          );
        })}
      </td>
    );
  };

  const ColumnMenuTechnician = (props: any) => {
    return (
      <td className={props.className} {...props} style={Object.assign(props.style)}>
        {props.dataItem.oldTechnician ? (
          <>
            {props.dataItem.technician.name} {props.dataItem.technician.surname}{' '}
            <Button
              tag='a'
              className='p-1'
              color='link'
              onClick={() => {
                setModal(true);
                setInfoTechnician(props.dataItem.oldTechnician);
                setInfoTech(props.dataItem.technician);
              }}
            >
              <span className={'k-icon k-i-information'}></span>
            </Button>
          </>
        ) : (
          <></>
        )}
        {props.dataItem.technician ? (
          <>
            {props.dataItem.technician.name} {props.dataItem.technician.surname}
          </>
        ) : (
          <></>
        )}
      </td>
    );
  };

  useEffect(() => {
    (async () => {
      await controller.getUncoverdTurns(props, setUncoverTurns, setResolveTurns, setShowSpinner, setDataResult);
      await controller.uncoverTurnsAlert(setSuccessAlertVisible);
      setTimeout(() => {
        setSuccessAlertVisible(false);
      }, 5000);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderMainInfo = () => {
    return (
      <>
        <Alert isOpen={successAlertVisible} toggle={onDismissSuccessAlertVisible} className='alert-warning'>
          <p className='m-0'>
            <span className={'k-icon k-i-warning'}></span> Revisión de turnos pendiente
          </p>
        </Alert>

        <Alert isOpen={successResolveTurn} toggle={onDismissSuccessResolveTurn} className='alert-warning'>
          <p className='m-0'>
            <span className={'k-icon k-i-success'}></span> Los turnos se han creado correctamente
          </p>
        </Alert>

        <Row>
          <Col xs='12' md='6' lg='4'>
            <Button href='/technician' tag='a' className='w-100 p-5 btn-seidor bg-seidor-dark-blue'>
              Gestión técnicos
            </Button>
          </Col>
          <Col xs='12' md='6' lg='4'>
            <Button href='/services' tag='a' className='w-100 p-5 btn-seidor bg-seidor-medium-blue'>
              Gestión servicios
            </Button>
          </Col>
          <Col xs='12' md='6' lg='4'>
            <Button href='/skills' tag='a' className='w-100 p-5 btn-seidor bg-seidor-light-blue'>
              Gestión de skills
            </Button>
          </Col>

          <Spinner color='info' className={`m-center mt-5 ${showSpinner}`}>
            Loading...
          </Spinner>
        </Row>

        <Row className='mt-5'>
          {uncoverTurns && (
            <>
            <Col xs='12' md='6'>
              <h2>Turnos por asignar</h2>
            </Col>

            <Col md='6' xs='12'>
              <Button
                href='#'
                tag='a'
                className='p-3 btn-seidor bg-seidor-medium-blue float-right'
                disabled={disabledBtn}
                onClick={async () => {
                  await controller.getResolveTurns(
                    props,
                    setResolveTurns,
                    setUncoverTurns,
                    setShowSpinnerBtn,
                    setDisabledBtn,
                    setDataResult
                  );
                }}
              >
                Asignar turnos
                <Spinner size='sm' className={`spinner-pos ${showSpinnerBtn}`}>
                Loading...
                </Spinner>
              </Button>
              
              <GridButton
                icon='reload'
                fillMode='solid'
                className='p-3 btn-seidor bg-seidor-dark-blue color-seidor-white float-right'
                onClick={async () => {
                  await controller.getUncoverdTurns(
                    props,
                    setUncoverTurns,
                    setResolveTurns,
                    setShowSpinner,
                    setDataResult,
                  );
                  setCoverTurns([])
                }}
              ></GridButton>
            </Col>
            <Col className='mt-3 mb-3'>
              <Grid data={uncoverTurns} className='turnos-grid'>
                <Column field='service.name' title='Servicio' />
                <Column field='servicesTurn.name' title='Turno' />
                <Column title='Fecha' cell={ColumnStartDate} />
                <Column title='Skills' className='skill-column' cell={ColumnMenuSkills} />
              </Grid>
            </Col>
            </>
          )}

          {resolveTurns && (
            <>
            <Col xs='12' md='6'>
              <h2>Turnos asignados</h2>
            </Col>

            <Col xs='12' md='6'>
              <Button
                className={`p-3 btn-seidor bg-seidor-medium-blue color-seidor-white float-right ${hiddenBtn === true ? 'd-none' : ''}`}
                onClick={handleSubmit}
                disabled={coverTurns.length === 0 ? true : false}
              >
                Cubrir turnos seleccionados
              </Button>

              {/* Disabled Button */}
              <Button
                className={`p-3 btn-seidor bg-seidor-dark-blue color-seidor-white float-right ${showSpinnerTurns === true ? 'd-none' : ''}`}
                disabled
              >
                Cubrir turnos seleccionados
                <Spinner size='sm' className={`spinner-pos`}>
                Loading...
                </Spinner>
              </Button>
              {/* End Disabled Button */}
              
              <GridButton
                icon='reload'
                fillMode='solid'
                className='p-3 btn-seidor bg-seidor-dark-blue color-seidor-white float-right'
                onClick={async () => {
                  await controller.getUncoverdTurns(
                    props,
                    setUncoverTurns,
                    setResolveTurns,
                    setShowSpinner,
                    setDataResult,
                  );
                  setCoverTurns([])
                }}
                ></GridButton>
            </Col>

            <Col className='mt-3 mb-3'>
              <Grid
                data={dataResult}
                cellRender={cellRender}
                className='turnos-grid'
                rowRender={rowRender}
                {...dataState}
                detail={DetailComponent}
                onDataStateChange={dataStateChange}
                expandField='expanded'
                onExpandChange={expandChange}
              >
                <Column title='Servicio' cell={ColumnName} />
                <Column title='Turno' cell={ColumnTurn} />
                <Column title='Fecha' cell={ColumnStartDate} />
                <Column title='Técnico Asignado' className='skill-column' cell={ColumnMenuTechnician} />
                <Column title='Validar Turno' width='120px' cell={ColumnMenuAction} />
              </Grid>
            </Col>
            </>
          )}
        </Row>

        {infoTechnician && (
          <Modal isOpen={modal} onRequestClose={() => setModal(false)} size={'lg'}>
            <ModalHeader toggle={toggle}>Información técnico</ModalHeader>
            <ModalBody>
              <Table>
                <thead>
                  <tr>
                    <th>Servicio</th>
                    <th>Turno</th>
                    <th>Fecha</th>
                    <th>Técnico</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{infoTechnician.servicesTurn.service.name}</td>
                    <td>{infoTechnician.servicesTurn.name}</td>
                    <td>03/01/2023 08:00 - 17:00</td>
                    <td>
                      {infoTech.name} {infoTech.surname}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </ModalBody>
            <ModalFooter>
              <Button
                className='btn-seidor bg-seidor-dark-grey'
                onClick={() => {
                  setModal(false);
                  setInfoTechnician(null);
                }}
              >
                Cerrar
              </Button>
            </ModalFooter>
          </Modal>
        )}

        {renderErrorHandler()}
      </>
    );
  };

  return renderMainInfo();
};
