const routes = [
  {
    path: '/dashboard',
    name: 'Inicio',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-chart-pie-36',
  },
  {
    path: '/technician',
    name: 'Técnicos',
    rtlName: 'الرموز',
    icon: 'tim-icons icon-atom',
  },
  {
    path: '/services',
    name: 'Servicios',
    rtlName: 'خرائط',
    icon: 'tim-icons icon-pin',
  },
  {
    path: '/skills',
    name: 'Skills',
    rtlName: 'خرائط',
    icon: 'tim-icons icon-pin',
  },
  {
    path: '/backups',
    name: 'Backups',
    rtlName: 'خرائط',
    icon: 'tim-icons icon-pin',
  },
];
export default routes;
