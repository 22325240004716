import NodeApiDS from '@DataSource/NodeApiDS';
import TurnsMapper from '@DataMappers/TurnsMapper';
import moment from 'moment';

export default class TurnsRepository {
  nodeApiDS: NodeApiDS;
  turnsMapper: TurnsMapper;
  constructor(nodeApiDS: NodeApiDS) {
    this.nodeApiDS = nodeApiDS;
    this.turnsMapper = new TurnsMapper();
  }

  getBEVersion = async () => {
    try {
      const res = await this.nodeApiDS.callApi('version', 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  getUncoverdTurns = async (token: any) => {
    try {
      const timestamp = this.getTimestamp();
      const res = await this.nodeApiDS.callApi(`turn/uncoverturns?since=${timestamp}`, 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  getResolveTurns = async (token: any) => {
    try {
      const timestamp = this.getTimestamp();
      const res = await this.nodeApiDS.callApi(`turn/uncoverturns/resolve?since=${timestamp}`, 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  uncoverTurnsAlert = async () => {
    try {
      const res = await this.nodeApiDS.callApi('turn/uncoverturns/alert', 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  getUncoverTurnsAlertIcon = async () => {
    try {
      const res = await this.nodeApiDS.callApi('turn/uncoverturns/alert', 'get');
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  resolveTurnsUncovereds = async (data: any) => {
    try {
      const {arrCreateTurn, arrUpdateTurn} = this.turnsMapper.mappingTurns(data);
      const res = await this.nodeApiDS.callApi('turn', 'post', arrCreateTurn);

      if (res && arrUpdateTurn.length){
        await this.resolveTurnsAffecteds(arrUpdateTurn)
        return true
      }
   return res.data;
    } catch (error) {
      throw error;
    }
  };

  resolveTurnsAffecteds = async (arrUpdateTurn: any) => {
    try {
      const dataToSend: any[] = [];
      if(Array.isArray(arrUpdateTurn)){
        arrUpdateTurn.forEach(async(turn: any) => {
          const turnId = turn.turnId;
          dataToSend!.push({
            "name": this.setUpDate(turn.startDateTime, turn.endDateTime),
            "technicianId": turn.technicianId,
            "startDateTime": moment.parseZone(turn.startDateTime).valueOf(),
            "endDateTime": moment.parseZone(turn.endDateTime).valueOf(),
            "serviceId": turn.serviceId
          })
          if (turn.technicianId !== null){
            const resDel = await this.nodeApiDS.callApi(`turn/${turnId}`, 'del')
            resDel ?? await this.nodeApiDS.callApi(`turn`, 'post', dataToSend)
            return resDel.data
          } else {
            const resDel = await this.nodeApiDS.callApi(`turn/${turnId}`, 'del')
            console.log(resDel)
            return resDel.data
          }
        })
      }
    } catch (error) {
      throw error;
    }
  }

  setUpDate = (fechaIni: any, fechaEnd: any) => {
    const convertFechaIni = moment(fechaIni);
    const dateFormatIniMoment = moment.parseZone(convertFechaIni).utc().format();
    const formatDateIni = moment(dateFormatIniMoment).format('DD/MM/YYYY, HH:mm');

    const convertFechaFin = moment(fechaEnd);
    const dateFormatFinMoment = moment.parseZone(convertFechaFin).utc().format();
    const formatDateFin = moment(dateFormatFinMoment).format('DD/MM/YYYY, HH:mm');

    const timeStart = formatDateIni.split(',')[1];
    const timeEnd = formatDateFin.split(',')[1];

    return `${timeStart.trim()} - ${timeEnd.trim()}`;
  };

  getTimestamp = () => {
    const tomorrowMoment = moment().add(1, 'days');
    tomorrowMoment.set('hour', 0);
    tomorrowMoment.set('minute', 0);
    tomorrowMoment.set('second', 0);
    tomorrowMoment.set('millisecond', 0);

    const timestamp = tomorrowMoment.unix() * 1000;

    return timestamp;
  };
}
