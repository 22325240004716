import { InjectedPropsType } from '@ComponentsRoot/core/types/InjectedPropsType';
import { useEffect } from 'react';
import { Button, Col, Input, Row, Label, FormGroup } from 'reactstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
// import FormErrorView from '@ComponentsRoot/FormError/view/FormErrorView';
import Select from 'react-select'
// import { Controller, OnSubmit, SubmitHandler, useForm } from 'react-hook-form';
// import AddServiceController from "../controller/AddServiceController";

type AddServiceFormValuesTypes = {
  backup: string;
};

const options = [
  { value: 'Eloy', label: 'Eloy Asensio' },
  { value: 'David', label: 'David Perez' },
  { value: 'Xavier', label: 'Xavier Castelló' }
]

//https://react-hook-form.com/api/usecontroller/controller (V7 IMPORTANT!)
//https://react-hook-form.com/api/useform

export const AddTechnicianView = (props: InjectedPropsType) => {
  // const controller = new AddServiceController(props);

  const {
    handleSubmit,
    // control,
    // formState: { errors },
  } = useForm<AddServiceFormValuesTypes>();

  const onSubmit: SubmitHandler<AddServiceFormValuesTypes> = (data) => {
    console.log(data);
  };

  useEffect(() => {
    (async () => {
      // await props.bloc.getInventory();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderMainForm = () => {
    return (
      <>
        <Row>
          <Col xs='12'>
            <p className='m-0'>Horario</p>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="manana" value={'Mañana'} />
              <Label check>
                Mañana
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="tarde" value={'Tarde'} />
              <Label check>
                Tarde
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="weekend" value={'Weekend'} />
              <Label check>
                Fin de semana
              </Label>
            </FormGroup>
          </Col>
          <Col xs='12'>
            <FormGroup className='mt-3'>
              <Label for="backup">
                Backup
              </Label>
              <Select options={options} isMulti={false} />
            </FormGroup>
          </Col>
          <Col xs='12'><p>Habilidades</p></Col>
          <Col xs='12'>
            <Label className='mr-3'>
              Técnicas:
            </Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="PHP" value={'PHP'} />
              <Label check>
                PHP
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="Java" value={'Java'} />
              <Label check>
                Java
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="React" value={'React'} />
              <Label check>
                React
              </Label>
            </FormGroup>
          </Col>
          <Col xs='12'>
            <Label className='mr-3'>
              Idiomas:
            </Label>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="PHP" value={'Inglés'} />
              <Label check>
                Inglés
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="Java" value={'Catalan'} />
              <Label check>
                Catalan
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" className='form-check-input' id="React" value={'Castellano'} />
              <Label check>
                Castellano
              </Label>
            </FormGroup>
          </Col>
          <Col className='mt-3'>
            <FormGroup>
              <Label for="exampleDate">
                Vacaciones / Ausencias
              </Label>
              <Input
                id="exampleDate"
                name="date"
                placeholder="date placeholder"
                type="date"
              />
              <Button
                color="dark"
                href="#"
                tag="a"
                className='mt-2'
              >
                Añadir más
              </Button>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg='12'>
            <Button onClick={() => handleSubmit(onSubmit)()} color="primary">Crear</Button>
          </Col>
        </Row>
      </>
    );
  };

  return renderMainForm();
};
