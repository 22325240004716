/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useMsal, useAccount} from "@azure/msal-react";
import { IPublicClientApplication} from "@azure/msal-browser";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Button,
  Tooltip 
} from "reactstrap";

import './styles/NavbarViewStyle.scss';
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const logoutHandleClick = async (instance: IPublicClientApplication) => {
    try {
      await instance.logoutRedirect();
    } catch (error) {

      await instance.logoutRedirect();

    }
};

const AdminNavbar = (props: any) => {
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/dashboard");
  }

  return (
    <>
      <Navbar className="navbar-top bg-seidor-medium-blue" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          
          <Nav className="align-items-center d-none d-md-flex" navbar>
            {props.iconAlert === true && (<><Button color="link" active id="alertTurns" onClick={handleClick}>
              <span className="k-icon k-i-warning k-icon-32 color-orange"></span>
            </Button>
            <Tooltip
              isOpen={tooltipOpen}
              target="alertTurns"
              toggle={toggle}
              autohide={true}
              flip={true}
            >
              Revisión de turnos pendiente
            </Tooltip></>)}
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0 useraccount" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={`data:image/png;base64, ${props.imgUser}`}
                      // src={
                      //   require("@Assets/img/icons/icon-profile-seidor.png")
                      // }
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold username">
                      {account!.name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem href="#pablo" onClick={async()=>await logoutHandleClick(instance)}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
