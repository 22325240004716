import AxiosClient from '@Services/AxiosClient';
import { AxiosResponse } from 'axios';
declare type apiMethodsTypes = 'get' | 'post' | 'del' | 'put';
export default class NodeApiDS {
  axiosClient: AxiosClient;

  constructor(axiosClient: AxiosClient) {
    this.axiosClient = axiosClient;
  }

  callApi = async (
    endpoint: string,
    method: keyof AxiosClient & apiMethodsTypes,
    body?: any
  ): Promise<AxiosResponse<any, any>> => {
    try {
      return await this.axiosClient[method](endpoint, body);
    } catch (error) {
      throw error;
    }
  };

  getAxiosClient = () => {
    return this.axiosClient;
  };
}
