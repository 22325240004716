import { useParams } from 'react-router-dom';
import { InjectedPropsType } from '@ComponentsRoot/core/types/InjectedPropsType';
import React, { useEffect, useState } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button as GridButton } from '@progress/kendo-react-buttons';
import {
  Container,
  Button,
  Col,
  Input,
  Row,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Spinner,
} from 'reactstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import moment from 'moment';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
// import FormErrorView from '@ComponentsRoot/FormError/view/FormErrorView';
import '../styles/ServicesGridViewStyle.scss';
// import turnos from "../data/turnos.json";
import EditServiceController from '../controller/EditServiceController';
import { ErrorHandler } from '@ComponentsRoot/ErrorHandler/ErrorHandler';
import ModalAddturno, { NewTurnFormValuesTypes } from './ModalAddTurno';
import ModalEditTurno from './ModalEditTurno';

export type EditServiceFormValuesTypes = {
  linkedServices: any;
};
export type EditTurnFormValuesTypes = {
  servicioId: string;
  servicesTurnId: number;
  name: string;
  monday: boolean;
  thuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  startTime: string;
  endTime: string;
  onsite: boolean;
  [key: `skill-${number}`]: any;
  skills: any;
};

// @ts-ignore
export const formatOptionLabelResources = (value, skillSelectedOptions) => {
  console.log(skillSelectedOptions)
  const findSkill = skillSelectedOptions.find((skill: any) => skill.value === value.value)
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'none' }}>{value.value}</div>
      <div>{value.label}</div>
      <div style={{ marginLeft: '10px', color: '#242528' }}>{findSkill?.resources}</div>
    </div>
  )
}

//https://react-hook-form.com/api/usecontroller/controller (V7 IMPORTANT!)
//https://react-hook-form.com/api/useform

export const EditServiceView = (props: InjectedPropsType) => {
  const [serviceDetailData, setDetailData] = useState<any | null>(null);
  const [serviceDetailTurno, setDetailTurno] = useState<any | null>(null);
  const [allServices, setAllServices] = useState<any | null>(null);
  const [allSkills, setSkillsData] = useState<any | null>([]);
  const [allSubSkills, setAllSubSkills] = useState<any | null>([]);
  const [subSkillsById, setSubSkillsDataById] = useState<any | null>([]);
  const [skillsById, setSkillsById] = useState<any | null>([]);
  const [iconSuccess, setIconSuccess] = useState(null);
  const [messageSuccess, setMessageSuccess] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalSkills, setModalSkills] = useState(false);
  const [modalEditSkills, setModalEditSkills] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [serviceTurnDetailById, setServiceTurnDetailById] = useState<any | null>(null);
  const [isError, setIsError] = useState<any | null>(null);
  const [skillTurn, setSkillTurn] = useState<any | null>(null);
  const [resourcesSkill, setResourcesSkill] = useState<any | null>(null);
  const [editSkillsTurnDropdown, setEditSkillsTurnDropdown] = useState<any | null>([]);
  const [getIdTurn, setIdTurn] = useState(null);
  const [getCategoryId, setCategoryId] = useState(null);
  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const [showSpinner, setShowSpinner] = useState<any | null>('d-none');
  const [showSpinnerBtn, setShowSpinnerBtn] = useState<any | null>('d-none');
  const [showSpinnerBtnTurn, setShowSpinnerBtnTurn] = useState<any | null>('d-none');
  const [disabledBtn, setDisabledBtn] = useState(false);

  const onDismissSuccessAlertVisible = () => {
    setSuccessAlertVisible(false);
    setServiceTurnDetailById(null);
  };

  const { serviceId } = useParams();

  const toggleSkills = () => setModalSkills(!modalSkills);
  const toggleEditSkills = () => setModalEditSkills(!modalEditSkills);

  const {
    //  handleSubmit,
    control,
    //  formState: { errors },
  } = useForm<EditTurnFormValuesTypes>();

  const {
    handleSubmit: handleSubmitEdit,
    control: controlEdit,
    // formState: { errors: errors2 },
  } = useForm<EditServiceFormValuesTypes>();

  const onSubmit: SubmitHandler<EditTurnFormValuesTypes> = async (data) => {
    data.servicioId = serviceId as any;
    await controller.saveTurno(
      data,
      setDetailTurno,
      setModalEditIsOpen,
      setSuccessAlertVisible,
      setIconSuccess,
      setMessageSuccess,
      setServiceTurnDetailById,
      setShowSpinnerBtnTurn,
      setDisabledBtn
    );
  };

  const onSubmitNew: SubmitHandler<NewTurnFormValuesTypes> = async (data) => {
    await controller.createNewTurn(
      data,
      setDetailTurno,
      setModalIsOpen,
      setSuccessAlertVisible,
      setIconSuccess,
      setMessageSuccess,
      setServiceTurnDetailById,
      setShowSpinnerBtnTurn,
      setDisabledBtn
    );
  };

  const onSubmitEdit: SubmitHandler<EditServiceFormValuesTypes> = async (data) => {
    await controller.editService(
      data,
      setSuccessAlertVisible,
      setIconSuccess,
      setMessageSuccess,
      setShowSpinnerBtn,
      setDisabledBtn
    );
  };

  const controller = new EditServiceController(props, serviceId, setIsError);

  const renderErrorHandler = () => {
    if (isError) {
      return <ErrorHandler isOpen toggle={controller.toggleError} message={isError.message} />;
    }
    return <></>;
  };

  const ColumnMenuAction = (props: any) => {
    return (
      <td className={props.className} {...props} style={Object.assign(props.style)}>
        <GridButton
          icon='pencil'
          fillMode='solid'
          className='mr-2 btn-seidor bg-seidor-light-blue color-seidor-white'
          onClick={async () => {
            setModalEditIsOpen(true);
            setIdTurn(props.dataItem.id)
            await controller.getServiceTurnById(props.dataItem.id, setServiceTurnDetailById);
          }}
        />
        <GridButton
          icon='trash'
          fillMode='solid'
          className='btn-seidor bg-seidor-dark-grey color-seidor-white'
          onClick={() => {
            controller.deleteServiceTurn(
              props.dataItem.id,
              props.dataItem.serviceId,
              setDetailTurno,
              setSuccessAlertVisible,
              setIconSuccess,
              setMessageSuccess
            );
          }}
        />
      </td>
    );
  };

  const ColumnDays = (props: any) => {
    return (
      <td className={props.className} {...props} style={Object.assign(props.style)}>
        <div className={`${props.dataItem.monday === false ? 'd-none' : ''}`}>
          <div>{`${props.dataItem.monday === true ? 'Lunes' : ''}`}</div>
        </div>
        <div className={`${props.dataItem.thuesday === false ? 'd-none' : ''}`}>
          <div>{`${props.dataItem.thuesday === true ? 'Martes' : ''}`}</div>
        </div>
        <div className={`${props.dataItem.wednesday === false ? 'd-none' : ''}`}>
          <div>{`${props.dataItem.wednesday === true ? 'Miércoles' : ''}`}</div>
        </div>
        <div className={`${props.dataItem.thursday === false ? 'd-none' : ''}`}>
          <div>{`${props.dataItem.thursday === true ? 'Jueves' : ''}`}</div>
        </div>
        <div className={`${props.dataItem.friday === false ? 'd-none' : ''}`}>
          <div>{`${props.dataItem.friday === true ? 'Viernes' : ''}`}</div>
        </div>
        <div className={`${props.dataItem.saturday === false ? 'd-none' : ''}`}>
          <div>{`${props.dataItem.saturday === true ? 'Sábado' : ''}`}</div>
        </div>
        <div className={`${props.dataItem.sunday === false ? 'd-none' : ''}`}>
          <div>{`${props.dataItem.sunday === true ? 'Domingo' : ''}`}</div>
        </div>
      </td>
    );
  };

  const ColumnHour = (props: any) => {
    const parseZoneStart = ParseZoneUTC(props.dataItem.startTime);
    const parseZoneEnd = ParseZoneUTC(props.dataItem.endTime);

    return (
      <td className={props.className} {...props} style={Object.assign(props.style)}>
        {parseZoneStart} - {parseZoneEnd}
      </td>
    );
  };

  const ParseZoneUTC = (data: any) => {
    const date = moment.utc();
    const time = data.split(':');
    date.set('hour', Number(time[0]));
    date.set('minute', Number(time[1]));
    date.set('second', 0);
    const formatDateTime = date.format();
    const dateTimeLocal = moment.parseZone(formatDateTime).local().format();
    const timeFormat = moment(dateTimeLocal).format('HH:mm');
    return timeFormat;
  }

  const ColumnMenuSkills = (props: any) => {
    return (
      <td className={props.className} {...props} style={Object.assign(props.style)}>
        {props.dataItem.skills.map((skill: any, key: any) => {
          return (
            <>
              <div key={key}>
                <div>
                  {skill.skill.name} <span>({skill.numbertechnicians})</span>
                </div>
              </div>
            </>
          );
        })}
      </td>
    );
  };

  const ColumnOnSite = (props: any) => {
    return (
      <td className={props.className} {...props} style={Object.assign(props.style)}>
        {`${props.dataItem.inPerson === true ? 'Sí' : 'No'}`}
      </td>
    );
  };

  useEffect(() => {
    (async () => {
      await controller.getInfoServicio(serviceId as string, setDetailData, setShowSpinner);
      await controller.getAllServices(setAllServices);
      await controller.getAllSkills(setSkillsData);
      await controller.getTurnosServicio(serviceId as string, setDetailTurno);
      await controller.getAllSubSkills(setAllSubSkills);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (allSkills && allSkills.length) {
        await controller.getSubSkillsById(allSkills, setSubSkillsDataById);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSkills]);

  const renderMainForm = () => {
    return (
      <>
        <Container className='mb-4 border-container'>
          <Spinner color='info' className={`m-center mt-5 ${showSpinner}`}>
            Loading...
          </Spinner>
          <Row className='mt--40'>
            <Col lg='12'>
              <FormGroup>
                <h1>
                  <span className='bg-seidor-white'>Datos del servicio</span>
                </h1>
                <h2>{`${serviceDetailData?.name}`}</h2>
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <Input
                      ref={ref}
                      id='servicioId'
                      type={'hidden'}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxLength={10}
                      value={value}
                    />
                  )}
                  name={'servicioId'}
                  control={control}
                  defaultValue={`${serviceDetailData?.id}`}
                />
              </FormGroup>
            </Col>

            <Col xs='12' md='6'>
              <FormGroup>
                <Label for='exampleDate'>Servicios vinculados</Label>
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <Select
                      isMulti
                      className='basic-multi-select'
                      classNamePrefix='select'
                      onChange={onChange}
                      options={allServices.map((service: any, key: any) => {
                        return { value: service.id, label: service.name };
                      })}
                      defaultValue={controller.getLinkedServices(serviceDetailData)}
                    />
                  )}
                  name={'linkedServices'}
                  control={controlEdit}
                />
              </FormGroup>
              <FormGroup>
                <Button
                  className='btn-seidor bg-seidor-medium-blue'
                  disabled={disabledBtn}
                  onClick={() => handleSubmitEdit(onSubmitEdit)()}
                >
                  Vincular
                  <Spinner size='sm' className={`spinner-pos ${showSpinnerBtn}`}>
                    Loading...
                  </Spinner>
                </Button>{' '}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs='12' className='mb-2'>
              <Button
                className='btn-seidor bg-seidor-medium-blue color-seidor-white'
                onClick={() => {
                  setModalIsOpen(true);
                }}
              >
                Añadir turno
              </Button>
            </Col>
            <Col xs='12' className='mb-5'>
              <Grid data={serviceDetailTurno} className='turnos-grid'>
                <Column field='name' title='Nombre' />
                <Column title='Días' className='skill-column' cell={ColumnDays} />
                <Column title='Horario' className='skill-column' cell={ColumnHour} />
                <Column title='Skills' className='skill-column' cell={ColumnMenuSkills} />
                <Column title='OnSite' className='skill-column' cell={ColumnOnSite} />
                <Column title='Actions' cell={ColumnMenuAction} />
              </Grid>
            </Col>
          </Row>

          {/* <Row>
            <Col lg='12'>
              <Button className='btn-seidor bg-seidor-medium-blue color-seidor-white' onClick={() => handleSubmit(onSubmit)()}>Guardar</Button>
            </Col>
          </Row> */}
        </Container>

        <ModalAddturno
          addMoreButtonClickFn={async (skill: any) => {
            setModalSkills(true);
            await controller.getSkillsById(skill, setSkillsById, setCategoryId);
          }}
          showSpinnerBtnTurn={showSpinnerBtnTurn}
          disabledButton={disabledBtn}
          allSkills={allSkills}
          serviceDetailDataId={serviceDetailData?.id}
          controller={controller}
          onSubmit={onSubmitNew}
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          allSubSkills={allSubSkills}
        />

        <ModalEditTurno
          addMoreButtonClickFn={async (skill: any) => {
            setModalEditSkills(true);
            await controller.getSkillsById(skill, setSkillsById, setCategoryId);
          }}
          showSpinnerBtnTurn={showSpinnerBtnTurn}
          disabledBtn={disabledBtn}
          serviceTurnDetailById={serviceTurnDetailById}
          onSubmit={onSubmit}
          editSkillsTurnDropdown={editSkillsTurnDropdown}
          onRequestClose={() => setModalEditIsOpen(false)}
          setModalEditIsOpen={setModalEditIsOpen}
          allSkills={allSkills}
          skillsById={skillsById}
          modalEditIsOpen={modalEditIsOpen}
          controller={controller}
          setModalEditSkills={setModalEditSkills}
          setServiceTurnDetailById={setServiceTurnDetailById}
          allSubSkills={allSubSkills}
          serviceDetailTurno={serviceDetailTurno}
          getIdTurn={getIdTurn}
        />

        <Modal isOpen={modalSkills} toggle={toggleSkills}>
          <ModalHeader toggle={toggleSkills}>Añadir skill y recurso mínimo</ModalHeader>
          <ModalBody>
            <Label>Selecciona una skill</Label>
            <CreatableSelect
              className='basic-multi-select'
              classNamePrefix='select'
              name={'skill'}
              onChange={(choice) => setSkillTurn(choice)}
              options={skillsById.map((skill: any, key: any) => {
                return { value: skill.id, label: skill.name };
              })}
            />
            <Label className='mt-3'>Recursos mínimos</Label>
            <Input
              type='number'
              id='recurso'
              name='recurso'
              className='form-control'
              onChange={(event) => setResourcesSkill(event.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              className='btn-seidor bg-seidor-medium-blue'
            >
              Añadir
            </Button>{' '}
            <Button className='btn-seidor bg-seidor-dark-grey' onClick={toggleSkills}>
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalEditSkills} toggle={toggleEditSkills}>
          <ModalHeader toggle={toggleSkills}>Añadir skill y recurso mínimo</ModalHeader>
          <ModalBody>
            <Label>Selecciona una skill</Label>
            <CreatableSelect
              className='basic-multi-select'
              classNamePrefix='select'
              name={'skill'}
              onChange={(choice) => setSkillTurn(choice)}
              options={skillsById.map((skill: any, key: any) => {
                return { value: skill.id, label: skill.name };
              })}
            />
            <Label className='mt-3'>Recursos mínimos</Label>
            <Input
              type='number'
              id='recurso'
              name='recurso'
              className='form-control'
              onChange={(event) => setResourcesSkill(event.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              className='btn-seidor bg-seidor-medium-blue'
              onClick={async(e: any) =>
                await controller.editNewSkillTurn(
                  skillTurn,
                  resourcesSkill,
                  getCategoryId,
                  serviceTurnDetailById?.skills,
                  editSkillsTurnDropdown,
                  setEditSkillsTurnDropdown,
                  toggleEditSkills
                )
              }
            >
              Añadir
            </Button>{' '}
            <Button className='btn-seidor bg-seidor-dark-grey' onClick={toggleEditSkills}>
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>

        <Alert isOpen={successAlertVisible} toggle={onDismissSuccessAlertVisible} className='alert-success'>
          <p className='m-0'>
            <span className={`k-icon ${iconSuccess}`}></span> {messageSuccess}
          </p>
        </Alert>

        {renderErrorHandler()}
      </>
    );
  };

  return serviceDetailData === null || serviceDetailTurno === null || allSkills === null || subSkillsById === null ? (
    <>{renderErrorHandler()}</>
  ) : (
    renderMainForm()
  );
};
