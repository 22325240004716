import { Configuration, RedirectRequest } from '@azure/msal-browser';
import ConfigManager from '@ComponentsRoot/core/ConfigManager';

const cfgManager = new ConfigManager();

// Interesting links
// https://azuread.github.io/microsoft-authentication-library-for-js/ref/classes/_azure_msal_browser.publicclientapplication.html
// https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_react.html#useisauthenticated
// https://azuread.github.io/microsoft-authentication-library-for-js/ref/interfaces/_azure_msal_browser.ipublicclientapplication.html#acquiretokenredirect

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: 'a8e1a249-fa27-467a-89e7-07c296a309a3',
    authority: 'https://login.microsoftonline.com/654623d6-1504-4f22-a146-b7c72637766a',
    // redirectUri: cfgManager.getRedirectUriBasedOnEnvironment(),
    redirectUri: cfgManager.getReactOauthRedirect(),
    // knownAuthorities: ["ulisescloud.b2clogin.com"],
    knownAuthorities: ['login.microsoftonline.com'],
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: ['openid', 'Team.ReadBasic.All', 'Schedule.ReadWrite.All', 'Directory.Read.All', 'GroupMember.Read.All', 'User.Read'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  // graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me",
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/teams',
  // graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

export const authorityResetPwdFlowUri = 'https://ulisescloud.b2clogin.com/ulisescloud.onmicrosoft.com/B2C_1_reset';
export const forgottenPwdStr = 'forgotten their password';
export const canceledForgetPasswordStr = 'The user has cancelled entering self-asser';

export const processAzureMsalError = (instance: any, error: any) => {
  if (error['errorMessage'].includes(forgottenPwdStr)) {
    instance.loginRedirect({
      // redirectUri: cfgManager.getRedirectUriBasedOnEnvironment(),
      redirectUri: cfgManager.getReactOauthRedirect(),
      authority: authorityResetPwdFlowUri,
      scopes: loginRequest.scopes,
    });
  } else if (error['errorMessage'].includes(canceledForgetPasswordStr)) {
    instance.loginRedirect();
  }
};
