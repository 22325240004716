import { InteractionType } from "@azure/msal-browser";
// Msal imports
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "@ComponentsRoot/MsalAuth/authConfig";
// import FallbackLoader from "@Layout/views/FallbackLoader";
import { ErrorComponent } from "./ErrorComponent";

export const MsalAuthInjector = (props:any) => {
  const { instance } = useMsal();

  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={(props) => <ErrorComponent instance={instance} {...props} />}
      loadingComponent={() => <p>Loading...</p>}
    >
      {props.children}
    </MsalAuthenticationTemplate>
  );
};
